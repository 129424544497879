import {
  ConnectDropTarget,
  DropTargetHookSpec,
  useDrop as useDndDrop,
} from 'react-dnd';

export const useDrop = <
  DragObject = unknown,
  DropResult = unknown,
  CollectedProps = unknown
>(
  specArg: () => DropTargetHookSpec<DragObject, DropResult, CollectedProps>,
  deps?: unknown[]
): [CollectedProps, ConnectDropTarget] => {
  const [collect, connect] = useDndDrop(specArg, deps);
  return [collect, connect];
};

export default useDrop;

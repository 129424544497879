import * as ReactDOM from 'react-dom/client';
import 'uno.css';
import './styles.less';
import { ThemeHOC } from '@gaker/theme';
import 'antd-mobile/es/global';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import 'dayjs/locale/zh-cn';

dayjs.locale('zh-cn');
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export const baseEntry = (options: { bootstrap: React.FC }) => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  const App = ThemeHOC(options.bootstrap);
  root.render(<App></App>);
};

import React from 'react';
import Logo from '@dxp-assets/gaker-logo.jpg';
import classNames from 'classnames';

const DoorPage: React.FN<{
  right?: React.ReactNode;
  logo?: string;
  left?: React.ReactNode;
  scroll?: boolean;
}> = (props) => {
  return (
    <div className="size-full bg-g-grey-1 relative overflow-hidden">
      <div className="h-54px w-full absolute bg-white flex z-1 items-center justify-between p-l-g-xl p-r-g-xl  border-b-g-solid-stroke">
        {props.left ? (
          props.left
        ) : (
          <div className="h-40px w-120px">
            <img
              className="size-full object-cover object-center"
              src={props.logo || Logo}
            ></img>
          </div>
        )}
        {props.right}
      </div>
      <div
        style={{ height: 'calc(100% - 54px)' }}
        className={classNames('w-full m-t-54px relative', {
          'overflow-auto ': props.scroll !== false,
          'overflow-hidden': props.scroll === false,
        })}
      >
        {props.children}
      </div>
    </div>
  );
};

export default DoorPage;

import React, { useEffect, useRef } from 'react';
import emitter from '@dxp-utils/emitter';
import { useRouterRedirect } from '@dxp-hooks/useRouterRedirect';
import AddForm from '../form/addForm';
import listener from '@dxp-utils/listener';
import Runner from '@dxp-runner/runner';
import useEditorStore from '@dxp-hooks/storeContent';
import { useDetailPageInfoUpdate } from '@dxp-hooks/useDetailPageName';
import { useDebugTrack } from '@dxp-hooks/useDebugTrack';
import NoMore from '@dxp-components/NoMore';
import { useShow } from '@dxp-components/KeepAlive/context';
import { useQueryParams } from '@gaker/hooks';
import useListenerToEmitter from '@dxp-hooks/useListenerToEmitter';
import { useParams } from 'react-router-dom';
import isSubTable from '@dxp-utils/isSubTable';

const AsyncTitle: React.FC = () => {
  const store = useEditorStore();
  const update = useDetailPageInfoUpdate();
  useEffect(() => {
    if (!store.setting.name) return;
    update({ name: store.setting.name });
  }, [store?.setting?.name]);
  return null;
};

const Run: React.FC = () => {
  const redirect = useRouterRedirect();
  const cacheAddList = useRef<string[]>([]);
  const params = useParams<{ objectApiName: string; dataId: string }>();
  emitter.useEmitter(
    'list-item-click',
    (data) => {
      redirect({ to: 'detail', params: data });
    },
    []
  );

  emitter.useEmitter(
    'list-add',
    (data) => {
      if (!cacheAddList.current.includes(data.objectApiName)) {
        cacheAddList.current.push(data.objectApiName);
      }
      listener.addEventListener(
        `form-save-${data.objectApiName}-${data.dataId}`,
        () => {
          if (
            isSubTable(data.objectApiName) &&
            data.associateDataId &&
            data.associateObjectApiName
          ) {
            // 子表单更新时，直接刷新主应用数据
            emitter.emit(
              `detail-refresh-${data.associateObjectApiName}-${data.associateDataId}`,
              {}
            );
          } else {
            emitter.emit(`list-force-refresh-${data.objectApiName}`, {});
          }
        }
      );
    },
    []
  );
  useListenerToEmitter(`form-save-${params.objectApiName}-${params.dataId}`, [
    params.objectApiName,
    params.dataId,
  ]);

  emitter.useEmitter(
    `form-save-${params.objectApiName}-${params.dataId}`,
    () => {
      // 保存后刷新页面
      emitter.emit(
        `detail-refresh-${params.objectApiName}-${params.dataId}`,
        {}
      );
    },
    [params.objectApiName, params.dataId]
  );

  // 重新进入时有新增操作的刷新下列表
  useShow(() => {
    while (cacheAddList.current.length) {
      emitter.emit(`list-force-refresh-${cacheAddList.current.pop()}`, {});
    }
    emitter.emit(`detail-refresh-${params.objectApiName}-${params.dataId}`, {});
  });

  return (
    <Runner>
      <AddForm isMaster={true}></AddForm>
      <AsyncTitle></AsyncTitle>
    </Runner>
  );
};

export default Run;

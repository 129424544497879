import React from 'react';
import { RunnerAuth, RunnerBody, RunnerObjectName } from './RunnerPage';
import { Home, Left, More, ShareOne } from '@icon-park/react';
import { useRouterRedirect } from '@dxp-hooks/useRouterRedirect';
import { HOC } from '@gaker/tools';
import {
  DetailPageInfoProvider,
  useDetailPageInfo,
} from '@dxp-hooks/useDetailPageName';
import { useNavigate } from 'react-router-dom';

const Right: React.FC = () => {
  return (
    <div className="flex items-center gap-g-m p-r-g-xl">
      <ShareOne className="cursor-pointer" size="24" strokeWidth={2} />
      {/* <More size="24" strokeWidth={2} /> */}
    </div>
  );
};

const ListPage: React.FN<{ isNotAuth?: boolean }> = (props) => {
  const { name } = useDetailPageInfo();
  const navigate = useNavigate();
  return (
    <RunnerObjectName>
      <RunnerBody
        scroll={false}
        left={
          <div className="absolute flex-center h-100% left-5px">
            <Left
              size={24}
              strokeWidth={4}
              className="cursor-pointer m-r-g-s"
              onClick={() => navigate(-1)}
            />
            <span className="title-g-small">{name}列表</span>
          </div>
        }
        right={
          null
          // <div className="absolute right-0px flex-center h-100% m-l-g-m">
          //   <Right></Right>
          // </div>
        }
      >
        {props.children}
      </RunnerBody>
    </RunnerObjectName>
  );
};

export default HOC(DetailPageInfoProvider, { value: { name: '' } })(
  HOC(RunnerAuth)(ListPage)
);

const DefaultSize = 4;

export const BaseSize: {
  'size-1': number;
  'size-2': number;
  'size-3': number;
  'size-4': number;
  'size-5': number;
  'size-6': number;
  'size-7': number;
  'size-8': number;
  'size-9': number;
  'size-10': number;
} = Array.from({ length: 10 }).reduce<any>((map, _, index) => {
  map[`size-${index + 1}`] = (index + 1) * DefaultSize;
  return map;
}, {});

export const ComponentSize = {
  mini: BaseSize['size-1'],
  small: BaseSize['size-7'],
  default: BaseSize['size-8'],
  large: BaseSize['size-9'],
};

export const MarginSize = {
  s: BaseSize['size-1'],
  m: BaseSize['size-2'],
  l: BaseSize['size-3'],
  xl: BaseSize['size-4'],
  xxl: BaseSize['size-6'],
  xxxl: BaseSize['size-8'],
};

export const ResponsiveScreenSize = {
  s: 360,
  m: 720,
  l: 1024,
  xl: 1440,
  xxl: 1600,
};

export const IconSize = {
  normal: 16,
};

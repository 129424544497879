/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-08-25 14:57:45
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2023-09-22 14:44:16
 * @FilePath: /gaker/libs/components/Debugger/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';

export const Debugger: React.FN<{ show?: boolean }> = (props) => {
  if (process.env.NODE_ENV !== 'production' || props.show === true)
    return <div>{props.children}</div>;
  return null;
};

export default Debugger;

import React from 'react';
import './index.less';
import RunnerComponent from '@dxp-components/runnerComponent';
import { IDxpEditor } from '@dxp-declare';
import {
  DataIdContext,
  ListDisplay,
  ObjectContext,
} from '@dxp-presets/context';
import { useRouterRedirect } from '@dxp-hooks/useRouterRedirect';
import emitter from '@dxp-utils/emitter';
import listener from '@dxp-utils/listener';
import AddForm from '../form/addForm';

export interface IListProps {
  component: IDxpEditor.Components.TListComponent;
  associateDataId?: string;
  showTitle?: boolean;
  objectApiName?: string;
}
const List: React.FN<IListProps> = ({
  component,
  associateDataId,
  objectApiName,
  ...props
}) => {
  const redirect = useRouterRedirect();
  emitter.useEmitter(
    'list-item-click',
    (data) => {
      redirect({ to: 'detail', params: data });
    },
    []
  );
  emitter.useEmitter(
    'list-add',
    (data) => {
      listener.addEventListener(
        `form-save-${data.objectApiName}-${data.dataId}`,
        () => {
          emitter.emit(`list-force-refresh-${data.objectApiName}`, {});
        }
      );
    },
    []
  );

  return (
    <DataIdContext value={associateDataId || ''}>
      <ListDisplay value={props.showTitle ? 'tile' : 'tab'}>
        <div className="list-runner p-g-s">
          <ObjectContext
            value={{ objectApiName: objectApiName || '', objectName: '' }}
          >
            <RunnerComponent.Component component={component}>
              {props.children}
            </RunnerComponent.Component>
            <AddForm isMaster={true}></AddForm>
          </ObjectContext>
        </div>
      </ListDisplay>
    </DataIdContext>
  );
};

export default List;

import { useRef } from 'react';
export const useDebounce = <F extends (...args: any[]) => any>(
  fn: F,
  time: number
): ((...args: Parameters<F>) => void) => {
  const timer = useRef<any>();
  const fnRef = useRef<F>();
  fnRef.current = fn;
  return (...args) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      fnRef.current!(...args);
      timer.current = null;
    }, time);
  };
};

export default useDebounce;

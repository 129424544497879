import { createContext } from '@gaker/tools';

export const [useObjectNameMap, ObjectNameMapProvider] = createContext(
  {} as Record<string, string>,
  (v) => v
);

export const useObjectName = (objectApiName: string) => {
  const map = useObjectNameMap();
  return map[objectApiName];
};

import { getInviteUrl, getWxJsApiTicket } from '@dxp-api';
import useCurrentUser from '@dxp-hooks/useCurrentUser';
import { getCurrentData } from '@dxp-utils/currentData';
import { useRequest } from '@gaker/hooks';
import { Close, RetroBag } from '@icon-park/react';
import { Button, Tabs, message } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import QRCode from 'antd/es/qrcode';
import { useParams } from 'react-router-dom';
import useWebsite from '@dxp-hooks/useWebsite';
import { IDxpEditor } from '@dxp-declare';
import { wxReady, wxShareData } from '@dxp-utils/wxsdk';
import { env } from '@dxp-terminal/env';
import copy from 'copy-to-clipboard';
import GuidImage from '@dxp-assets/wx_share_guid.png';
import formatUrl from '@dxp-utils/formatUrl';

const description = (website: IDxpEditor.Models.WebSite) => {
  if (website.type === 'client')
    return `邀请您加入“${website.siteName}”，可自助完成"选品、下单、付款、提交开票申请、查看履约进度、提交工单、下发任务"等交易旅程 `;
  if (website.type === 'agent')
    return `邀请您加入“${website.siteName}”，可在线完成客户的报备、查重、协同跟进,以及自主下单、代客下单、查看订单信息等交易事项 `;
  return `邀请您加入“${website.siteName}”，可在线完成"接单、收款、开票、发货、汇报履约进度、跟进工单、跟进任务"等协同事项 `;
};

const LinkInvite: React.FC<{ url: string }> = (props) => {
  const currentUser = useCurrentUser();

  const website = useWebsite();

  const initShared = useMemo(() => {
    if (!website || !props.url) return;
    return wxReady(() => {
      return wxShareData({
        title: '邀请成员注册', // 分享标题
        desc: currentUser.userName + ' ' + description(website), // 分享描述
        link: props.url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: formatUrl(website.icon || website.logo), // 分享图标
      });
    });
  }, [website, props.url]);

  const [showShareGuide, setShowShareGuide] = useState(false);
  return (
    <div>
      <span className="text-g-normal-bold text-g-brand-6">
        {currentUser.userName + ' '}
      </span>
      {description(website)}
      <span
        onClick={() => {
          window.open(props.url);
        }}
        className="text-g-link text-wrap break-all cursor-pointer"
      >
        {props.url}
      </span>
      <div className="flex gap-g-l m-t-g-xl m-b-g-m">
        <Button
          type="primary"
          className="flex-1"
          ghost
          size="large"
          onClick={() => {
            try {
              copy(
                `[${currentUser.userName}]${description(website)}${props.url}`
              );
              message.success('复制成功');
            } catch (e) {
              message.error('复制失败');
            }
          }}
        >
          复制
        </Button>
        {(env.isWxChat || !env.isProduct) && (
          <Button
            type="primary"
            className="flex-1"
            size="large"
            onClick={() => {
              if (initShared)
                initShared
                  .then(() => setShowShareGuide(true))
                  .catch(() => message.error('分享失败'));
              else {
                message.error('分享失败');
              }
            }}
          >
            分享至微信
          </Button>
        )}
      </div>
      {showShareGuide && (
        <div
          onClick={() => setShowShareGuide(false)}
          className="size-full fixed bg-#1D212999 z-999 top-0 left-0"
        >
          <img className="w-full" src={GuidImage}></img>
        </div>
      )}
    </div>
  );
};

const QRCodeInvite: React.FC<{ url: string }> = (props) => {
  const ref = useRef<HTMLDivElement>();
  return (
    <div
      ref={(el) => {
        if (!el) return;
        ref.current = el;
      }}
      className="flex flex-center flex-col p-g-m gap-g-xl"
    >
      <QRCode bgColor="white" className="size-256px" value={props.url}></QRCode>
      <Button
        onClick={() => {
          if (!ref.current) return;
          const canvas = ref.current.querySelector<HTMLCanvasElement>('canvas');
          if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = 'QRCode.png';
            a.href = url;
            document.body?.appendChild(a);
            a.click();
            document?.body?.removeChild(a);
          }
        }}
        className="flex-1 w-full"
        size="large"
        type="primary"
        ghost
      >
        下载二维码
      </Button>
    </div>
  );
};

const UserInvite: React.FC<{ onClose?: () => void }> = (props) => {
  const params = useParams<{ domain: string }>();
  const objectData = useMemo(() => {
    return getCurrentData(params.domain!);
  }, []);
  const { data } = useRequest(getInviteUrl, {
    defaultParams: [objectData!],
  });
  return (
    <div className="bg-g-grey-1 w-full">
      <div className="bg-white flex w-full justify-between items-center p-g-l border-b-g-solid-stroke">
        <span className="title-g-small font-500">邀请成员注册</span>
        <Close
          className="cursor-pointer"
          onClick={props.onClose}
          theme="outline"
          strokeWidth={3}
        />
      </div>
      <div className="p-g-xxxl">
        <div className="bg-white rd-g-normal border-g-solid-stroke p-g-xl !p-t-0">
          <Tabs
            size="small"
            defaultActiveKey="link"
            items={[
              {
                key: 'link',
                tabKey: 'link',
                label: '邀请链接',
                children: <LinkInvite url={data || ''}></LinkInvite>,
              },
              {
                key: 'qrcode',
                tabKey: 'qrcode',
                label: '二维码',
                children: <QRCodeInvite url={data || ''}></QRCodeInvite>,
              },
            ]}
          ></Tabs>
        </div>
      </div>
    </div>
  );
};

export default UserInvite;

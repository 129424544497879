import RunnerComponent from '@dxp-components/runnerComponent';
import { IDxpEditor } from '@dxp-declare';
import { EditorStore } from '@dxp-hooks/storeContent';
import { useRunnerMeta } from '@dxp-hooks/useRunnerMeta';
import { FieldDetail, FieldValue, PageMode } from '@dxp-presets/context';
import Runner from '@dxp-runner';
import { FieldDetailStore } from '@dxp-store/fieldDetailStore';
import { RunnerStore } from '@dxp-store/runnerValueStore';
import { createComponentId } from '@dxp-utils/createComponentId';
import { iterateComponent } from '@dxp-utils/iterateComponent';
import { Right as _Right } from '@icon-park/react';;import { IconPark as IconParkHOC } from '@gaker/components';
        const Right = IconParkHOC(_Right)
      
import classNames from 'classnames';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import List from '../list/List';
import { useRequest, useScreen } from '@gaker/hooks';
import { useRouterRedirect } from '@dxp-hooks/useRouterRedirect';
import Account from './account';
import { Models } from '@declare-models';
import { getFieldValue } from '@gaker/models';
import { buildUrl } from '@dxp-api';
import formatUrl from '@dxp-utils/formatUrl';
import { useApi } from '@dxp-api/hooks';
import useWebsite from '@dxp-hooks/useWebsite';
import { ListMain } from '../list';
import { useDebugTrack } from '@dxp-hooks/useDebugTrack';
import UserList from '../userlist/userList';
import { env } from '@dxp-terminal/env';
import { Debugger } from '@gaker/components';
import { Button } from 'antd';

const Product_Object_Api_Name = 'productObject';

const productComponent: IDxpEditor.Components.TListComponent = {
  id: createComponentId('list'),
  type: 'list',
  config: {
    objectName: '产品',
    objectId: Product_Object_Api_Name,
    width: 'flex.1',
  },
};
const HomeBody: React.FC<{
  config: IDxpEditor.IEditor;
}> = ({ config }) => {
  const [current, setCurrent] = useState('');
  const website = useWebsite();
  const { objectApiName = '', dataId = '' } = useParams<{
    objectApiName: string;
    dataId: string;
  }>();
  const { data, loading } = useRunnerMeta({
    objectApiName,
    config,
    dataId: dataId,
    pageSize: 1,
  });
  const { data: serviceObjectList, refresh } = useRequest(
    useApi('homeObjectList'),
    {
      defaultLoaded: false,
    }
  );

  useEffect(() => {
    if (!website.type) return;
    refresh({ type: website.type });
  }, [website.type]);

  const objectList = serviceObjectList; //
  //  useMemo(() => {
  //   // if (!env.isProduct) return serviceObjectList || [];
  //   const list = (serviceObjectList || []).slice(
  //     0,
  //     website.type === 'client' ? 1 : website.type === 'agent' ? 2 : 0
  //   );

  //   iterateComponent(config, (component) => {
  //     const serverObject = serviceObjectList?.find(
  //       (obj) => obj.objectApiName === component.config.objectId
  //     );
  //     if (
  //       component.type === 'list' &&
  //       serverObject &&
  //       !list.find((v) => v.objectApiName === serverObject.objectApiName)
  //     ) {
  //       list.push(serverObject);
  //     }
  //   });
  //   return list;
  // }, [config, serviceObjectList, website.type]);

  useEffect(() => {
    if (objectList?.length) {
      setCurrent(objectList[0].objectApiName);
    }
  }, [objectList]);

  const { icon, title } = config.titleBar.config;

  const value = useMemo(() => {
    const showValue: { icon?: string; title: string } = { title: '' };
    if (!data) return showValue;
    const getValue = (field: Models.Field.TField) => {
      try {
        return getFieldValue(
          {
            value: data.content?.[0][field.fieldApiName],
            valueType: field.valueType as any,
          },
          field
        );
      } catch (e) {
        console.warn(e);
      }
    };
    if (icon?.config.field.fieldApiName) {
      showValue.icon = formatUrl(getValue(icon.config.field));
    }
    if (title?.config.field) {
      showValue.title = getValue(title.config.field);
    }
    return showValue;
  }, [icon, title, data]);

  const screen = useScreen();
  const redirect = useRouterRedirect();
  return (
    <PageMode value={'view'}>
      <div className="size-full flex flex-row ">
        <div
          className={classNames('h-full p-g-xl overflow-auto shrink-0', {
            'w-375px border-r-g-solid-stroke': screen.width > 720,
            'w-full': screen.width < 720,
          })}
        >
          <Account
            {...value}
            gotoUserList={() => {
              if (screen.width < 720) return redirect('user');
              setCurrent('userlist');
            }}
          ></Account>
          <Debugger>
            <Button
              onClick={() => {
                console.log(
                  '%c [  ]-146-「home/index.tsx」',
                  'font-size:13px; background:pink; color:#bf2c9f;',
                  objectList,
                  serviceObjectList
                );
              }}
            >
              打印应用列表
            </Button>
          </Debugger>
          <div className="bg-white rd-g-small border-g-solid-stroke">
            {(objectList || []).map((object, index) => {
              return (
                <div
                  key={object.objectApiName + index}
                  className={classNames(
                    'h-54px flex flex-row justify-between items-center title-g-normal cursor-pointer hover:bg-g-grey-1 p-l-g-xl p-r-g-xl',
                    {
                      'border-t-g-solid-stroke': index > 0,
                      'color-g-brand-6': current === object.objectApiName,
                    }
                  )}
                  onClick={() => {
                    if (screen.width < 720)
                      return redirect({
                        to: 'list',
                        params: {
                          objectApiName: object.objectApiName,
                        },
                      });
                    setCurrent(object.objectApiName);
                  }}
                >
                  {object.objectName}
                  <Right></Right>
                </div>
              );
            })}
          </div>
        </div>
        {screen.width > 720 && current && (
          <div className="flex-1 h-full  !b-t-0 !b-r-0 !b-b-0 relative overflow-hidden">
            <div className="absolute size-full">
              {current === 'userlist' ? (
                <div className="p-g-s">
                  <UserList hideInvite={true}></UserList>
                </div>
              ) : (
                <ListMain
                  showTitle={true}
                  key={current}
                  associateDataId={dataId}
                  objectApiName={current}
                ></ListMain>
              )}
            </div>
          </div>
        )}
      </div>
    </PageMode>
  );
};

const Home: React.FC = () => {
  return (
    <Runner
      canvas={(config) => {
        return <HomeBody config={config}></HomeBody>;
      }}
    ></Runner>
  );
};

export default Home;

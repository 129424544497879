import { useEffect, useRef } from 'react';

export const useOutSideClick = (
  outSideClick: (currentTarget: HTMLElement, event: any) => void
) => {
  const elRef = useRef<HTMLElement>();
  const ref = (el: HTMLElement | undefined | null) => {
    if (!el || elRef.current) return;
    elRef.current = el;
  };
  useEffect(() => {
    const handler = (event: any) => {
      if (elRef.current && !elRef.current.contains(event.target)) {
        outSideClick(event.current, event);
      }
    };
    window.document.body.addEventListener('click', handler, true); //todo 优化?
    return () =>
      window.document.body.removeEventListener('click', handler, true);
  }, [outSideClick]);

  return [ref];
};

export default useOutSideClick;

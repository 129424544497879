import { createContext } from '@gaker/tools';
import * as Api from './index';
import { useRequest } from '@gaker/hooks';
import { IDxpEditor } from '@dxp-declare';

const [use, Provider] = createContext(Api, (api) => api);

export const ApiProvider: React.FN<{ api: Partial<typeof Api> }> = ({
  api,
  children,
}) => {
  return <Provider value={{ ...Api, ...api }}>{children}</Provider>;
};

export const useApi = <Key extends keyof typeof Api = keyof typeof Api>(
  apiName: Key
) => {
  const api = use();
  return api[apiName] || Api[apiName];
};

export const [useRegion, RegionProvider] = createContext(
  [] as IDxpEditor.Models.IRegionVO[],
  (region) => region
);

// todo 以下代码是尝试代码
// 纠结于直接使用useApiHighRequest('name')或useApiRequest代替useRequest
// 还是直接 useRequest(useApi('name'))
// 从hooks组装及最小粒度原则讲，采用第二种方案应该更好些

export const useApiHighRequest = () => {};

export const useApiRequest = () => {};

type P<X> = X extends Promise<infer R> ? R : unknown;

type T<Y> = Y extends {
  mcode: string;
  message: string;
  result: boolean;
  data: infer R;
}
  ? R
  : unknown;

export const useRequester = <Key extends keyof typeof Api = keyof typeof Api>(
  apiName: Key
) => {
  const request = useApi(apiName);
  return useRequest<
    T<P<ReturnType<typeof request>>>,
    Parameters<typeof request>
  >(request);
};

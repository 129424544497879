import { env } from '@dxp-terminal/env';
import { getCurrentData } from '@dxp-utils/currentData';
import { message } from 'antd';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';

const queryToString = (query?: any) => {
  if (!query) return '';
  const filter = env.isProduct
    ? (key: string) => typeof query[key] !== undefined
    : () => true;
  const queryStr = Object.keys(query)
    .filter(filter)
    .map((key) => `${key}=${query[key]}`)
    .join('&');
  return queryStr ? '?' + queryStr : '';
};

type TRedirect =
  | {
      to: 'home';
      params?: { objectApiName: string; dataId: string };
    }
  | {
      to: 'detail';
      params: { objectApiName: string; dataId: string };
    }
  | { to: 'list'; params: { objectApiName: string; associateDataId?: string } }
  | {
      to: 'user';
    }
  | {
      to: 'login';
    }
  | { to: 'product' }
  | {
      to: 'reset';
    }
  | {
      to: 'create';
      params: {
        objectApiName: string;
      };
      query?: {
        dataId: string;
        associateDataId: string;
        associateObjectApiName?: string;
        associateFieldApiName?: string;
        isMaster?: boolean;
      };
    }
  | {
      to: 'edit';
      params: { objectApiName: string; dataId: string };
      query?: {
        associateDataId: string;
        associateObjectApiName: string;
        associateFieldApiName: string;
      };
    }
  | {
      to: 'result';
      query: {
        type: 'submit';
        objectApiName: string;
        dataId: string;
      };
    };

export const useRouterRedirect = (navigateParams?: {
  replace?: boolean;
}): ((
  route: TRedirect['to'] | TRedirect,
  push?: (path: string, push?: (path: string) => void) => void,
  params?: { replace?: boolean }
) => void) => {
  const navigate = useNavigate();
  const param = useParams<{ domain: string }>();
  return (route, push, params?: { replace?: boolean }) => {
    let redirect = push || navigate;
    if (redirect === navigate && (params?.replace || navigateParams?.replace)) {
      redirect = (path: string) => {
        navigate(path, { replace: true });
      };
    }
    const to = (path: string) => {
      return redirect(`/${param.domain}/${path}`);
    };
    if (typeof route === 'string') return to(route);
    switch (route.to) {
      case 'home':
        const data = route.params || getCurrentData(param.domain!);
        if (!data) {
          setTimeout(() => {
            to('login');
          }, 2000);
          return message.error('登录过期，跳转至首页失败');
        }
        return to(`${data.objectApiName}/${data.dataId}`);
      case 'detail':
        return to(
          `detail/${route.params.objectApiName}/${route.params.dataId}`
        );
      case 'list': {
        return to(
          `list/${route.params.objectApiName}` +
            (route.params.associateDataId
              ? `?associateDataId=${route.params.associateDataId}`
              : '')
        );
      }
      case 'create': {
        const data = route.params;
        return to(`create/${data.objectApiName}${queryToString(route.query)}`);
      }
      case 'edit': {
        const data = route.params;
        return to(
          `edit/${data.objectApiName}/${data.dataId}${queryToString(
            route.query
          )}`
        );
      }
      case 'result': {
        return to(`result${queryToString(route.query)}`);
      }
      default:
        return to(route.to);
    }
  };
};

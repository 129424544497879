import {
  BrowserRouter,
  HashRouter,
  Route,
  Routes,
  matchRoutes,
  useLocation,
} from 'react-router-dom';
import { Suspense } from 'react';
import { useLoading } from '@dxp-hooks/useLoading';
import { basename } from '@dxp-utils/basename';

interface BaseRoute {
  path: string;
  name: string;
  element: React.ReactElement;
}

const RouterProxy: React.FN =
  process.env.NODE_ENV === 'production' || !basename
    ? (props) => {
        return (
          <BrowserRouter basename={basename}>{props.children}</BrowserRouter>
        );
      }
    : (props) => {
        return <HashRouter>{props.children}</HashRouter>;
      };

const BaseLayout: React.FC<{
  routes: BaseRoute[];
  children(route: BaseRoute): React.ReactElement;
}> = ({ routes, children }) => {
  const location = useLocation();
  const match = matchRoutes(routes, location.pathname);
  if (!match?.length) {
    return null;
  }
  const route = match[0].route;
  return children(route);
};

export const Router: React.FC<{
  routes: any[];
}> = ({ routes }) => {
  const [loading] = useLoading(true);
  return (
    <Suspense fallback={loading}>
      <RouterProxy>
        <Routes>
          {routes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                Component={route.Component}
                // element={
                //   Layout ? (
                //     <Suspense fallback={loading}>
                //       <BaseLayout routes={route.children}>
                //         {(route) => {
                //           return <Layout route={route}></Layout>;
                //         }}
                //       </BaseLayout>
                //     </Suspense>
                //   ) : null
                // }
              >
                {route.children.map((r: any) => (
                  <Route
                    key={r.name}
                    Component={r.Component}
                    path={r.path}
                    element={r.element}
                  ></Route>
                ))}
              </Route>
            );
          })}
        </Routes>
      </RouterProxy>
    </Suspense>
  );
};

/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-08-04 11:28:03
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2024-05-06 21:32:12
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import classNames from 'classnames';
import React from 'react';

export const Mask: React.FC<{
  className?: string;
  disabled?: boolean;
  top?: number;
  style?: React.CSSProperties;
  onClick?(): void;
}> = ({ className, disabled, style = {}, top = 0, ...props }) => {
  if (disabled) return null;
  return (
    <div
      onClick={props.onClick}
      style={{ ...style, top: top, height: `calc(100% - ${top}px)` }}
      className={classNames(
        'size-full top-0 left-0 bg-#1D212999 z-1009',
        className
      )}
    ></div>
  );
};

export default Mask;

import { env } from '@dxp-terminal/env';
import CryptoJS from 'crypto-js';
import { WX_ADVANCED_APP_ID } from '../config';
import { uuid } from '@gaker/tools';
import { getWxJsApiTicket } from '@dxp-api';

let ticket = '';
let lastTime = 0;
let counter = 0;

let initPromise: Promise<any> | undefined;

const isExpired = () => Date.now() - lastTime > 1.5 * 60 * 60 * 1000; // 是否过期，true过期

const sha1 = (str: string) => CryptoJS.SHA1(str).toString();

const getTicket = (): Promise<string> => {
  if (ticket && !isExpired()) {
    // 官方是2小时过期，为了稳妥，本地缓存1.5小时即过期
    return Promise.resolve(ticket);
  }
  return getWxJsApiTicket({})
    .then((data) => {
      if (data.result && data.data) {
        ticket = data.data;
        lastTime = Date.now();
        counter = 0;
        return ticket;
      }
      return Promise.reject();
    })
    .catch((e) => {
      if (counter > 0) {
        counter = 0;
        return Promise.reject(e);
      }
      counter++;
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          getTicket().then(resolve).catch(reject);
        }, 1000);
      });
    });
};

const buildSignature = async () => {
  const timestamp = Math.ceil(Date.now() / 1000); //  + '';
  const nonceStr = uuid(12); // ;
  const ticket = await getTicket();

  const url = env.isProduct
    ? window.location.href.split('#')[0]
    : 'https://portaltest.gaker.com'; // window.location.href;

  const str = `jsapi_ticket=${ticket}&noncestr=${nonceStr}&timestamp=${timestamp}&url=${url}`;

  const signature = sha1(str);
  console.log('str--->', str);
  console.log('ticket--->', ticket);
  console.log('timestamp-- > ', timestamp);
  console.log('nonceStr-->' + nonceStr);
  console.log('url--->', url);
  console.log('signature--->' + signature);
  return {
    timestamp,
    nonceStr,
    signature,
  };
};

const init = async () => {
  if (!isExpired() && initPromise) {
    return initPromise;
  }
  try {
    const signature = await buildSignature();

    initPromise = new Promise((resolve, reject) => {
      let timer: any = 0;
      wx.ready(() => {
        timer = setTimeout(resolve, env.isProduct ? 1500 : 5000);
      });
      wx.error((e) => {
        reject(e);
        clearTimeout(timer);
        initPromise = undefined;
      });
      wx.config({
        debug: !env.isProduct, // , // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: WX_ADVANCED_APP_ID, // 必填，公众号的唯一标识
        ...signature,
        jsApiList: ['updateAppMessageShareData', 'onMenuShareAppMessage'], // 必填，需要使用的JS接口列表
      });
    });
    return initPromise;
  } catch (e) {
    initPromise = undefined;
    return Promise.reject(e);
  }
};

export const wxReady = (callback: () => Promise<any>) => {
  if (!callback) return init();
  return init().then(callback);
};

export const wxShareData = (
  params: Omit<WX.JSSDK.ShareData, 'success' | 'fail'>
) => {
  return new Promise<any>((resolve, reject) => {
    const data = {
      title: '邀请成员注册', // 分享标题
      desc: params.desc, // 分享描述
      link: params.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
      imgUrl: params.imgUrl, // 分享图标
      success() {
        resolve({});
      },
      fail(e: any) {
        reject(e);
      },
    } as any;
    if (wx.updateAppMessageShareData) {
      wx.updateAppMessageShareData(data);
    } else {
      (wx as any).onMenuShareAppMessage(data);
    }
  });
};

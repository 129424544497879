import { DTO, IDxpEdit, IDxpEditor } from '@dxp-declare';
import {
  getCreator as GetCreator,
  postCreator as PostCreator,
  get,
} from '@dxp-utils/request';
import {
  createTitleBarComponent,
  createTopBarComponentByLayout,
} from '@dxp-utils/createComponent';
import { FieldType } from '@gaker/models';
import { basename } from '@dxp-utils/basename';
import { Models } from '@declare-models';

// @mark  本地加前缀进行区分方便本地代理
export const buildUrl = (url: string) => {
  const basenameUrl = (url: string) => {
    if (basename) return `/${basename}${url}`;
    return url;
  };
  return process.env.NODE_ENV === 'production' ? url : basenameUrl(url);
};

const getCreator: typeof GetCreator = (url: string) => {
  return GetCreator(buildUrl(url));
};
const postCreator: typeof PostCreator = (url: string) => {
  return PostCreator(buildUrl(url));
};

const URLS = {
  objectList: '/api/v1/portal/metadata/objects',
  objectDetail: '/api/v1/portal/metadata/fields/{objectApiName}',
  getDxpConfig:
    basename === 'portal'
      ? '/api/v1/portal/metadata/layout/draft/{objectApiName}_{type}_dxp_config'
      : '/api/v1/portal/metadata/layout/{objectApiName}_{type}_dxp_config',
  saveDxpConfig: '/api/v1/portal/metadata/layout/draft/{id}_{type}_dxp_config',
  getDxpReleaseConfig:
    '/api/v1/portal/metadata/layout/release/{objectApiName}_{type}_dxp_config',
  releaseDxp:
    '/api/v1/portal/metadata/layout/release/{objectApiName}_{type}_dxp_config',
  getRecentId: '/api/v1/portal/data/recent/{objectApiName}',
  loadRichText: '/api/v1/portal/data/richText/{richId}',
  saveRichText: '/api/v1/portal/data/richText',
  loadObjectData: '/api/v1/portal/data/pages',
  loadFieldMeta: '/api/v1/portal/metadata/fields',
  getInviteUserInfo: '/api/v1/portal/user/invite/{inviteId}',
  getInviteCode: '/api/v1/portal/user/invite/registerCode',
  setInviteUserPwd: '/api/v1/portal/user/password',
  activeInviteUser: '/api/v1/portal/user/active',
  getLoginCode: '/api/v1/portal/user/sendCode',
  login: '/api/v1/portal/user/login',
  getCurrentUser: '/api/v1/portal/user/currentUser',
  tradeSite: '/api/v1/portal/metadata/site',
  productionTradeSite: '/api/v1/portal/metadata/site/{domainId}',
  getInviteUrl: '/api/v1/portal/user/inviteUrl/{objectApiName}/{dataId}',
  getExpressAffect: '/api/v1/portal/metadata/expressAffect', // 获取字段表达式关联影响信息
  calculate: '/api/v1/portal/data/real/calculate', //表达式实时计算
  addData: '/api/v1/portal/data/addData', // 新增数据
  userList: '/api/v1/portal/user/list',
  resetPassword: '/api/v1/portal/user/resetPassword',
  members: '/api/v1/portal/data/members',
  homeObjectList: '/api/v1/portal/metadata/objects/{type}',
  divisionList: '/api/v1/portal/metadata/division',
  objectNameMap: '/api/v1/portal/metadata/objectName/{type}',
  getWxUserInfo: '/api/v1/portal/wechat/userinfo',
  loginByWx: '/api/v1/portal/wechat/login',
  bindWxUser: '/api/v1/portal/wechat/bind',
  updateLoginTime: '/api/v1/portal/user/updateLoginTime/{dataId}',
  WxJsApiTicket: '/api/v1/portal/wechat/jsapi_ticket',
  getPortalLayout: '/api/v1/tenant/object/layout/getPortalLayout',
  updateData: '/api/v1/portal/data/updateData',
  deleteData: '/api/v1/portal/data/deleteData',
};

export const getObjectList = getCreator<
  IDxpEditor.Models.IObjectVO[],
  {
    type: IDxpEditor.Types.TDoorType;
  }
>(URLS.objectList);

export const getObjectDetail = getCreator<
  {
    fields: IDxpEditor.Models.IFieldVO[];
    subObjects: IDxpEditor.Models.IObjectVO[];
  },
  { objectApiName: string }
>(URLS.objectDetail);

export const loadObjectData = postCreator<
  {
    content: Record<string, any>[];
    pageInfo: { pageNum: number; pageSize: number };
    total: number;
  },
  {
    objectApiName: string;
    dataId?: string;
    fieldApiNames: string[];
    fieldFilters?: {
      fieldApiName?: string;
      associateObjectApiName?: string;
      fieldValue: string | string[];
    }[];
    orderBy?: string;
    direction?: 'DESC' | 'ASC';
    pageNum?: number;
    pageSize?: number;
  }
>(URLS.loadObjectData);

const defaultLayout = 'form-list.flex';

export const initConfig: any = () => {
  return {
    setting: {
      layout: { type: defaultLayout, titleBarTop: false },
      pcView: 'table',
      listOption: '',
      mobile2Col: false,
    },
    buffer: {
      device: 'computer',
      current: null,
      currentPath: [],
    },
    titleBar: createTitleBarComponent(),
    components: createTopBarComponentByLayout(defaultLayout),
  };
};

const _getDxpConfig = getCreator<
  { id: string; objectName: string; config: IDxpEditor.IEditor },
  {
    objectApiName: string;
    type: IDxpEditor.Types.TDoorType;
  }
>(URLS.getDxpConfig);

// 获取原来的配置  不对外开放功能
export const getOldDxpConfig = (params: {
  objectApiName: string;
}): Promise<IDxpEditor.IEditor> => {
  return get(
    buildUrl('/api/v1/portal/metadata/layout/draft/{objectApiName}_dxp_config'),
    params
  ).then((data) => {
    const configStr: any = data.data?.config;
    try {
      return JSON.parse(configStr);
    } catch (e) {
      return Promise.reject(e);
    }
  });
};

export const getDxpConfig = (
  params: {
    objectApiName: string;
    type: IDxpEditor.Types.TDoorType;
  },
  server: typeof _getDxpConfig = _getDxpConfig
) => {
  return server(params).then((data) => {
    const configStr: any = data.data?.config;
    let config: IDxpEditor.IEditor = (() => {
      try {
        return JSON.parse(configStr);
      } catch (e) {}
    })();
    if (config) {
      if (config.components) {
        config.buffer = {
          current: null as any,
          device: 'computer',
          currentPath: [],
        };
      } else {
        config = undefined as any;
      }
    }
    return {
      ...data,
      data: {
        ...data.data,
        config: config,
      },
    };
  });
};

const _getDxpReleaseConfig = getCreator<
  { id: string; objectName: string; config: IDxpEditor.IEditor },
  {
    objectApiName: string;
    type: IDxpEditor.Types.TDoorType;
  }
>(URLS.getDxpReleaseConfig);

export const getDxpReleaseConfig = (params: {
  objectApiName: string;
  type: IDxpEditor.Types.TDoorType;
}) => {
  return getDxpConfig(params, _getDxpReleaseConfig);
};

export const saveDxpConfig = postCreator<
  any,
  {
    objectApiName: string;
    id: string;
    objectName: string;
    layoutObjects: { objectApiName: string }[];
    config: IDxpEditor.IEditor;
    type: IDxpEditor.Types.TDoorType;
  }
>(URLS.saveDxpConfig);

export const releaseDxp = postCreator<
  any,
  {
    objectApiName: string;
    type: IDxpEditor.Types.TDoorType;
  }
>(URLS.releaseDxp);

export const loadFieldMeta = postCreator<
  Record<
    string,
    IDxpEditor.Components.IFieldComponentBaseConfig<FieldType>['field']
  >,
  { objectApiName: string; fieldApiNames: string[] }
>(URLS.loadFieldMeta);

export const getRecentId = getCreator<string, { objectApiName: string }>(
  URLS.getRecentId
);

export const loadRichText = getCreator<string, { richId: string }>(
  URLS.loadRichText
);

export const saveRichText = postCreator<
  string,
  | {
      textId: string;
      content: string;
    }
  | {
      objectApiName: string;
      fieldApiName: string;
      content: string;
    }
>(URLS.saveRichText);

export const getInviteUserInfo = getCreator<
  {
    countryCode: string;
    inviteUserName: string;
    logo: string;
    siteName: string;
    telephone: string;
    tenantId: string;
    userName: string;
  },
  { inviteId: string }
>(URLS.getInviteUserInfo);

export const getInviteCode = postCreator<
  {
    sendCode: boolean; // 验证码是否发送成功，如果返回的话，总是为true
    isRegistered: boolean;
  },
  { inviteId: string; countryCode: string; telephone: string }
>(URLS.getInviteCode);

export const setInviteUserPwd = postCreator<
  {
    dataId: string;
    objectApiName: string;
    token: string;
  },
  {
    inviteId: string;
    countryCode: string;
    telephone: string;
    userName: string;
    password: string;
    code: string;
  }
>(URLS.setInviteUserPwd);

export const activeInviteUser = postCreator<
  {
    dataId: string;
    objectApiName: string;
    token: string;
  },
  {
    inviteId: string;
    countryCode: string;
    telephone: string;
    userName: string;
    code: string;
  }
>(URLS.activeInviteUser);

export const getLoginCode = postCreator<
  any,
  {
    countryCode: string; // 必需
    telephone: string; // 必需
    sendType: 'LOGIN' | 'FORGET_PASSWORD' | 'WECHAT_BIND'; // LOGIN 登录 、FORGET_PASSWORD 忘记密码
  }
>(URLS.getLoginCode);

type LoginType = 'PASSWORD' | 'CODE';

type LoginBase<Type extends LoginType, Extra extends any> = {
  countryCode: string; // 必需
  telephone: string; // 必需
  path: string;
  loginType: Type; // 登录类型 PASSWORD 密码登录 、CODE 验证码登录 必需
} & Extra;

export const userLogin = postCreator<
  {
    dataObject: Record<string, string>;
    objectApiName: string; // 当前用户是哪个对象下的成员，目前为客户
    token: string; // 登录成功后的token
  },
  | LoginBase<
      'PASSWORD',
      {
        password: string;
      }
    >
  | LoginBase<'CODE', { code: string }>
>(URLS.login);

export const getCurrentUser = getCreator<
  IDxpEditor.Models.CurrentUserVO,
  { dataId: string }
>(URLS.getCurrentUser);

export const getTradeSite = getCreator<
  IDxpEditor.Models.WebSite,
  {
    type: IDxpEditor.Types.TDoorType;
  }
>(URLS.tradeSite);

export const saveTradeSite = postCreator<any, IDxpEditor.Models.WebSite>(
  URLS.tradeSite
);

export const getProductionTradeSite = getCreator<
  IDxpEditor.Models.WebSite,
  { domainId: string }
>(URLS.productionTradeSite);

export const getInviteUrl = getCreator<
  string,
  { objectApiName: string; dataId: string }
>(URLS.getInviteUrl);

export const getExpressAffect = getCreator<DTO.Common.IExpressAffectDTO, {}>(
  URLS.getExpressAffect
);

type TOjectApiName = string;
type TFieldApiNameKey = string;
interface ICalculateFormData extends IDxpEdit.IFormDataDTO {
  changeObject: TOjectApiName;
  changeField: TFieldApiNameKey;
  changeDataIds: string[];
  targetFieldKeys: DTO.Common.RefFieldKey[];
}

export const calculatePost = postCreator<
  IDxpEdit.IFormDataDTO,
  ICalculateFormData
>(URLS.calculate);

export const addData = postCreator<
  IDxpEdit.IFormDataDTO,
  IDxpEdit.IFormDataDTO & {
    portalMasterObjectApiName: string;
    portalMasterDataId: string;
  }
>(URLS.addData);

export const updateData = postCreator<
  any,
  IDxpEdit.IFormDataDTO['masterData'] & {
    fieldApiName?: string; // 子表单字段名
    subDataId?: string; // 子表单数据ID
  }
>(URLS.updateData);

export const deleteData = postCreator<
  any,
  {
    objectApiName: string;
    dataId: string;
    fieldApiName?: string; // 子表单字段ApiName
    subDataIds?: string[]; // 子表单字段ApiName
  }
>(URLS.deleteData);

export const getUserList = getCreator<
  IDxpEditor.Models.IMemberUser[],
  { dataId: string; sort?: `createTime|${'desc' | 'asc'}` }
>(URLS.userList);

export const resetPassword = postCreator<
  any,
  {
    countryCode: string; // 必需
    telephone: string; // 必需
    password: string;
    code: string;
  }
>(URLS.resetPassword);

export const getMembers = getCreator<
  {
    members: {
      telephone: string;
      userId: string;
      userName: string;
      accountStatus: 'enabled';
    }[];
    participant: {
      telephone: string;
      userId: string;
      userName: string;
      accountStatus: 'enabled';
    }[];
    owner: {
      telephone: string;
      userId: string;
      userName: string;
      accountStatus: 'enabled';
    };
  },
  {
    objectApiName: string;
    dataId: string;
  }
>(URLS.members);

export const homeObjectList = getCreator<
  {
    objectIcon: string;
    objectName: string;
    objectApiName: string;
    objectType: string;
  }[],
  { type: IDxpEditor.Types.TDoorType }
>(URLS.homeObjectList);

export const objectNameMap = getCreator<
  Record<string, string>,
  {
    type: IDxpEditor.Types.TDoorType;
  }
>(URLS.objectNameMap);

export const getDivision = getCreator<
  IDxpEditor.Models.IRegionDTO[],
  {
    nationId?: string;
    provinceId?: string;
  }
>(URLS.divisionList);

export const getWxUserInfo = getCreator<
  IDxpEditor.Models.WxUserInfo,
  { code: string }
>(URLS.getWxUserInfo);

export const loginByWx = postCreator<
  {
    dataObject: Record<string, string>;
    objectApiName: string; // 当前用户是哪个对象下的成员，目前为客户
    token: string; // 登录成功后的token
    isUnionIdBindUser: boolean;
    wechatUserDTO?: IDxpEditor.Models.WxUserInfo;
  },
  {
    code: string;
    type: 'web' | 'oap';
    domainId: string;
  }
>(URLS.loginByWx);

export const bindWxUser = postCreator<
  {
    dataObject: Record<string, string>;
    objectApiName: string; // 当前用户是哪个对象下的成员，目前为客户
    token: string; // 登录成功后的token
    isUnionIdBindUser: boolean;
    wechatUserDTO?: IDxpEditor.Models.WxUserInfo;
  },
  {
    domainId: string; // 门户ID
    countryCode: string; // 手机号
    telephone: string;
    code: string; // 验证码
    wechatUserDTO: IDxpEditor.Models.WxUserInfo;
  }
>(URLS.bindWxUser);

export const updateLoginTime = postCreator<any, { dataId: string }>(
  URLS.updateLoginTime
);

export const getWxJsApiTicket = getCreator<string, {}>(URLS.WxJsApiTicket);

export const getPortalLayout = getCreator<
  DTO.Common.ILayout,
  { objectApiName: string }
>(URLS.getPortalLayout);

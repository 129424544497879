import { useApi } from '@dxp-api/hooks';
import { IDxpEditor } from '@dxp-declare';
import useWebsite from '@dxp-hooks/useWebsite';
import { useDataId } from '@dxp-presets/context';
import { useRequest } from '@gaker/hooks';
import { Avatar, Button, Tooltip } from 'antd';
import React, { Suspense, useMemo } from 'react';
import { DefaultAvatar } from '@gaker/components';
import { Dialog } from '@gaker/gaker-ui';
import UserInvite from '../userlist/invite';
import { Right as _Right } from '@icon-park/react';;import { IconPark as IconParkHOC } from '@gaker/components';
        const Right = IconParkHOC(_Right)
      
import classNames from 'classnames';

const Account: React.FC<{
  icon?: string;
  title: string;
  gotoUserList?(): void;
}> = (props) => {
  const website = useWebsite();
  const dataId = useDataId();
  const [instance] = Dialog.useDialog();
  const { data } = useRequest(useApi('getMembers'), {
    defaultParams: [
      {
        objectApiName: IDxpEditor.Models.DoorObjectApiName[website.type],
        dataId: dataId,
      },
    ],
  });

  const ownerList = useMemo(() => {
    const list: {
      telephone: string;
      userId: string;
      userName: string;
      accountStatus: 'enabled';
    }[] = [];
    if (data?.owner) {
      list.push(data.owner);
    }
    return list.concat(data?.participant || []);
  }, [data]);

  const memberList = useMemo(() => {
    if (!data?.members) return [];
    return data.members;
  }, [data?.members]);

  return (
    <div className="w-full p-g-s m-b-g-xl">
      <div className="flex gap-g-xl">
        {props.icon && (
          <img className="size-64px rd-g-normal" src={props.icon}></img>
        )}
        <div className="flex flex-col justify-start">
          <span className="title-g-small">{props.title}</span>
          {/* <div className="text-g-normal">
            <span className="text-g-grey-6">帐号：</span>C2321321
          </div> */}
        </div>
      </div>

      <div className="m-t-g-xl flex flex-row items-center gap-g-l">
        <span className="text-g-grey-6 text-g-normal">乙方成员</span>
        <Avatar.Group>
          {ownerList
            .slice(0, ownerList.length > 6 ? 3 : 6) // 6个是全量
            .map((user, index) => {
              return (
                <Tooltip
                  key={user.userId}
                  title={user.userName}
                  placement="top"
                >
                  <DefaultAvatar
                    className={classNames({
                      '!ms-0px': true,
                      // '!ms-8px': index === 1,
                      // '!ms-4px': index > 1,
                    })}
                    size="small"
                    title={user.userName}
                  ></DefaultAvatar>
                </Tooltip>
              );
            })}
        </Avatar.Group>
        {ownerList.length > 6 && (
          <span className="flex flex-row items-center">
            共<span className="color-g-primary">{ownerList.length}</span>人
            <Right></Right>
          </span>
        )}
      </div>
      <div className="m-t-g-xl flex flex-row justify-between items-center">
        <div
          className="flex flex-row gap-g-l items-center cursor-pointer"
          onClick={props.gotoUserList}
        >
          <span className="text-g-grey-6 text-g-normal shrink-0">我方成员</span>
          <Avatar.Group>
            {memberList.slice(0, 4).map((user, index) => {
              return (
                <Tooltip
                  key={user.userId}
                  title={user.userName}
                  placement="top"
                >
                  <DefaultAvatar
                    className={'!ms-0px'}
                    size="small"
                    title={user.userName}
                  ></DefaultAvatar>
                </Tooltip>
              );
            })}
          </Avatar.Group>
          <span className="flex flex-row items-center">
            共<span className="color-g-primary">{memberList.length || 0}</span>
            人<Right></Right>
          </span>
        </div>
        <Dialog
          width={400}
          instance={instance}
          content={<UserInvite onClose={() => instance.hide()} />}
        >
          <Button
            size="small"
            shape="round"
            type="primary"
            className="m-l-g-l "
            onClick={() => instance.show()}
          >
            邀请
          </Button>
        </Dialog>
      </div>
    </div>
  );
};

export default Account;

import { IDxpEditor } from '@dxp-declare';
import {
  createTitleBarComponent,
  createTopBarComponentByLayout,
} from '@dxp-utils/createComponent';
import { observable } from 'mobx';
import React, { useContext, useMemo } from 'react';

const Context = React.createContext<IDxpEditor.IEditor>({} as any);

const defaultLayout = 'form-list.flex';

let initConfig = (objectApiName: string): IDxpEditor.IEditor => {
  const titlebar = createTitleBarComponent();
  titlebar.config.showStatus = !/SubTable/.test(objectApiName);
  return {
    setting: {
      layout: { type: defaultLayout, titleBarTop: true },
      pcView: 'table',
      listOption: '',
      mobile2Col: false,
      createAuth: false,
      editAuth: [],
      filter: [],
    },
    buffer: {
      device: 'computer',
      current: null as any,
      currentPath: [],
    },
    titleBar: titlebar,
    components: createTopBarComponentByLayout(defaultLayout),
  };
};

const initEditConfig = () => {
  return {
    setting: {
      layout: { type: 'form.flex', titleBarTop: true },
      pcView: 'table',
      listOption: '',
      mobile2Col: false,
    },
    buffer: {
      device: 'computer',
      current: null,
      currentPath: [],
    },
    titleBar: createTitleBarComponent(),
    components: createTopBarComponentByLayout('form.flex'),
  };
};

export const EditorStore: React.FN<{
  config?: IDxpEditor.IEditor | null;
  objectApiName: string;
  mode?: IDxpEditor.Types.TPageMode;
}> = (props) => {
  const config = useMemo(() => {
    if (!props.config)
      return observable(
        props.mode === 'create'
          ? initEditConfig()
          : initConfig(props.objectApiName)
      );
    return observable(props.config);
  }, [props.config, props.objectApiName, props.mode]);
  return (
    <Context.Provider value={config as any}>{props.children}</Context.Provider>
  );
};

export const useEditorStore = () => {
  return useContext(Context);
};

export default useEditorStore;

import { getDivision } from '@dxp-api';
import { RegionProvider } from '@dxp-api/hooks';
import { IDxpEditor } from '@dxp-declare';
import { useRequest } from '@gaker/hooks';
import { useMemo } from 'react';

const SystemPage: React.FN = (props) => {
  const { data } = useRequest(getDivision);
  const regionData = useMemo(() => {
    if (!data) return [];
    return data.map<IDxpEditor.Models.IRegionVO>(function deep(
      item
    ): IDxpEditor.Models.IRegionVO {
      return {
        pid: item.pid,
        value: item.id,
        label: item.value,
        children: item.cityList?.map(deep),
      };
    });
  }, [data]);
  return <RegionProvider value={regionData}>{props.children}</RegionProvider>;
};

export default SystemPage;

const proxy = (log: typeof console.log) => {
  if (process.env['NODE_ENV'] === 'production') return () => {};
  return log;
};

export const logger = {
  log: proxy(console.log.bind(console)),
  warn: proxy(console.warn.bind(console)),
  error: proxy(console.error.bind(console)),
  info: proxy(console.info.bind(console)),
};

export default logger;

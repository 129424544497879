import { HOC } from '@gaker/tools';
import React, { Suspense } from 'react';

type TAdapterMode = 'pc' | 'mobile' | 'pad';

const AdapterContext = React.createContext<TAdapterMode>('pc');

const Adapter: React.FN<{ mode: TAdapterMode }> = (props) => {
  return (
    <AdapterContext.Provider value={props.mode}>
      {props.children}
    </AdapterContext.Provider>
  );
};

export const useAdapter = () => {
  return React.useContext(AdapterContext);
};

export const createAdapterComponent = <T extends any>(
  PCComponent: () => Promise<any>,
  MobileComponent: () => Promise<any>
): React.FN<T> => {
  const PCLazyComponent = HOC(Suspense, { fallback: <></> })(
    React.lazy(PCComponent)
  );
  const MobileLazyComponent = HOC(Suspense, { fallback: <></> })(
    React.lazy(MobileComponent)
  );
  return (props) => {
    const mode = useAdapter();
    return mode === 'mobile' ? (
      <MobileLazyComponent {...props}></MobileLazyComponent>
    ) : (
      <PCLazyComponent {...props}></PCLazyComponent>
    );
  };
};

export default Adapter;

import React from 'react';
import { RunnerAuth, RunnerBody, RunnerObjectName } from './RunnerPage';
import { Home, Left, More, ShareOne, Write } from '@icon-park/react';
import { useRouterRedirect } from '@dxp-hooks/useRouterRedirect';
import { HOC } from '@gaker/tools';
import {
  DetailPageInfoProvider,
  useDetailPageInfo,
} from '@dxp-hooks/useDetailPageName';
import { useNavigate, useParams } from 'react-router-dom';
import emitter from '@dxp-utils/emitter';
import {
  FieldEditEnableProvider,
  useFieldEditAuthList,
} from '@dxp-presets/context';
import { env } from '@dxp-terminal/env';

const Right: React.FC = () => {
  const params = useParams<{ objectApiName: string; dataId: string }>();
  const list = useFieldEditAuthList() || [];
  return (
    <div className="flex items-center gap-g-m p-r-g-xl">
      {list.length > 0 && (
        <Write
          className="cursor-pointer"
          size={20}
          strokeWidth={4}
          onClick={(event) => {
            event.stopPropagation();
            emitter.emit('object-edit', params as any);
          }}
        />
      )}
      {/* <ShareOne className="cursor-pointer" size={20} strokeWidth={4} /> */}
      {/* <More size={16} strokeWidth={4} /> */}
    </div>
  );
};

const DetailPage: React.FN<{ isNotAuth?: boolean }> = (props) => {
  const navigate = useNavigate();
  const { name } = useDetailPageInfo();
  return (
    <RunnerBody
      left={
        <div
          className="absolute flex-center h-100% left-5px"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Left
            size={20}
            strokeWidth={4}
            className="cursor-pointer m-r-g-s"
            onClick={() => navigate(-1)}
          />
          {/* ms-3px是为是让顶部标题信息与下文的字段名称对齐*/}
          <span className="title-g-small ms-1px">{name}详情</span>
        </div>
      }
      right={
        <div className="absolute right-0px flex-center h-100% m-l-g-m">
          <Right></Right>
        </div>
      }
    >
      <RunnerObjectName>{props.children}</RunnerObjectName>
    </RunnerBody>
  );
};

export default HOC(DetailPageInfoProvider, { value: { name: '' } })(
  HOC(RunnerAuth)(HOC(FieldEditEnableProvider)(DetailPage))
);

import { Color } from '../define';

export const setPrimaryColor = (color: string) => {
  Color.updateBrandColor(color);
  document.documentElement.style.setProperty(
    Color.generateVar('primary'),
    Color.PrimaryColor
  );
  Object.keys(Color.BrandColor).forEach((key) => {
    document.documentElement.style.setProperty(
      Color.generateVar(key),
      Color.BrandColor[key]
    );
  });
};

import React from 'react';
import Runner from './runner';
import { IDxpEditor } from '@dxp-declare';

const Run: React.FC<{
  canvas?(
    config: IDxpEditor.IEditor,
    object: {
      objectApiName: string;
    }
  ): React.ReactNode;
}> = (props) => {
  return (
    <Runner {...props}>{/* <AddForm isMaster={true}></AddForm> */}</Runner>
  );
};

export default Run;

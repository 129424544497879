import { Color } from '.';

const border = (width: number, style: 'dashed' | 'solid') => {
  return {
    borderStyle: style,
    borderWidth: width,
    borderColor: Color.NeutralColor['grey-3'],
  };
};
const borderRadius = (radius: number) => {
  return {
    borderRadius: radius,
  };
};

export const BorderRadius = {
  Normal: borderRadius(8),
  Small: borderRadius(4),
  Large: borderRadius(10), //todo
};

export const Border = {
  SolidStroke: border(1, 'solid'),
  SolidNormal: border(2, 'solid'),
  SolidBold: border(3, 'solid'),
  DashedStroke: border(1, 'dashed'),
  DashedNormal: border(2, 'dashed'),
  DashedBold: border(3, 'dashed'),
};

const shadow = (x: number, y: number, b: number, s: number) => {
  return {
    offsetX: x,
    offsetY: y,
    blurRadius: b,
    spreadRadius: s,
    color: '#00000020',
  };
};

export const Shadow = {
  Level1: shadow(0, 2, 5, 0),
  Level2: shadow(0, 4, 10, 0),
  Level3: shadow(0, 8, 20, 0),
};

import { createContext } from '@gaker/tools';
import { useEffect, useRef } from 'react';

export const [useKeepAliveState, KeepAliveStateProvider] = createContext(
  true,
  (v) => v
);

export const useShow = (handler: () => void) => {
  const state = useKeepAliveState();
  const call = useRef(handler);
  call.current = handler;
  useEffect(() => {
    if (state) {
      call.current();
    }
  }, [state]);
};

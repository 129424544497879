import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useScreen } from '@gaker/hooks';
import { createPortal } from 'react-dom';
import Mask from './mask';

interface IDialogInstance {
  show(): void;
  hide(): void;
}

interface IDialogProps {
  appendToBody?: boolean;
  visible?: boolean;
  content: React.ReactNode;
  instance?: DialogInstance;
  width?: number | string;
  zIndex?: number;
  onInit?(): void;
}

class DialogInstance {
  private __ref: React.RefObject<IDialogInstance>;
  id = Date.now();
  constructor(ref: React.RefObject<IDialogInstance>) {
    this.__ref = ref;
  }
  private get inst() {
    return this.__ref.current;
  }
  show() {
    this.inst && this.inst.show();
  }
  hide() {
    this.inst && this.inst.hide();
  }
  getRef() {
    return this.__ref;
  }
}

const DialogBody: React.FN<IDialogProps> = (props) => {
  // const appendTo = useMemo(() => {
  //   if (props.appendToBody === false) return (el?: HTMLElement | null) => {};
  //   return (el?: HTMLElement | null) => el && document.body.appendChild(el);
  // }, [props.appendToBody]);
  const screen = useScreen();
  const portal = createPortal(
    <div>
      <Mask
        className="fixed"
        style={props.zIndex ? { zIndex: props.zIndex - 1 } : {}}
      ></Mask>
      <div
        className="fixed size-full top-0 left-0 flex flex-center z-1010"
        style={{
          zIndex: props.zIndex,
        }}
      >
        <div
          style={{ width: screen.width < 720 ? '100%' : props.width }}
          className={`${screen.width < 720 ? 'p-g-xl' : ''}`}
        >
          {props.content}
        </div>
      </div>
    </div>,
    document.body
  );
  useEffect(() => props.onInit?.(), []);

  return (
    <>
      {portal}
      {props.children}
    </>
  );
};

const Dialog: React.FN<IDialogProps> & {
  useDialog: () => [DialogInstance];
} = (props) => {
  const [visible, setVisible] = useState(props.visible || false);

  useEffect(() => {
    if (typeof props.visible === 'boolean') setVisible(props.visible);
  }, [props.visible]);

  useImperativeHandle(
    props.instance?.getRef(),
    () => {
      return {
        show() {
          setVisible(true);
        },
        hide() {
          setVisible(false);
        },
      };
    },
    []
  );
  if (visible) return <DialogBody {...props}></DialogBody>;
  return <>{props.children}</>;
};

Dialog.useDialog = () => {
  const inst = useRef(new DialogInstance(useRef(null)));
  return [inst.current];
};

export default Dialog;

import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';

export const useLoading = (
  loading?: boolean
): [
  React.ReactNode,
  {
    isLoading: boolean;
    loading: (loadingStatus?: boolean) => void;
  }
] => {
  const [isLoading, setIsLoading] = useState(!!loading);
  useEffect(() => {
    setIsLoading(!!loading);
  }, [loading]);
  const context = isLoading ? (
    <Spin
      spinning={isLoading}
      className="size-full"
      style={{ maxHeight: '100%' }}
      wrapperClassName="size-full absolute flex flex-center z-1000 bg-#F2F3F599"
    >
      <div className="size-full"></div>
    </Spin>
  ) : null;
  return [
    context,
    {
      isLoading,
      loading(loading = true) {
        setIsLoading(loading);
      },
    },
  ];
};

type StorageValue<T> = T | null;

interface IStorage {
  set: <T>(key: string, value: T, options?: Record<string, any>) => void;
  get: <T>(key: string) => StorageValue<T>;
  remove: (key: string) => void;
  clear: () => void;
}

function createWrapper(storage: Storage): IStorage {
  return {
    set<T>(key: string, value: T, options?: Record<string, any>): void {
      let data = value;
      if (typeof options?.transform === 'function') {
        data = options.transform(value);
      }
      storage.setItem(key, JSON.stringify(data));

      if (options?.debug) {
        console.log(`[Storage] set ${key}:`, data);
      }
    },

    get<T>(key: string): StorageValue<T> {
      const item = storage?.getItem(key);
      if (item !== null) {
        try {
          const value = item.includes(':') ? JSON.parse(item) : item;
          if (
            typeof value === 'object' &&
            value !== null &&
            typeof value._type === 'string'
          ) {
            if (typeof DataFactory[value._type]?.deserialize === 'function') {
              return DataFactory[value._type].deserialize(value);
            }
          } else {
            return value as T;
          }
        } catch (error) {
          console.error(`[Storage] parse error: ${key}=${item}, error:`, error);
          return null;
        }
      }
      return null;
    },

    remove(key: string): void {
      storage.removeItem(key);
    },

    clear(): void {
      storage.clear();
    },
  };
}

interface IData {
  _type: string;
  serialize: () => string;
}

interface IDataFactory<T extends IData> {
  deserialize: (data: any) => T;
}

const DataFactory: Record<string, IDataFactory<any>> = {};

function registerData(name: string, factory: IDataFactory<any>): void {
  DataFactory[name] = factory;
}

registerData('Date', {
  deserialize(data: any): Date {
    return new Date(data.value);
  },
});

registerData('RegExp', {
  deserialize(data: any): RegExp {
    return new RegExp(data.source, data.flags);
  },
});

registerData('Set', {
  deserialize(data: any): Set<any> {
    return new Set(data.value);
  },
});

registerData('Map', {
  deserialize(data: any): Map<any, any> {
    const map = new Map();
    Object.entries(data.value).forEach(([key, value]) => {
      map.set(key, value);
    });
    return map;
  },
});

/** cookie */
// export const storageC = createWrapper(document.cookie as any);
/** 会话缓存 */
export const storageS = createWrapper(sessionStorage);
/** 本地缓存 */
export const storage = createWrapper(localStorage);

export default storage;

/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-10-25 15:26:33
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2024-01-02 11:32:20
 * @FilePath: /gaker/libs/theme/src/iconPark/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import {
  IconProvider,
  DEFAULT_ICON_CONFIGS,
} from '@icon-park/react/es/runtime';
import { Size } from '../define';
const IconParkHOC = <T extends { className?: string }>(
  Component: React.FunctionComponent<T>
): React.FC<T> => {
  return (props) => {
    return React.createElement(
      IconProvider,
      {
        value: {
          ...DEFAULT_ICON_CONFIGS,
          size: Size.IconSize.normal,
        },
      },
      [
        React.createElement(Component, {
          ...props,
          key: 'c3',
          strokeWidth: 3,
          className: `icon-normal ${props?.className}`,
        }),
      ]
    );
  };
};

export default IconParkHOC;

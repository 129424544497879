import createEmitter from '../createEmitter';

interface IPostMessageEvent {
  type: string;
  data: any;
}

export const createPostMessage = <
  T extends { [key in string | symbol]: any }
>() => {
  const emitter = createEmitter<T>();

  const prefix_event = 'DXP_POST_MESSAGE';

  const getEvent = (eventName: any, data: any): IPostMessageEvent => {
    return { type: `${prefix_event}_${eventName}`, data };
  };

  const getId = (eventName: any) => `${eventName}_call__back`;

  const getEventNameByType = (type: string) => {
    if (!type) return;
    if (type.startsWith(prefix_event))
      return type.slice(prefix_event.length + 1);
    return '';
  };

  window.addEventListener('message', (event) => {
    try {
      const sourceWindow = event.source;
      const data: IPostMessageEvent = JSON.parse(event.data);
      const eventName = getEventNameByType(data.type);
      if (eventName) {
        const callback = (resolve: boolean, data: any) => {
          sourceWindow?.postMessage(
            JSON.stringify(
              getEvent(getId(eventName), { resolve: resolve, data })
            )
          );
        };
        emitter
          .emit(eventName, data.data)
          .then(callback.bind(null, true))
          .catch(callback.bind(null, false));
      }
    } catch (e) {}
  });

  const addEventListener = emitter.on.bind(emitter);

  const addOnceEventListener = emitter.once.bind(emitter);

  const useEventListener = emitter.useEmitter.bind(emitter);

  const postMessage = <Key extends keyof T>(
    eventName: Key,
    data: T[Key],
    sourceWindow?: Window,
    options?: { replaceByEmitter?: boolean } //当不是在iframe中时，是否通过emitter转发
  ) => {
    if (!sourceWindow) {
      sourceWindow = window.parent;
    }
    if (sourceWindow === window) {
      if (options?.replaceByEmitter) {
        console.info('postMessage  info: post by Emitter.emit', eventName);
        return emitter.emit(eventName, data);
      }
      console.warn('postMessage fail:parent is not exist; ', eventName);
      return Promise.reject(); // 如果是顶层 则不在发送消息}
    }
    sourceWindow.postMessage(JSON.stringify(getEvent(eventName, data)));
    return new Promise<any>((resolve, reject) => {
      emitter.once(getId(eventName), (event) => {
        if (event.resolve) resolve(event.data);
        else reject(event.data);
      });
    });
  };

  return {
    addEventListener,
    useEventListener,
    postMessage,
    addOnceEventListener,
  };
};

export default createPostMessage;

/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-12-05 22:39:19
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2024-05-06 21:16:47
 * @FilePath: /gaker/libs/tools/createContext/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';

type FN<P = object> = FunctionComponent<PropsWithChildren<P>>;

export const createContext = <
  T = any,
  Params = '_s_',
  Handler extends (value: T, ...args: any[]) => any = Params extends '_s_'
    ? (value: T) => any
    : (value: T, params: Params) => any
>(
  value: T,
  useValueHandler: Handler
): [
  Params extends '_s_'
    ? () => ReturnType<Handler>
    : (params: Params) => ReturnType<Handler>,
  FN<{ value: T }>,
  () => (value: T) => void,
  () => T
] => {
  const Context = React.createContext<{
    value: T;
    setValue: (value: T) => void;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  }>({ value: value, setValue() {} });

  const useContext = ((...args: any[]) => {
    return useValueHandler(React.useContext(Context).value, ...args);
  }) as any;

  const useUpdateContext = () => {
    return React.useContext(Context).setValue;
  };

  const ContextComponent: React.FC<{ value: T; children?: any }> = ({
    ...props
  }) => {
    const [value, setValue] = useState(props.value);
    useEffect(() => {
      setValue(props.value);
    }, [props.value]);
    return (
      <Context.Provider value={{ value, setValue }}>
        {props.children}
      </Context.Provider>
    );
  };

  return [
    useContext,
    ContextComponent,
    useUpdateContext,
    () => {
      return React.useContext(Context).value;
    },
  ];
};

export default createContext;

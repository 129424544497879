import React, { useContext, useState } from 'react';
import { Color, Font, Size, Style } from './define';
import { Button, ColorPicker } from 'antd';
import classNames from 'classnames';
import { ThemeHOC, useThemePrimaryColor } from './react';
import { NeutralHelperColor } from './define/color';

const { BrandColor, FunctionalColor, NeutralColor, FunctionalHelperColor } =
  Color;

const ThemeContext = React.createContext<{ mode: 'js' | 'uno' }>({
  mode: 'js',
});

const useTheme = () => {
  return useContext(ThemeContext).mode;
};
const mUno = (mode: string, value: any) => {
  if (mode === 'uno') return value;
  return typeof value === 'string' ? '' : {};
};
const mJs = (mode: string, value: any) => {
  if (mode === 'js') return value;
  return typeof value === 'string' ? '' : {};
};

const useThemeMatch = () => {
  const mode = useTheme();
  return {
    Uno(value: any) {
      return mUno(mode, value);
    },
    Js(value: any) {
      return mJs(mode, value);
    },
  };
};

const Cls: React.FC<any> = (props: any) => {
  const mode = useTheme();
  if (mode === 'js') return <></>;
  return <div className="bg-gray">{props.children}</div>;
};

const ColorList: React.FC<{ colors: any; name: string }> = ({
  colors,
  name,
}) => {
  const keys = Object.keys(colors);
  const mode = useTheme();
  return (
    <div className="flex-box">
      <div className="color-black font-500 rounded-lg shadow-2xl flex-col m-2 bg-blue size-20 flex-box">
        {name}
      </div>
      {keys.map((key, index) => {
        return (
          <div
            key={key}
            className={`
              size-30 flex-box rounded-lg shadow-2xl flex-col m-2
              ${index > keys.length / 2 ? 'color-white' : 'color-black'}
              ${mUno(mode, 'bg-g-' + key)}
            `}
            style={mJs(mode, { backgroundColor: colors[key] })}
          >
            <Cls>{'.bg-g-' + key}</Cls>
            <div>{key}</div>
            <div>{colors[key]}</div>
          </div>
        );
      })}
    </div>
  );
};

// ' bg-g-brand-1 bg-g-brand-2 bg-g-brand-3 bg-g-brand-4 bg-g-brand-5 bg-g-brand-6 bg-g-brand-7 bg-g-brand-8 bg-g-brand-9 bg-g-brand-10
// bg-g-grey-1 bg-g-grey-2 bg-g-grey-3 bg-g-grey-4 bg-g-grey-6 bg-g-grey-8 bg-g-grey-10
// bg-g-grey-1-6 bg-g-grey-2-6 bg-g-grey-3-6 bg-g-grey-4-6 bg-g-grey-6-6 bg-g-grey-8-6 bg-g-grey-10-6
//bg-g-error bg-g-warn bg-g-success bg-g-link bg-g-yellow bg-g-cyan bg-g-purple
// bg-g-error-6 bg-g-warn-6 bg-g-success-6 bg-g-link-6 bg-g-yellow-6 bg-g-cyan-6 bg-g-purple-6 bg-g-primary';

const ColorDoc: React.FC<{ test?: string }> = (props) => {
  return (
    <div className={'border-3 border-solid m-10 ' + props.test}>
      <ColorList key={1} name="品牌色" colors={BrandColor}></ColorList>
      <ColorList key={2} name="中性色" colors={NeutralColor}></ColorList>
      <ColorList
        key={4}
        name="中性色-遮罩"
        colors={NeutralHelperColor}
      ></ColorList>
      <ColorList key={3} name="功能色" colors={FunctionalColor}></ColorList>
      <ColorList
        key={4}
        name="功能色-遮罩"
        colors={FunctionalHelperColor}
      ></ColorList>
    </div>
  );
};
const toLine = (str: string) => {
  return str.replace(/\B([A-Z])/g, '-$1').toLowerCase();
};

// 'text-g-normal text-g-normal-bold text-g-small text-g-small-bold text-g-large text-g-large-bold'
// 'title-g-normal title-g-small title-g-large'
// 'topic-g-normal topic-g-small topic-g-large'
const FontList: React.FC<{ fonts: any; name: string; prefix: string }> = ({
  fonts,
  name,
  prefix,
}) => {
  const keys = Object.keys(fonts);
  const mode = useTheme();
  return (
    <div className="flex-box flex-wrap">
      <div className="color-black font-500 rounded-lg shadow-2xl flex-col m-2 bg-blue size-20 flex-box">
        {name}
      </div>
      {keys.map((key) => {
        return (
          <span
            key={key}
            className={classNames(
              'rounded-lg shadow-2xl flex-col m-2 bg-#00000020',
              mUno(mode, prefix + '-g-' + toLine(key))
            )}
            style={mJs(mode, {
              ...fonts[key],
              lineHeight: fonts[key].lineHeight + 'px',
            })}
          >
            <Cls>{'.' + prefix + '-g-' + toLine(key)}</Cls>
            <br />
            <span>
              {key}:{JSON.stringify(fonts[key])}
            </span>
          </span>
        );
      })}
    </div>
  );
};

const FontDoc: React.FC = () => {
  return (
    <div className="border-3 border-solid m-10">
      <FontList
        key="text"
        prefix="text"
        fonts={Font.TextFont}
        name="文本字体"
      ></FontList>
      <FontList
        key="title"
        prefix="title"
        fonts={Font.TitleFont}
        name="标题字体"
      ></FontList>
      <FontList
        key={'topic'}
        prefix="topic"
        fonts={Font.TopicFont}
        name="运营字体"
      ></FontList>
    </div>
  );
};

// 'size-g-1 size-g-2 size-g-3 size-g-4 size-g-5 size-g-6 size-g-7 size-g-8 size-g-9 size-g-10'
// 'size-g-mini size-g-small size-g-default size-g-large'
// 'm-g-s m-g-m m-g-l m-g-xl m-g-xxl m-g-xxxl'
// 'p-g-s p-g-m p-g-l p-g-xl p-g-xxl p-g-xxxl'
// 'gap-g-s gap-g-m gap-g-l gap-g-xl gap-g-xxl gap-g-xxxl'
const SizeList: React.FC<{
  size: any;
  name: string;
  isMargin?: boolean;
  isPadding?: boolean;
}> = ({ size, name, isMargin = false, isPadding = false }) => {
  const keys = Object.keys(size);
  const { Js, Uno } = useThemeMatch();
  return (
    <div className="flex-box flex-wrap">
      <div className="color-black font-500 rounded-lg shadow-2xl flex-col m-2 bg-blue size-20 flex-box">
        {name}
      </div>
      {keys.map((key) => {
        const s = (v: number) => Math.min(200, v);
        const cls = isMargin
          ? Uno('m-g-' + key.replace('size-', ''))
          : isPadding
          ? Uno('p-g-' + key.replace('size-', ''))
          : Uno('size-g-' + key.replace('size-', ''));
        return (
          <div key={key} className="m-2">
            <span>
              <Cls> {'.' + cls}</Cls>
              {key}:{size[key] + 'px'}
              <div
                className={classNames({
                  'border border-solid': isMargin,
                  'bg-#C9CDD4': isPadding,
                  [cls]: isPadding,
                })}
                style={isPadding ? Js({ padding: s(size[key]) }) : {}}
              >
                <div
                  className={classNames('flex-col bg-#00000020', cls, {
                    'size-100px': isMargin || isPadding,
                  })}
                  style={
                    isMargin
                      ? Js({
                          width: 100,
                          height: 100,
                          margin: s(size[key]),
                        })
                      : isPadding
                      ? Js({ minWith: 20 })
                      : Js({ width: s(size[key]), height: s(size[key]) })
                  }
                ></div>
              </div>
            </span>
          </div>
        );
      })}
    </div>
  );
};

const GapList: React.FC<{}> = () => {
  const { Js, Uno } = useThemeMatch();

  return (
    <div className="flex-box flex-wrap items-start">
      <div className="color-black font-500 rounded-lg shadow-2xl flex-col m-2 bg-blue size-20 flex-box">
        间距
      </div>
      <div>
        {Object.keys(Size.MarginSize).map((key: any) => {
          const gap = Size.MarginSize[key as keyof typeof Size.MarginSize];
          return (
            <div>
              <Cls>{'.gap-g-' + key}</Cls>
              <span>gap-{gap}px</span>
              <div
                className={classNames(
                  'flex p-g-xl border-g-solid-stroke',
                  Uno('gap-g-' + key)
                )}
                style={Js({
                  gap: gap,
                })}
              >
                {Array.from({ length: 6 }).map((_, index) => {
                  return <div className="size-100px bg-#00000020"></div>;
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SizeDoc: React.FC = () => {
  return (
    <div className="m-10 border-solid border-3">
      <SizeList key={'base'} size={Size.BaseSize} name="基础尺寸"></SizeList>
      <SizeList
        key="component"
        size={Size.ComponentSize}
        name="组件尺寸"
      ></SizeList>
      <SizeList
        key="margin"
        size={Size.MarginSize}
        name="间距尺寸"
        isMargin={true}
      ></SizeList>
      <SizeList
        key="padding"
        size={Size.MarginSize}
        name="内边距"
        isPadding={true}
      ></SizeList>
      <GapList></GapList>
      {/* <SizeList size={Size.ResponsiveScreenSize} name="断点尺寸"></SizeList> */}
    </div>
  );
};

// 'rd-g-normal rd-g-small rd-g-large'
// 'border-g-solid-stroke border-g-solid-normal border-g-solid-bold border-g-dashed-stroke border-g-dashed-normal border-g-dashed-bold'
// 'shadow-g-level1 shadow-g-level2 shadow-g-level3'
const StyleList: React.FC<{
  style: any;
  name: string;
  prefix: string;
  className?: string;
}> = ({ style, name, prefix, className = '' }) => {
  const keys = Object.keys(style);
  const { Js, Uno } = useThemeMatch();
  return (
    <div className={`flex-box flex-wrap color-grey-2 color-grey-2`}>
      <div className="color-black font-500 rounded-lg shadow-2xl flex-col m-2 bg-blue size-20 flex-box">
        {name}
      </div>
      {keys.map((key) => {
        const cls = prefix + 'g-' + toLine(key);
        return (
          <div
            key={key}
            className={classNames('m-2 w-100 h-30 p-5 ', Uno(cls), className)}
            style={Js(style[key])}
          >
            <Cls> {'.' + cls}</Cls>
            {key}
            <div>{JSON.stringify(style[key], null, 2)}</div>
          </div>
        );
      })}
    </div>
  );
};

const StyleDoc: React.FC = () => {
  const boxShadowToCss = () => {
    return Object.keys(Style.Shadow).reduce<any>((map, key) => {
      const shadow: typeof Style.Shadow.Level1 = (Style.Shadow as any)[key];
      map[key] = {
        boxShadow: `${shadow.offsetX}px ${shadow.offsetY}px ${shadow.blurRadius}px ${shadow.spreadRadius}px ${shadow.color}`,
        border: 'none',
      };
      return map;
    }, {});
  };

  return (
    <div className="m-10 border-solid border-3">
      <StyleList
        prefix="rd-"
        name="圆角样式"
        key="rd"
        style={Style.BorderRadius}
        className="border-1 border-solid "
      ></StyleList>
      <StyleList
        key="border"
        prefix="border-"
        name="边框样式"
        style={Style.Border}
      ></StyleList>
      <StyleList
        key="shadow"
        prefix="shadow-"
        name="阴影样式"
        style={boxShadowToCss()}
      ></StyleList>
    </div>
  );
};

const Mode: React.FC<{
  change(mode: 'js' | 'uno'): void;
  mode: 'js' | 'uno';
}> = ({ change, mode }) => {
  const { set } = useThemePrimaryColor();
  return (
    <div className="fixed flex-col flex bg-g-cyan-6 p-g-2 rd-g-normal">
      <div className="b-g-purple-6 rd-g-small p-g-1 bg-g-purple-6 ">
        <Button
          key="xx"
          type={mode === 'uno' ? 'primary' : 'default'}
          onClick={change.bind(null, 'uno')}
        >
          unocss
        </Button>
        <Button
          key={'yy'}
          className="m-l-2"
          type={mode === 'js' ? 'primary' : 'default'}
          onClick={change.bind(null, 'js')}
        >
          js
        </Button>
      </div>
      <ColorPicker
        onChange={(color) => {
          set(color.toRgbString());
        }}
      >
        <Button type="primary" className="m-t-1">
          修改主题色
        </Button>
      </ColorPicker>
    </div>
  );
};

const Theme = ThemeHOC(() => {
  const [mode, setMode] = useState<'js' | 'uno'>('js');
  return (
    <ThemeContext.Provider value={{ mode: mode }}>
      <div>
        {/* <div className="p-lr-g-s">test</div> */}
        <Mode mode={mode} change={setMode}></Mode>
        <ColorDoc></ColorDoc>
        <FontDoc></FontDoc>
        <SizeDoc></SizeDoc>
        <StyleDoc></StyleDoc>
      </div>
    </ThemeContext.Provider>
  );
});

export { Theme };

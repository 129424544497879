import { FieldType } from '@gaker/models';
import { ValueBaseStore } from './baseStore';
import { Models } from '@declare-models';

export class FieldDetailStore extends ValueBaseStore {
  data: Record<
    string,
    { fieldDetail: Models.FieldDetail.TFieldDetail['detail'] }
  > = {};
  objectApiName: string;
  constructor(objectApiName: string, data: any) {
    super();
    this.objectApiName = objectApiName;
    this.data = data;
  }
  get<Detail extends Models.FieldDetail.TFieldDetail['detail']>(field: {
    valueType?: FieldType;
    fieldApiName: string;
  }): Detail {
    if (!this.data[field.fieldApiName]) return {} as any;
    const detail = this.data[field.fieldApiName].fieldDetail;

    if (!detail) return {} as any;
    if (
      field.valueType &&
      [(FieldType.NUMBER, FieldType.MONEY)].includes(field.valueType)
    ) {
      return {
        ...detail,
        displayThousandth:
          (detail as any).displayFormat === 'THOUSAND_SEPARATE',
      } as any as Detail;
    }
    return detail as any;
  }
}

export const HOC = <HOCProps extends {}>(
  HOCComponent: React.FC<HOCProps>,
  hocProps?: HOCProps
) => {
  return <Props extends {}>(Component: React.FC<Props>) => {
    return (props: Props) => (
      <HOCComponent {...(hocProps || ({} as any))}>
        <Component {...props}></Component>
      </HOCComponent>
    );
  };
};

export default HOC;

/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-09-18 09:25:40
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2024-01-03 10:29:24
 * @FilePath: /gaker/libs/theme/src/define/font.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const EnFont = 'Inter';
export const NumberFont = 'Inter';

const font = (size: number, lineHeight: number) => {
  return { fontSize: size, lineHeight, fontWeight: 'initial' };
};

const bold = (_font: ReturnType<typeof font>) => {
  return { ..._font, fontWeight: '500' };
};

export const TextFont = {
  Normal: font(14, 22),
  NormalBold: bold(font(14, 22)),
  Small: font(13, 20),
  SmallBold: bold(font(13, 20)),
  Middle: font(16, 22),
  MiddleBold: bold(font(16, 22)),
  Large: font(20, 28),
  LargeBold: bold(font(20, 28)),
  Helper: font(12, 20),
};

export const TitleFont = {
  Normal: font(16, 24),
  Small: font(16, 24),
  Middle: font(20, 28),
  Large: font(24, 32),
};

export const TopicFont = {
  Normal: font(36, 44),
  Small: font(36, 44),
  Middle: font(48, 56),
  Large: font(56, 62),
};

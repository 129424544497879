import { useEffect, useRef } from 'react';
import {
  ConnectDragPreview,
  ConnectDragSource,
  DragSourceHookSpec,
  useDrag as useDndDrag,
} from 'react-dnd';
import { DndEmitKesEnum, dndEmitter } from '../useDndEvents';

const addDragStartEventListener = (
  el?: HTMLElement,
  handler?: (evt: DragEvent) => void
) => {
  if (!el || !handler) return () => {};
  el.addEventListener('dragstart', handler);
  return () => el.removeEventListener('dragstart', handler);
};

export interface IDragEvent {
  onStart?: (evt: DragEvent) => void;
}

export const useDrag = <
  DragObject = unknown,
  DropResult = unknown,
  CollectedProps = unknown
>(
  specArg: () => DragSourceHookSpec<DragObject, DropResult, CollectedProps>,
  deps?: unknown[]
): [CollectedProps, ConnectDragSource, ConnectDragPreview] => {
  const elRef = useRef<HTMLElement>();
  const [collect, dragView, dragPreview] = useDndDrag<
    DragObject,
    DropResult,
    any
  >(() => {
    const options = specArg(); // todo 是否有副作用
    return {
      ...options,
      end(draggedItem: any, monitor) {
        options.end?.(draggedItem, monitor);
        dndEmitter.emit(DndEmitKesEnum.DragEnd);
      },
      collect(monitor) {
        const data = options.collect?.(monitor) || {};
        monitor.getHandlerId();
        return {
          ...data,
          __item__: monitor.getItem(),
        };
      },
    };
  }, deps);

  useEffect(() => {
    if (!elRef.current) return;
    return addDragStartEventListener(elRef.current, (evt) => {
      setTimeout(() => {
        dndEmitter.emit(DndEmitKesEnum.DragStart, evt);
      }, 20);
    });
  }, [collect]);

  const ref: ConnectDragSource = (el: any) => {
    if (!el) return dragView(el);
    if (el.addEventListener) {
      elRef.current = el;
    }

    return dragView(el);
  };

  return [collect, ref, dragPreview];
};

export default useDrag;

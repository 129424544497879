import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { LazyComponent } from '@gaker/components';
import { useRouterRedirect } from '@dxp-hooks/useRouterRedirect';
import { HOC } from '@gaker/tools';
import RunnerPage, { NotAuthRunnerPage } from '../layout/RunnerPage';
import SystemPage from '../layout/SystemPage';
import FormPage from '../layout/FormPage';
import DetailPage from '../layout/DetailPage';
import ListPage from '../layout/ListPage';
import { useLocation, useOutlet } from 'react-router-dom';
import Home from '../app/home';
import Run from '../app/detail';
import { useLoading } from '@dxp-hooks/useLoading';
import KeepAlive from '@dxp-components/KeepAlive';

const createElement = (promise: () => Promise<any>, Layout?: React.FC) => {
  if (Layout) return React.createElement(HOC(Layout)(LazyComponent(promise)));
  return React.createElement(LazyComponent(promise));
};

const RedirectToLogin: React.FC = () => {
  const redirect = useRouterRedirect();
  useEffect(() => {
    redirect({ to: 'home' });
  }, []);
  return null;
};
const domainPath = '/:domain';
export const runnerRoutes = [
  {
    path: '/',
    Component: React.memo(KeepAlive),
    children: [
      {
        path: '',
        name: 'home',
        isNotAuth: false,
        element: React.createElement(HOC(RunnerPage)(RedirectToLogin)),
      },
      {
        path: '/login',
        name: 'login',
        isNotAuth: true,
        element: createElement(() => import('../app/login'), NotAuthRunnerPage),
      },
      {
        path: '/reset',
        name: 'reset',
        isNotAuth: true,
        element: createElement(() => import('../app/reset'), NotAuthRunnerPage),
      },
      {
        path: '/invite/:inviteId',
        name: 'invite',
        isNotAuth: true,
        element: createElement(
          () => import('../app/invite'),
          NotAuthRunnerPage
        ),
      },
      {
        path: '/user',
        name: 'user',
        element: createElement(() => import('../app/userlist'), ListPage),
      },
      // {
      //   // 未使用
      //   path: '/product',
      //   name: 'product',
      //   element: createElement(() => import('../app/product')),
      // },
      {
        path: '/:objectApiName/:dataId', // view | create -> preview
        name: 'portal',
        Component: HOC(RunnerPage)(Home),
        // element: React.createElement(HOC(RunnerPage)(Home)), //createElement(() => import('../app/home'), RunnerPage),
      },
      {
        path: '/detail/:objectApiName/:dataId', // view | create -> preview
        name: 'detail',
        Component: HOC(DetailPage)(Run), // createElement(() => import('../app/detail'), DetailPage),
      },
      {
        path: '/list/:objectApiName', // 首页 移动端使用的列表组件
        name: 'list',
        element: createElement(() => import('../app/list'), ListPage),
      },
      {
        path: '/create/:objectApiName',
        name: 'create',
        element: createElement(() => import('../app/form'), FormPage),
      },
      {
        path: '/edit/:objectApiName/:dataId',
        name: 'edit',
        element: createElement(() => import('../app/edit'), FormPage),
      },
      {
        path: '/result', // view | create -> preview
        name: 'result',
        element: createElement(() => import('../app/result'), RunnerPage),
      },
    ].map((r) => ({ ...r, path: domainPath + r.path })),
  },
];

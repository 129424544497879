const topWindow = (() => {
  let parent = window.parent || window;
  while (parent.parent && parent !== parent.parent) {
    parent = parent.parent;
  }
  return parent;
})();

type DataId = string;
const map: Record<DataId, Window> =
  (topWindow as any).__dxp_deps_window ||
  ((topWindow as any).__dxp_deps_window = {});

export const setDepWindow = (dataId: DataId) => {
  map[dataId] = window;
};

export const getSourceWindow = (dataId: DataId) => {
  return map[dataId] || window.parent || window;
};

export const removeDepWindow = (dataId: DataId) => {
  delete map[dataId];
};

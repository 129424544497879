import emitter from '@dxp-utils/emitter';
import listener from '@dxp-utils/listener';

type Params = Parameters<typeof emitter.useEmitter>;

export const useEmitterToListener = (name: Params[0], deps: Params[2]) => {
  emitter.useEmitter(
    name,
    (data) => {
      return listener.postMessage(name, data);
    },
    deps
  );
};

export default useEmitterToListener;

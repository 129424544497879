import { IDxpEditor } from '@dxp-declare';
import { Color } from '@gaker/theme';
import { createContext } from '@gaker/tools';

export const [useWebsite, WebSiteContext] = createContext(
  {
    id: '',
    logo: '',
    siteName: '',
    siteDesc: '',
    brandColor: Color.PrimaryColor,
  } as IDxpEditor.Models.WebSite,
  (u) => u
);

export default useWebsite;

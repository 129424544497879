/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/prefer-namespace-keyword */

import FieldType from './FieldType';

export module Models {
  export module Define {
    export enum AddressFormat {
      /**国家 */
      COUNTRY = 'COUNTRY',
      /**国家/省 */
      COUNTRY_PROVINCE = 'COUNTRY_PROVINCE',
      /**国家/省/市 */
      COUNTRY_PROVINCE_CITY = 'COUNTRY_PROVINCE_CITY',
      /**国家/省/市/区(县) */
      COUNTRY_PROVINCE_CITY_DISTRICT = 'COUNTRY_PROVINCE_CITY_DISTRICT',
    }
    export enum LocateLimitType {
      /**无限制 */
      NO_LIMIT = 'NO_LIMIT',
      /**当前定位点限制 */
      CUR_LIMIT = 'CUR_LIMIT',
      /**条件定位点限制 */
      CON_LIMIT = 'CON_LIMIT',
    }
    export enum MoneyUnitEnum {
      CNY = 'CNY', // 人民币
      SGD = 'SGD', // 新币
      EUR = 'EUR', // 欧元
      USD = 'USD', // 美元
      JPY = 'JPY', // 日元
      HKD = 'HKD', // 港币
      AUD = 'AUD', // 澳元
    }
    export enum MonetaryUnitSymbol {
      CNY = '¥', // 人民币 CNY
      SGD = '$', // 新币 S
      EUR = '€', // 欧元
      USD = '$', // 美元
      JPY = '¥', // 日元
      HKD = '$', // 港币 HK
      AUD = '$', // 澳元 A
    }
    export enum FileType {
      jpeg = 'jpeg',
      png = 'png',
      docx = 'docx',
      doc = 'doc',
      pdf = 'pdf',
      xls = 'xls',
      xlsx = 'xlsx',
    }
    export enum TerminalType {
      ALL = 'ALL',
      /** 移动端 */
      MOBILE = 'MOBILE',
    }

    /**
     * 上传类型
     */
    export enum UploadType {
      ALL = 'ALL',
      /** 文件选择 */
      STORAGE = 'STORAGE',
      /** 相机 */
      CAMERA = 'CAMERA',
    }

    export enum ExpressType {
      //数值类型
      EXPRESS_NUMBER = 'EXPRESS_NUMBER',
      //日期类型,包含(返回时长)
      EXPRESS_DATE = 'EXPRESS_DATE',
      //文本
      EXPRESS_TEXT = 'EXPRESS_TEXT',
      //汇总
      EXPRESS_SUMMARY = 'EXPRESS_SUMMARY',
    }

    export enum ApprovalStateEnum {
      Common = 'common',
      InApproval = 'inApproval',
      Approval = 'approval',
      Cancel = 'approval_cancel',
      Reject = 'approval_reject',
    }

    export enum ConditionLogicEnum {
      AND = 'AND',
      OR = 'OR',
    }

    export enum ItemQueryTypeEnum {
      EQ,
      NEQ,
      GT,
      GTE,
      LT,
      LTE,
      CONTAIN,
      NOT_CONTAIN,
      INTERSECT,
      NOT_INTERSECT,
      REGEX,
      NOT_REGEX,
      START_WITH,
      END_WITH,
      EMPTY,
      NONE_EMPTY,
    }

    export enum ItemQueryTypeLabelEnum {
      EQ = '等于',
      NEQ = '不等于',
      GT = '大于',
      GTE = '大于等于',
      LT = '小于',
      LTE = '小于等于',
      CONTAIN = '属于',
      NOT_CONTAIN = '不属于',
      INTERSECT = '任意等于',
      NOT_INTERSECT = '全部不等于',
      REGEX = '包含',
      NOT_REGEX = '不包含',
      START_WITH = '开始于',
      END_WITH = '结束于',
      EMPTY = '为空',
      NONE_EMPTY = '不为空',
    }
  }
  export module FieldValue {
    interface StringValue {
      stringValue: string;
    }

    interface FileValue {
      fileName: string;
      upLoadTime: number;
      fileSize: number;
      fileUrl: string;
    }
    export interface IBaseFileValue extends FileValue {}

    interface UserValue {
      accountStatus: string;
      photoUrl: string;
      userSystemIds: string[];
      userName: string;
      userId: string;
    }

    interface NumberValue {
      doubleValue: string;
      numberValue: number;
    }

    interface IFieldValue<Type extends FieldType, Value> {
      valueType: Type;
      value: Value;
    }

    export type TTextMultiValue = IFieldValue<
      FieldType.TEXT_MULTI,
      StringValue
    >;
    export type TTextSingleValue = IFieldValue<
      FieldType.TEXT_SINGLE,
      StringValue
    >;

    export type TEmailValue = IFieldValue<FieldType.EMAIL, StringValue>;

    export type TNumberAutoValue = IFieldValue<
      FieldType.NUMBER_AUTO,
      StringValue
    >;
    export type TRichText = IFieldValue<FieldType.RICH_TEXT, StringValue>;
    export type TWebSiteValue = IFieldValue<FieldType.WEBSITE, StringValue>;

    export type TNumberValue = IFieldValue<FieldType.NUMBER, NumberValue>;

    export type TPercentValue = IFieldValue<FieldType.PERCENT, NumberValue>;
    export type TMoneyValue = IFieldValue<FieldType.MONEY, NumberValue>;

    export type TAddressValue = IFieldValue<
      FieldType.ADDRESS,
      {
        addressCodes: string[];
        codeNameMap: Record<string, string>;
        detail: string;
      }
    >;

    export type TPictureValue = IFieldValue<
      FieldType.PICTURE,
      {
        fileInfoList: FileValue[];
      }
    >;

    export type TUserValue = IFieldValue<
      FieldType.USER,
      {
        userInfo: UserValue;
        stringValue: string;
      }
    >;
    export type TUserListValue = IFieldValue<
      FieldType.USER_LIST,
      {
        userInfoList: UserValue[];
        stringValueList: string[];
      }
    >;

    export type TDateValue = IFieldValue<FieldType.DATE, { timeValue: number }>;
    export type TDateTimeValue = IFieldValue<
      FieldType.DATE_TIME,
      { timeValue: number }
    >;
    export type TSelectSingleValue = IFieldValue<
      FieldType.SELECT_SINGLE,
      {
        optionKey: string;
        optionValue: string;
      }
    >;
    export type TRadioValue = IFieldValue<
      FieldType.RADIO,
      {
        optionKey: string;
        optionValue: string;
      }
    >;
    export type TSelectMultiValue = IFieldValue<
      FieldType.SELECT_MULTI,
      {
        optionKeyList: string[];
        optionValueList: string[];
      }
    >;

    export type TCheckboxValue = IFieldValue<
      FieldType.CHECKBOX,
      {
        optionKeyList: string[];
        optionValueList: string[];
      }
    >;

    export type TFileValue = IFieldValue<
      FieldType.FILE,
      {
        fileInfoList: FileValue[];
      }
    >;

    export type TTelePhoneValue = IFieldValue<
      FieldType.TELEPHONE,
      { countryCode: string; telephone: string }
    >;

    interface IBaseAssociateValue {
      associateObjectApiName: string; // 关联对象objectApiName
      associateObjectName: string;
      associateTitleFieldValue: string; // 关联对象显示的标题字段值
      associateDataId: string; // 关联的dataId
      layoutId?: string;
      associateMainFieldName?: string;
      associateMainFieldValue: string; // 关联对象的主字段值
    }

    export type TObjectAssociateValue = IFieldValue<
      FieldType.OBJECT_ASSOCIATE,
      IBaseAssociateValue
    >;

    export type TFatherChildValue = IFieldValue<
      FieldType.PARENT_SON,
      IBaseAssociateValue
    >;

    export type TStateFlowStateValue = IFieldValue<
      FieldType.SELECT_SINGLE,
      {
        optionValue: string;
        optionKey: string;
      }
    >;
    export type TLocalValue = IFieldValue<
      FieldType.LOCATION,
      {
        location: {
          addressName: string;
          coordinate: [string, string];
        };
      }
    >;

    export type TPoolValue = IFieldValue<
      FieldType.POOL,
      {
        poolId: string;
        poolName: string;
      }
    >;

    export type TLayoutValue = IFieldValue<
      FieldType.LAYOUT,
      {
        layoutId: string;
        layoutName: string;
      }
    >;

    export type TFieldValue =
      | TTextMultiValue
      | TTextSingleValue
      | TEmailValue
      | TNumberAutoValue
      | TRichText
      | TWebSiteValue
      | TNumberValue
      | TPercentValue
      | TMoneyValue
      | TAddressValue
      | TPictureValue
      | TUserValue
      | TUserListValue
      | TDateValue
      | TDateTimeValue
      | TSelectSingleValue
      | TRadioValue
      | TSelectMultiValue
      | TCheckboxValue
      | TFileValue
      | TTelePhoneValue
      | TObjectAssociateValue
      | TFatherChildValue
      | TLocalValue
      | TPoolValue
      | TLayoutValue;
  }
  export module FieldDetail {
    export interface IMoneyDetail extends INumberDetail {
      unitType: Define.MoneyUnitEnum;
      displayUnit: boolean; // 是否数据显示单位
    }
    export interface INumberDetail {
      decimalLen: number; // 小数位数
      displayThousandth?: boolean; // 是否显示千分位
      displayFormat: 'THOUSAND_SEPARATE';
    }
    export interface IPercentDetail {
      decimalLen: number; // 小数位数
    }
    export interface ICalculateDetail {
      valueType: FieldType;
    }
    export interface ISummaryDetail extends ICalculateDetail {
      summaryObject: string;
      summaryField: string;
    }
    export interface IRefDetail extends ICalculateDetail {}

    interface IOptionListDetail {
      optionList: {
        optionValue: string;
        optionKey: string;
        colors: string[];
      }[];
    }

    export interface IRadioDetail extends IOptionListDetail {}
    export interface ICheckBoxDetail extends IOptionListDetail {}
    export interface ISelectSingleDetail extends IOptionListDetail {}
    export interface ISelectMultiDetail extends IOptionListDetail {}

    export interface IAddressDetail {
      withDetail: boolean;
      countryCode: string /**国家码,0代表全球 */;
      format: Define.AddressFormat;
    }
    export interface ILocationDetail {
      /**定位限制类型 */
      limitType: Define.LocateLimitType;
      /**范围(单位:米) */
      radius: number;
      /**定位可用场景,允许非移动端使用 */
      allowNonMobile: boolean;
    }

    export interface IFileDetail {
      maxNum: number;
    }

    interface IBaseAssociateDetail {
      associateObjectApiName: string;
      associateObjectName: string;
      associateTitleFieldApiName: string; // 关联数据显示的字段
      tabName: string;

      isFVSlaveCreate?: boolean; //是否显示新建从对象入口
      isFVSlaveRequired?: boolean; //从对象数据是否必填
      //关联对象过滤规则
      objectFilter?: any; //todo
    }

    export type IObjectAssociateDetail = IBaseAssociateDetail;

    export type IFatherChildDetail = IBaseAssociateDetail;

    export type ISlaveObjectDetail = IBaseAssociateDetail;

    export interface ISubTableDetail {
      subTableId: string;
      subFieldDTOS: any[]; //@mark 未使用 todo
    }

    export interface IPictureDetail extends IFileDetail {
      terminalType: Define.TerminalType;
      uploadType: Define.UploadType;
    }

    export interface IRefDetail {
      refObjectApiName: string;
      refTenantFieldDTO: Field.TField;
    }

    interface BaseExpressDetail {
      valueType: FieldType;
      valueDetail?: FieldDetail.TFieldDetail;
    }

    export interface ICalculateDetail extends BaseExpressDetail {
      expressType: Define.ExpressType;
    }

    export interface ISummaryDetail extends BaseExpressDetail {
      summaryObject: string;
      summaryField: string;
    }

    interface IFieldDetail<Type extends FieldType, Detail> {
      valueType: Type;
      detail: Detail;
    }

    export type TFieldDetail =
      | IFieldDetail<FieldType.MONEY, IMoneyDetail>
      | IFieldDetail<FieldType.NUMBER, INumberDetail>
      | IFieldDetail<FieldType.PERCENT, IPercentDetail>
      | IFieldDetail<FieldType.RADIO, IRadioDetail>
      | IFieldDetail<FieldType.CHECKBOX, ICheckBoxDetail>
      | IFieldDetail<FieldType.SELECT_SINGLE, ISelectSingleDetail>
      | IFieldDetail<FieldType.SELECT_MULTI, ISelectMultiDetail>
      | IFieldDetail<FieldType.ADDRESS, IAddressDetail>
      | IFieldDetail<FieldType.LOCATION, ILocationDetail>
      | IFieldDetail<FieldType.FILE, IFileDetail>
      | IFieldDetail<FieldType.PICTURE, IPictureDetail>
      | IFieldDetail<FieldType.OBJECT_ASSOCIATE, IObjectAssociateDetail>
      | IFieldDetail<FieldType.SLAVE_ASSOCIATE, ISlaveObjectDetail>
      | IFieldDetail<FieldType.REF, IRefDetail>
      | IFieldDetail<FieldType.SUMMARY, ISummaryDetail>
      | IFieldDetail<FieldType.CALCULATE, ICalculateDetail>
      | IFieldDetail<FieldType.SUB_TABLE, ISubTableDetail>;

    export type TFieldDetailMap = {
      [FieldType.MONEY]: IMoneyDetail;
      [FieldType.NUMBER]: INumberDetail;
      [FieldType.PERCENT]: IPercentDetail;
      [FieldType.RADIO]: IRadioDetail;
      [FieldType.CHECKBOX]: ICheckBoxDetail;
      [FieldType.SELECT_SINGLE]: ISelectSingleDetail;
      [FieldType.SELECT_MULTI]: ISelectMultiDetail;
      [FieldType.ADDRESS]: IAddressDetail;
      [FieldType.LOCATION]: ILocationDetail;
      [FieldType.FILE]: IFileDetail;
      [FieldType.PICTURE]: IPictureDetail;
      [FieldType.OBJECT_ASSOCIATE]: IObjectAssociateDetail;
      [FieldType.SLAVE_ASSOCIATE]: ISlaveObjectDetail;
      [FieldType.REF]: IRefDetail;
      [FieldType.SUMMARY]: ISummaryDetail;
      [FieldType.CALCULATE]: ICalculateDetail;
      [FieldType.SUB_TABLE]: ISubTableDetail;
    };
  }

  export module Field {
    interface IField<
      FType extends FieldType,
      Detail = any,
      ValueType extends FieldType = FType
    > {
      fieldApiName: string;
      fieldName: string;
      fieldType: FType;
      valueType: ValueType;
      fieldDetail: Detail;
      objectApiName?: string;
    }

    export type TTextSingleField = IField<FieldType.TEXT_SINGLE>;
    export type TWebsiteField = IField<FieldType.WEBSITE>;
    export type TTextMultiField = IField<FieldType.TEXT_MULTI>;
    export type TNumberField = IField<
      FieldType.NUMBER,
      FieldDetail.INumberDetail
    >;
    export type TMoneyField = IField<FieldType.MONEY, FieldDetail.IMoneyDetail>;

    export type TPercentField = IField<
      FieldType.PERCENT,
      FieldDetail.IPercentDetail
    >;

    export type TTelePhoneField = IField<
      FieldType.TELEPHONE,
      FieldDetail.IPercentDetail
    >;

    export type TRadioField = IField<FieldType.RADIO, FieldDetail.IRadioDetail>;
    export type TSelectMultiField = IField<
      FieldType.SELECT_MULTI,
      FieldDetail.ISelectMultiDetail
    >;
    export type TSelectSingleField = IField<
      FieldType.SELECT_SINGLE,
      FieldDetail.ISelectSingleDetail
    >;
    export type TCheckboxField = IField<
      FieldType.CHECKBOX,
      FieldDetail.IRadioDetail
    >;

    export type TDateField = IField<FieldType.DATE>;
    export type TDateTimeField = IField<FieldType.DATE_TIME>;

    export type TUserField = IField<FieldType.USER>;
    export type TUserListField = IField<FieldType.USER_LIST>;
    export type TAddressField = IField<
      FieldType.ADDRESS,
      FieldDetail.IAddressDetail
    >;

    export type TLocationField = IField<
      FieldType.LOCATION,
      FieldDetail.ILocationDetail
    >;

    export type TRichTextField = IField<FieldType.RICH_TEXT>;

    export type TFileField = IField<FieldType.FILE, FieldDetail.IFileDetail>;

    export type TObjectAssociateField = IField<
      FieldType.OBJECT_ASSOCIATE,
      FieldDetail.IObjectAssociateDetail
    >;

    export type TSlaveObjectField = IField<
      FieldType.SLAVE_ASSOCIATE,
      FieldDetail.ISlaveObjectDetail
    >;

    export type TFatherChildField = IField<
      FieldType.PARENT_SON,
      FieldDetail.IFatherChildDetail
    >;

    export type TPictureField = IField<
      FieldType.PICTURE,
      FieldDetail.IPictureDetail
    >;

    export type TRefField = IField<FieldType.REF, FieldDetail.IRefDetail>;
    export type TCalculateField = IField<
      FieldType.CALCULATE,
      FieldDetail.ICalculateDetail
    >;
    export type TSummaryField = IField<
      FieldType.SUMMARY,
      FieldDetail.ISummaryDetail
    >;

    export type TSubTableField = IField<
      FieldType.SUB_TABLE,
      FieldDetail.ISubTableDetail
    >;

    export type TNumberAutoField = IField<FieldType.NUMBER_AUTO, object>;

    export type TPoolField = IField<FieldType.POOL, {}>;

    export type TLayoutField = IField<FieldType.LAYOUT, {}>;

    export type TField =
      | TTextSingleField
      | TWebsiteField
      | TTextMultiField
      | TNumberField
      | TMoneyField
      | TPercentField
      | TTelePhoneField
      | TRadioField
      | TSelectMultiField
      | TSelectSingleField
      | TCheckboxField
      | TDateField
      | TDateTimeField
      | TUserField
      | TUserListField
      | TAddressField
      | TLocationField
      | TRichTextField
      | TFileField
      | TObjectAssociateField
      | TFatherChildField
      | TSlaveObjectField
      | TPictureField
      | TRefField
      | TSummaryField
      | TCalculateField
      | TNumberAutoField
      | TSubTableField
      | TPoolField
      | TLayoutField;
  }

  export module System {
    export interface Region {
      pid?: string;
      label: string;
      value: string;
      children: Region[];
    }
    export interface User {
      fullName: string;
      isActive: boolean;
      primaryRoleId: string;
      primaryRoleName: string;
      primaryDepartmentId: string;
      primaryDepartmentName: string;
      userId: string;
      userName: string;
      tenantId: string;
      photoUrl: string;
      userSystemIds: string[];
      deptName?: string;
      email?: string;
    }

    export interface Role {
      roleDesc: string;
      roleId: string;
      roleName: string;
      tenantId: string;
    }

    export interface Department {
      departmentDesc: string;
      departmentId: string;
      departmentName: string;
      tenantId: string;
      preDepartmentId: string;
    }
    export interface DepartmentTree extends Department {
      tenantOrganizationDTOList: DepartmentTree[];
    }
  }

  export module Condition {
    export type LogicType = keyof typeof Define.ConditionLogicEnum;

    export type ItemQueryType = keyof typeof Define.ItemQueryTypeEnum;

    type FilterValueBase<
      Type extends FieldType = FieldType,
      QueryType extends ItemQueryType = ItemQueryType,
      Other extends Record<string, any> = object
    > = {
      fieldType: Type;
      fieldName: string;
      fieldFilterType: 'END';
      fieldApiName: string;
      itemQueryType: QueryType;
    } & Other;

    type TextFilterValue = FilterValueBase<
      | FieldType.TEXT_SINGLE
      | FieldType.TEXT_MULTI
      | FieldType.NUMBER_AUTO
      | FieldType.EMAIL
      | FieldType.WEBSITE,
      | 'EQ'
      | 'NEQ'
      | 'REGEX'
      | 'NOT_REGEX'
      | 'START_WITH'
      | 'END_WITH'
      | 'EMPTY'
      | 'NONE_EMPTY',
      {
        singleFieldValue: {
          stringValue: string;
        };
      }
    >;

    type NumberFilterValue = FilterValueBase<
      FieldType.MONEY | FieldType.NUMBER | FieldType.PERCENT,
      'EQ' | 'NEQ' | 'GT' | 'GTE' | 'LT' | 'LTE' | 'EMPTY' | 'NONE_EMPTY',
      {
        singleFieldValue: {
          doubleValue: number;
        };
      }
    >;

    type DateFilterValue = FilterValueBase<
      FieldType.DATE | FieldType.DATE_TIME,
      'EQ' | 'LT' | 'LTE' | 'EMPTY' | 'NONE_EMPTY',
      {
        singleFieldValue: {
          timeValue: number;
        };
      }
    >;

    type SelectFilterValue = FilterValueBase<
      FieldType.SELECT_SINGLE | FieldType.RADIO,
      'EQ' | 'NEQ' | 'EMPTY' | 'NONE_EMPTY',
      {
        singleFieldValue: { optionKey: string };
      }
    >;

    type SelectFilterMultiValue = FilterValueBase<
      FieldType.SELECT_SINGLE | FieldType.RADIO,
      'CONTAIN' | 'NOT_CONTAIN',
      {
        singleFieldValue: { optionKeyList: string[] };
      }
    >;

    type SelectMultiFilterValue = FilterValueBase<
      FieldType.SELECT_MULTI | FieldType.CHECKBOX,
      'EQ' | 'NEQ' | 'INTERSECT' | 'NOT_INTERSECT' | 'EMPTY' | 'NONE_EMPTY',
      {
        containsFieldValueList: {
          optionKeyList: string[];
        }[];
      }
    >;
    type SelectMultiFilterMultiValue = FilterValueBase<
      FieldType.SELECT_MULTI | FieldType.CHECKBOX,
      'CONTAIN' | 'NOT_CONTAIN',
      {
        containsFieldValueList: {
          optionKeyList: string[];
        }[];
      }
    >;

    type UserFilterValue = FilterValueBase<
      FieldType.USER,
      'EQ' | 'NEQ' | 'EMPTY' | 'NONE_EMPTY',
      {
        singleFieldValue: { stringValue: string };
      }
    >;

    type UserFilterMultiValue = FilterValueBase<
      FieldType.USER,
      'CONTAIN' | 'NOT_CONTAIN',
      {
        containsFieldValueList: { stringValue: string }[];
      }
    >;

    type UserListFilterValue = FilterValueBase<
      FieldType.USER_LIST,
      'EQ' | 'NEQ' | 'INTERSECT' | 'NOT_INTERSECT' | 'EMPTY' | 'NONE_EMPTY',
      {
        singleFieldValue: {
          stringValueList: string[];
        };
      }
    >;

    type UserListFilterMultiValue = FilterValueBase<
      FieldType.USER_LIST,
      'CONTAIN' | 'NOT_CONTAIN',
      {
        containsFieldValueList: {
          stringValueList: string[];
        }[];
      }
    >;

    type LayoutFilterValue = FilterValueBase<
      FieldType.LAYOUT,
      'EQ' | 'NEQ',
      {
        singleFieldValue: {
          layoutId: string;
        };
      }
    >;
    type LayoutFilterMultiValue = FilterValueBase<
      FieldType.LAYOUT,
      'CONTAIN' | 'NOT_CONTAIN',
      {
        containsFieldValueList: {
          layoutId: string;
        }[];
      }
    >;

    type PoolFilterValue = FilterValueBase<
      FieldType.POOL,
      'EQ' | 'NEQ' | 'EMPTY' | 'NONE_EMPTY',
      {
        singleFieldValue: {
          poolId: string;
        };
      }
    >;

    type PoolFilterMultiValue = FilterValueBase<
      FieldType.POOL,
      'CONTAIN' | 'NOT_CONTAIN',
      {
        containsFieldValueList: {
          poolId: string;
        }[];
      }
    >;

    export type FieldFilterValue =
      | TextFilterValue
      | NumberFilterValue
      | DateFilterValue
      | SelectFilterValue
      | SelectFilterMultiValue
      | SelectMultiFilterValue
      | SelectMultiFilterMultiValue
      | UserFilterValue
      | UserFilterMultiValue
      | UserListFilterValue
      | UserListFilterMultiValue
      | LayoutFilterValue
      | LayoutFilterMultiValue
      | PoolFilterValue
      | PoolFilterMultiValue;

    export interface Condition {
      filterList: FieldFilterValue[];
      logicType: LogicType;
    }
  }

  export module API {
    export type PageParam<T extends Record<string, any>> = {
      pageNum: number;
      pageSize: number;
    } & T;
    export interface PageResponse<T extends any> {
      content: T[];
      pageInfo: {
        pageSize: number;
        pageNumber: number;
      };
      total: number;
    }
  }
}

import {
  DataIdContext,
  ObjectContext,
  ViewMode,
  useDoorType,
} from '@dxp-presets/context';
import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import DxpCanvas from './core';
import { useRequest } from '@gaker/hooks';
import { getDxpConfig } from '@dxp-api/index';
import useScreen from '@gaker/hooks/useScreen';
import { getConfigId } from '@dxp-utils/getConfigId';
import { Debugger } from '@gaker/components';
import { Button } from 'antd';
import { IDxpEditor } from '@dxp-declare';
import { EditorStore } from '@dxp-hooks/storeContent';
import NoMore from '@dxp-components/NoMore';

const Runner: React.FN<{
  canvas?(
    config: IDxpEditor.IEditor,
    object: {
      objectApiName: string;
      objectName?: string;
    }
  ): React.ReactNode;
}> = ({
  canvas = (config) => <DxpCanvas config={config}></DxpCanvas>,
  ...props
}) => {
  const { objectApiName = '', dataId = '' } = useParams<{
    objectApiName: string;
    dataId: string;
  }>();

  const { data: config, refresh } = useRequest(getDxpConfig, {
    defaultLoaded: false,
  });

  const type = useDoorType();

  useEffect(() => {
    refresh({ objectApiName: getConfigId(objectApiName, 'view'), type });
  }, [objectApiName, dataId, type]);
  const { width: screenWidth } = useScreen();

  const device = useMemo(() => {
    if (screenWidth >= 1024) return 'computer';
    if (screenWidth >= 720) return 'pad';
    return 'phone';
  }, [screenWidth]);
  return (
    <DataIdContext value={dataId}>
      <ObjectContext value={{ objectApiName, objectName: '' }}>
        <ViewMode value={device}>
          <Debugger>
            <Button
              className="fixed top-20 left-200 z-1000"
              onClick={() => {
                console.log(JSON.parse(JSON.stringify(config?.config)));
              }}
            >
              打印数据
            </Button>
          </Debugger>
          {config?.config && (
            <EditorStore
              config={config.config}
              objectApiName={objectApiName}
              mode="view"
            >
              {canvas(config.config, { objectApiName, objectName: '' })}
              {props.children}
            </EditorStore>
          )}
        </ViewMode>
      </ObjectContext>
    </DataIdContext>
  );
};

export default Runner;

import { useKeepAliveState } from '@dxp-components/KeepAlive/context';
import { IDxpRunner } from '@dxp-declare';
import { createPostMessage } from '@gaker/tools';

export const listener = createPostMessage<IDxpRunner.IEmitterEvent>();

const oldEvenListener = listener.useEventListener.bind(listener);

listener.useEventListener = (event, handler, deps) => {
  const keepALiveState = useKeepAliveState();
  return oldEvenListener.call(
    listener,
    event,
    function (data) {
      if (!keepALiveState) {
        return Promise.reject();
      }
      return handler(data as any);
    },
    deps
  );
};

export default listener;

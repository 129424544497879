/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-12-07 17:16:12
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2023-12-13 16:04:08
 * @FilePath: /gaker/libs/theme/src/antd/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import { ConfigProvider } from 'antd';
import { useThemePrimaryColor } from '../react/hook';
import zhCN from 'antd/locale/zh_CN';
import 'dayjs/locale/zh-cn';
import defaultToken, { defaultComponents } from './antDTheme';
const AntdHOC = <T extends {}>(
  Component: React.FunctionComponent<T>,
  config?: { prefixCls?: string }
): React.FC<T> => {
  return (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { color } = useThemePrimaryColor();
    return React.createElement(
      ConfigProvider,
      {
        theme: {
          token: {
            colorPrimary: color,
            ...defaultToken,
          },
          components: {
            ...defaultComponents,
          },
        },
        locale: zhCN,
        prefixCls: config?.prefixCls,
      },
      [React.createElement(Component, { ...props, key: 'c3' })]
    );
  };
};

export default AntdHOC;

import { useEffect, useMemo, useRef, useState } from 'react';

// todo ResizeObserver兼容性问题解决
export const useSizeObserver = (): [
  {
    width: number;
    height: number;
    left: number;
    right: number;
    top: number;
    bottom: number;
  },
  (el?: HTMLElement | null) => void,
  HTMLElement | null | undefined
] => {
  const elRef = useRef<HTMLElement>();
  const [size, setSize] = useState({
    width: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  });
  const observer = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (let entry of entries) {
          const { width } = entry.contentRect;
          if (width <= 0) {
            return;
          }
          setSize(entry.target.getBoundingClientRect().toJSON());
        }
      }),
    []
  );
  useEffect(() => () => observer.disconnect(), []);
  return [
    size,
    (el) => {
      if (!el || elRef.current) return;
      elRef.current = el;
      observer.observe(el);
    },
    elRef.current,
  ];
};

export default useSizeObserver;

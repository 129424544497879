import { uuid } from '@gaker/tools';
import { ValueBaseStore } from './baseStore';
import { IDxpEditor } from '@dxp-declare';

export class RunnerStore extends ValueBaseStore {
  data: any = {};
  objectApiName: string;
  id: string = uuid(10);
  constructor(objectApiName: string, data: {}) {
    super();
    this.objectApiName = objectApiName;
    this.data = data;
    this.id = uuid(10);
  }
  get(field: IDxpEditor.Models.IFieldVO) {
    if (!field) return;
    return this.data[field.fieldApiName];
  }
}

import React, { useContext, useState } from 'react';
import { Color } from '../define';
import { setPrimaryColor } from '../lib';

const ThemeContext = React.createContext({
  colorPrimary: Color.PrimaryColor,
  setPrimaryColor(_color: string) {},
});

export const ReactThemeHOC = <T extends {}>(
  Component: React.FunctionComponent<T>
): React.FC<T> => {
  return (props) => {
    const [primaryColor, setPrimaryColor] = useState(Color.PrimaryColor);
    return React.createElement(
      ThemeContext.Provider,
      {
        key: 'theme-hoc',
        value: {
          colorPrimary: primaryColor,
          setPrimaryColor(color) {
            setPrimaryColor(color);
          },
        },
      },
      [React.createElement(Component, { ...props, key: 'theme-hoc-component' })]
    );
  };
};

export const useThemePrimaryColor = () => {
  const context = useContext(ThemeContext);
  return {
    color: context.colorPrimary,
    set(color: string) {
      context.setPrimaryColor(color);
      setPrimaryColor(color);
    },
  };
};

import { FieldType } from '@gaker/models';
import { Models as GModels, Models } from '@declare-models';
export namespace IDxpEditor {
  export namespace Types {
    export type TDoorType = 'client' | 'supplier' | 'agent';
    export type TDeviceType = 'computer' | 'pad' | 'phone';
    export type TComputerDefaultListType = 'table' | 'card' | 'board';
    export type TPageMode = 'create' | 'view' | 'edit';
    export type TListDisplayType = 'tile' | 'tab';
    type TWidth<T extends string> =
      | `${T}.0`
      | `${T}.1`
      | `${T}.2`
      | `${T}.3/2`
      | `${T}.3`;
    export type TComponentWidth = TWidth<'flex' | 'width'> | number;
    export type TLayoutType =
      | 'form.flex'
      | 'form-list.flex'
      | 'form.flex-list'
      | 'form-list.flex-list'
      | 'vertical:from.flex-list.flex';

    export type TPresetsComponentType =
      | 'title-bar'
      | 'title-filed-bar'
      | 'title-bar-image'
      | 'top-bar'
      | 'column-bar'
      | 'container'
      | 'field'
      | 'list'
      | 'comment';

    export type TPresetsComponentGroup =
      | 'layout'
      | 'list'
      | 'field'
      | 'title-bar'; // title-bar 特殊的 单独放一个组

    export type TBarFiledLayoutType =
      | 'col:field.left-value.left'
      | 'field.left-value.left'
      | 'field.left-value.right'
      | 'field.right-value.right'
      | 'field.right-value.left';

    export type TPercentStyle = 'number' | 'bar' | 'ring';

    export type TImageFitWay =
      | '40-40'
      | '40-30'
      | '80-80'
      | '80-60'
      | 'max.72-max.40'
      | 'max.143-max.80' // todo 感觉有点问题，后续跟踪
      | 'auto-max.with:4/3'
      | 'auto-eq.width';
  }

  export namespace Components {
    interface IComponentBaseConfig {
      width: Types.TComponentWidth;
      direction?: 'vertical' | 'horizontal';
      abstract?: boolean;
    }

    interface IBaseBarConfig extends IComponentBaseConfig {
      bar?: 3 | 2 | 1;
      layout: Types.TBarFiledLayoutType;
    }

    export interface IComponent<
      Type extends Types.TPresetsComponentType = Types.TPresetsComponentType,
      Config extends IComponentBaseConfig = IComponentBaseConfig
    > {
      id: string;
      type: Type;
      config: Config;
    }

    export interface IBarComponent<
      Type extends Types.TPresetsComponentType = Types.TPresetsComponentType,
      Config extends IComponentBaseConfig = IComponentBaseConfig,
      Children extends IComponent<
        Types.TPresetsComponentType,
        any
      > = IComponent<Type, Config>
    > extends IComponent<Type, Config> {
      children: Children[];
    }

    interface ICommentComponentConfig extends IComponentBaseConfig {
      content: string;
    }

    export type TCommentComponent = IComponent<
      'comment',
      ICommentComponentConfig
    >;

    interface IContainerComponentConfig extends IComponentBaseConfig {
      justContainer: boolean;
      isDetail?: boolean; // 此字段给布局做聚合使用 在单列布局时需要将详情放入到tab，此时虚构了一个容器
    }

    export type TContainerComponent = IBarComponent<
      'container',
      IContainerComponentConfig,
      IComponent
    >;

    interface ITitleBarConfig extends IComponentBaseConfig {
      layout: Types.TBarFiledLayoutType;
      hideFieldName: boolean;
      showStatus: boolean;
      borderBg: boolean;
      icon: TTitleBarImageComponent | null;
      title: TFieldComponent<any> | null;
      status: TFieldComponent<any> | null;
    }

    export type TTitleBarComponent = IBarComponent<
      'title-bar',
      ITitleBarConfig,
      TContainerComponent
    >;

    export type TTitleBarImageComponent = IComponent<
      'title-bar-image',
      TFieldComponentConfig<
        FieldType.PICTURE,
        {
          fit: Types.TImageFitWay;
        }
      >
    >;

    export type TTitleFieldBarComponent = IBarComponent<
      'title-filed-bar',
      IBaseBarConfig,
      TColumnBarComponent
    >;

    export type TTopBarComponent = IBarComponent<
      'top-bar',
      {
        width: Types.TComponentWidth;
        direction: 'vertical' | 'horizontal';
        type: 'form' | 'list';
        display: Types.TListDisplayType;
        borderBg?: boolean;
      },
      TContainerComponent
    >;

    export interface IColumnConfig extends IBaseBarConfig {
      name: string;
      justContainer?: boolean;
    }
    export type TColumnBarComponent = IBarComponent<
      'column-bar',
      IColumnConfig,
      TContainerComponent
    >;

    export interface IListConfig extends IComponentBaseConfig {
      objectName: string;
      objectId: string;
      associateFieldApiName?: string; // 当为子表单时，需要存在子表单的字段ID，在新建时需要此ID
      associateObjectApiName?: string;
    }

    export type TListComponent = IComponent<'list', IListConfig>;

    export interface IFieldComponentBaseConfig<
      Type extends FieldType,
      ValueType extends FieldType = Type,
      Detail extends any = any
    > extends IComponentBaseConfig {
      field: {
        fieldApiName: string;
        fieldName: string;
        fieldType: Type;
        valueType: ValueType;
        fieldDetail: Detail;
      };
      name: string;
    }

    type TFieldComponentConfig<
      Type extends FieldType,
      Config extends {},
      Detail extends any = any
    > = IFieldComponentBaseConfig<Type, Type, Detail> & Config;

    type TImageFieldConfig = TFieldComponentConfig<
      FieldType.PICTURE,
      {
        fit?: Types.TImageFitWay;
      }
    >;

    interface INumberConfig {
      largeFont: boolean;
      highlight: boolean;
    }

    type TNumberFiledConfig = TFieldComponentConfig<
      FieldType.NUMBER,
      INumberConfig,
      GModels.FieldDetail.INumberDetail
    >;

    type TMoneyFieldConfig = TFieldComponentConfig<
      FieldType.MONEY,
      INumberConfig,
      GModels.FieldDetail.IMoneyDetail
    >;

    type TPercentFieldConfig = TFieldComponentConfig<
      FieldType.PERCENT,
      {
        style: Types.TPercentStyle;
      },
      GModels.FieldDetail.IPercentDetail
    >;

    type TCheckBoxFieldConfig = TFieldComponentConfig<
      FieldType.CHECKBOX,
      {},
      GModels.FieldDetail.ICheckBoxDetail
    >;
    type TRadioFieldConfig = TFieldComponentConfig<
      FieldType.RADIO,
      {},
      GModels.FieldDetail.ICheckBoxDetail
    >;

    type TSelectSingleFieldConfig = TFieldComponentConfig<
      FieldType.SELECT_SINGLE,
      {},
      GModels.FieldDetail.ICheckBoxDetail
    >;

    type TSelectMultiFieldConfig = TFieldComponentConfig<
      FieldType.SELECT_MULTI,
      {},
      GModels.FieldDetail.ICheckBoxDetail
    >;

    type TBridgeFieldConfig<
      Type extends FieldType,
      ValueType extends FieldType,
      Config extends TFieldComponentConfig<ValueType, any>
    > = TFieldComponentConfig<Type, ValueType, any> &
      Omit<Config, 'field' | 'name'>;

    type TCalculateFieldConfig =
      | TBridgeFieldConfig<
          FieldType.CALCULATE,
          FieldType.NUMBER,
          TNumberFiledConfig
        >
      | TBridgeFieldConfig<
          FieldType.CALCULATE,
          FieldType.MONEY,
          TMoneyFieldConfig
        >
      | TBridgeFieldConfig<FieldType.CALCULATE, FieldType.TEXT_SINGLE, {}>
      | TBridgeFieldConfig<FieldType.CALCULATE, FieldType.DATE, {}>
      | TBridgeFieldConfig<FieldType.CALCULATE, FieldType.USER, {}>
      | TBridgeFieldConfig<FieldType.CALCULATE, FieldType.USER_LIST, {}>;

    type TRefFieldConfig =
      | TBridgeFieldConfig<FieldType.REF, FieldType.NUMBER, TNumberFiledConfig>
      | TBridgeFieldConfig<FieldType.REF, FieldType.MONEY, TMoneyFieldConfig>;

    type TSummaryFieldConfig =
      | TBridgeFieldConfig<
          FieldType.SUMMARY,
          FieldType.NUMBER,
          TNumberFiledConfig
        >
      | TBridgeFieldConfig<
          FieldType.SUMMARY,
          FieldType.MONEY,
          TMoneyFieldConfig
        >;

    export type TFieldComponentConfigMap = {
      [FieldType.PICTURE]: TImageFieldConfig;
      [FieldType.NUMBER]: TNumberFiledConfig;
      [FieldType.MONEY]: TMoneyFieldConfig;
      [FieldType.PERCENT]: TPercentFieldConfig;
      [FieldType.CALCULATE]: TCalculateFieldConfig;
      [FieldType.REF]: TRefFieldConfig;
      [FieldType.SUMMARY]: TSummaryFieldConfig;
      [FieldType.RADIO]: TRadioFieldConfig;
      [FieldType.CHECKBOX]: TCheckBoxFieldConfig;
      [FieldType.SELECT_MULTI]: TSelectMultiFieldConfig;
      [FieldType.SELECT_SINGLE]: TSelectSingleFieldConfig;
    };

    export type TCommonFieldComponent = IComponent<
      'field',
      IFieldComponentBaseConfig<FieldType>
    >;

    export type TFieldComponent_bak =
      | IComponent<'field', TImageFieldConfig>
      | IComponent<'field', TNumberFiledConfig>
      | IComponent<'field', TMoneyFieldConfig>
      | IComponent<'field', TPercentFieldConfig>
      | IComponent<'field', TCalculateFieldConfig>
      | IComponent<'field', TRefFieldConfig>
      | IComponent<'field', TSummaryFieldConfig>
      | IComponent<'field', TFieldComponentConfig<FieldType, {}>>;

    export type TFieldComponent<Type extends FieldType = any> =
      Type extends keyof TFieldComponentConfigMap
        ? IComponent<
            'field',
            TFieldComponentConfig<Type, TFieldComponentConfigMap[Type]>
          >
        : IComponent<'field', TFieldComponentConfig<Type, {}>>;

    export type TComponent =
      | TFieldComponent
      | TTopBarComponent
      | TColumnBarComponent
      | TCommentComponent
      | TListComponent
      | TTitleBarComponent
      | TTitleBarImageComponent
      | TContainerComponent;
  }

  export namespace Interactive {
    type TDragBase<
      Type extends Types.TPresetsComponentType = Types.TPresetsComponentType,
      Data extends any = {}
    > = {
      type: Type;
      group: Types.TPresetsComponentGroup;
      name: string;
      formComponentId?: string;
      data: Data;
    };

    export type TDragField = TDragBase<'field', Models.IFieldVO>;
    export type TDragList = TDragBase<
      'list',
      {
        objectName: string;
        objectId: string;
        associateFieldApiName?: string; // 当为子表单时，需要存在子表单的字段ID，在新建时需要此ID
        associateObjectApiName?: string;
      }
    >;

    export type TEditorDragItem =
      | TDragBase<'top-bar', {}>
      | TDragBase<'column-bar', {}>
      | TDragBase<'comment', {}>
      | TDragList
      | TDragField;
  }

  export namespace Models {
    export interface IObjectVO {
      objectApiName: string;
      objectName: string;
      associateFieldApiName?: string;
      associateObjectApiName?: string; // 当是子表单时才会有这个属性
    }
    export interface IFieldVO {
      fieldApiName: string;
      fieldName: string;
      fieldType: FieldType;
      valueType: FieldType;
      fieldDetail: any;
    }

    export interface CurrentUserVO {
      telephone: string;
      tenantId: string;
      userId: string;
      memberId: string;
      userName: string;
      avatar?: string;
    }

    export interface WxUserInfo {
      openid: string;
      nickname: string;
      sex: number;
      province: string;
      city: string;
      country: string;
      headimgurl: string;
      unionid: string;
    }

    export interface WebSite {
      brandColor: string;
      domain: string;
      id: string;
      logo: string;
      siteDesc: string;
      siteName: string;
      icon: string;
      type: Types.TDoorType;
    }

    export interface IMemberUser {
      accountStatus: string;
      memberId: string;
      telephone: string;
      tenantId: string;
      userId: string;
      userName: string;
      lastLoginTime: number;
      activeTime: number;
    }

    export interface IRegionDTO {
      pid: string;
      id: string;
      value: string;
      cityList: IRegionDTO[];
    }

    export interface IRegionVO {
      pid: string;
      label: string;
      value: string;
      children: IRegionVO[];
    }

    export enum DoorObjectApiName {
      client = 'customerObject',
      supplier = 'supplierObject',
      agent = 'agentObject',
    }
  }

  export type TPresetsComponentConfig<
    T extends Components.IComponent = Components.IComponent,
    Key extends keyof T['config'] = keyof T['config'],
    Props extends any = {}
  > = React.FC<
    {
      component: T;
      onChange?(
        valueChange: Record<Key, T['config'][Key]>,
        config: T['config']
      ): void;
    } & Props
  >;

  export type TPresetsComponent<T extends Components.IComponent> = React.FN<{
    component: T;
    className?: string;
  }> & {
    Config: TPresetsComponentConfig<T>;
  } & {
    type: IDxpEditor.Types.TPresetsComponentType;
  };

  export type TEditorTileConfigMap = UnionToIntersection<
    Values<Required<IEditor>>
  >;

  export interface ILayout {
    type: Types.TLayoutType;
    titleBarTop: boolean;
  }

  export interface IEditor {
    buffer: {
      device: Types.TDeviceType;
      current: Components.IComponent;
      currentPath: (string | number)[];
    };
    setting: {
      name?: string;
      layout: ILayout;
      pcView: Types.TComputerDefaultListType;
      filter: {
        fieldApiName: string;
        values?: {
          optionValueList: string[];
          optionKeyList: string[];
        };
      }[];
      listOption: string;
      mobile2Col: boolean;
      createAuth: boolean;
      editAuth: {
        condition: [
          {
            fieldApiName: string;
            logic: any; // @todo 备用扩展字段，一阶段默认都是包含
            values?: {
              optionValueList: string[];
              optionKeyList: string[];
            };
          }
        ];
        controlFields?: string[];
      }[];
    };
    titleBar: Components.TTitleBarComponent;
    components: Components.TTopBarComponent[];
  }
}

export namespace IDxpRunner {
  export type TRunnerComponent<
    T extends IDxpEditor.Components.TComponent,
    Props extends {} = {}
  > = React.FN<
    {
      component: T;
    } & Props
  >;

  type DataId = string;
  type DataObjectApiName = string;
  type TFromSaveDataKey = `form-save-${DataObjectApiName}-${DataId}`;
  type TFromCloseDataKey = `form-cancel-${DataId}`;
  type TFormDeleteDataRow = `form-delete-${DataObjectApiName}`;
  type TFormForceCalc = `form-calc-${DataObjectApiName}-${DataId}`;
  type TFromRefreshKey = `list-force-refresh-${DataObjectApiName}`;
  type TDetailRefreshKey = `detail-refresh-${DataObjectApiName}-${DataId}`;
  export interface IEmitterEvent {
    save: { isValidate?: boolean };
    print: {};
    'form-cancel': { objectApiName: string; dataId: string };
    'form-save': { objectApiName: string; dataId: string };
    'list-add': {
      objectApiName: string;
      dataId: string;
      associateObjectApiName?: string;
      associateDataId?: string; // 子表单编辑的时候会用
      associateFieldApiName?: string; // 子表单编辑的时候会用
      objectName?: string;
      isMaster?: boolean;
      isEdit?: boolean; // 是否是编辑
    };
    'list-item-click': {
      objectApiName: string;
      objectName?: string;
      dataId: string;
    };
    'list-item-delete': {
      objectApiName: string;
      dataId: string;
    };
    'object-edit': {
      objectApiName: string;
      dataId: string;
      associateObjectApiName?: string; // 子表单编辑的时候会用
      associateDataId?: string; // 子表单编辑的时候会用
      associateFieldApiName?: string; // 子表单编辑的时候会用
    };
    [key: TFormDeleteDataRow]: {
      dataId: string;
    };
    [key: TFromRefreshKey]: {};
    [key: TFromSaveDataKey]: IDxpEdit.IFormData;
    [key: TFromCloseDataKey]: { objectApiName: string; dataId: string };
    [key: TDetailRefreshKey]: {};
    [key: TFormForceCalc]: {};
  }
}

export namespace IDxpEdit {
  export type TEditComponent<
    T extends IDxpEditor.Components.TComponent,
    Props extends {} = {}
  > = React.FN<
    {
      component: T;
    } & Props
  >;

  type DataApiName = string;
  type DataObjectApiName = string;

  export interface IFormData {
    associateDataMap: Record<DataObjectApiName, IFormData[]>;
    masterData: {
      dataId: string;
      objectApiName: DataObjectApiName;
      dataDetail: Record<DataApiName, Models.FieldValue.TFieldValue['value']>;
    };
  }

  export interface IFormDataDTO {
    associateDataMap: Record<DataObjectApiName, IFormData['masterData'][]>;
    masterData: {
      dataId: string;
      objectApiName: DataObjectApiName;
      dataDetail: Record<DataApiName, Models.FieldValue.TFieldValue['value']>;
    };
  }
}

export namespace DTO {
  export module Common {
    type TOjectApiName = string;
    type TFieldApiNameKey = string;
    export type RefFieldKey = `${TOjectApiName}.${TFieldApiNameKey}`;
    export interface IExpressAffectDTO {
      refFieldIndexMap: Record<RefFieldKey, number>; //@mark 用途未知，先不用管
      refObjectIndexMap: Record<TOjectApiName, number>; //@mark 用途未知，先不用管
      refFieldMap: Record<RefFieldKey, RefFieldKey[]>;
      refObjects: TOjectApiName[];
    }

    export interface ILayout {
      headerLayout: Models.Field.TField[];
      itemLayouts: Array<{
        itemName: string;
        fieldLayoutList: Array<
          Models.Field.TField & {
            hideOnCreate?: boolean;
          }
        >;
      }>;
      objectApiNames: string[];
    }
  }
}

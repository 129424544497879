import { useEffect, useState } from 'react';

export const useScreen = () => {
  const [screen, setScreenWidth] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const resize = () => {
      setScreenWidth({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);
  return screen;
};
export default useScreen;

/*
 * @Author: your name
 * @Date: 2022-03-15 23:18:26
 * @LastEditTime: 2024-01-23 11:09:43
 * @LastEditors: Arthur arthur@lwork.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saleswork-components/src/DefaultAvatar/index.tsx
 */

import { Avatar } from 'antd';
import React, { CSSProperties, FC } from 'react';

interface Props {
  src?: string;
  title?: string;
  bgColor?: string;
  size?: 'default' | 'small' | 'large' | number;
  style?: CSSProperties;
  icon?: string;
  minWidth?: number;
  className?: string;
}
/**取默认背景色*/
const getColor = (val: string) => {
  const code = val?.codePointAt(1) || 0;
  const count = code % 6; //[0-5]
  const result = {
    0: '#13c1b8',
    1: '#46bd5e',
    2: '#4d97ff',
    3: '#e69f3c',
    4: '#ebbe1e',
    5: '#c37af9',
  }[count];
  return result;
};

/**默认头像*/
export const DefaultAvatar: FC<Props> = ({ ...props }) => {
  const { title = 'Gaker' } = props;
  const { size, src, minWidth, ...otherProps } = props;
  /**图片模式*/
  if (src) {
    return (
      <Avatar
        src={src}
        size={size}
        style={{ minWidth: minWidth }}
        {...otherProps}
      >
        {title}
      </Avatar>
    );
  }

  /**无链接模式*/
  const { length } = title;
  let showName = title;
  /**截取后两位*/
  if (length > 2) {
    showName = title.substring(length - 2, length);
  }
  return (
    <Avatar
      size={size}
      {...otherProps}
      style={{
        backgroundColor: getColor(title),
        minWidth: minWidth,
      }}
    >
      {showName}
    </Avatar>
  );
};

export default DefaultAvatar;

import emitter from '@dxp-utils/emitter';
import listener from '@dxp-utils/listener';

type Params = Parameters<typeof emitter.useEmitter>;

export const useListenerToEmitter = (name: Params[0], deps: Params[2]) => {
  listener.useEventListener(
    name,
    (data) => {
      emitter.emit(name, data);
    },
    deps
  );
};

export default useListenerToEmitter;

/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-09-25 16:33:36
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2023-12-13 16:12:23
 * @FilePath: /gaker/apps/home-page/src/app/components/Theme/antDTheme.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const defaultToken = {
  // colorPrimary: '#13c1b8',
  borderRadius: 4,
  wireframe: true,
  colorSuccess: '#00b42a',
  colorWarning: '#ff7d00',
  colorError: '#f53f3f',
  colorInfo: '#165dff',
  fontSizeHeading1: 24,
  fontSizeHeading2: 20,
  fontSizeHeading3: 16,
  fontSizeHeading4: 14,
  colorText: '#1d2129',
  colorTextSecondary: '#4e5969',
  colorTextTertiary: '#86909c',
  colorTextQuaternary: '#c9cdd4',
  colorBorder: '#E5E6EB',
  colorBorderSecondary: '#f2f3f5',
};

export const defaultComponents = {
  Table: {
    padding: 8,
    headerBorderRadius: 4,
    colorTextHeading: '#4e5969',
  },
  Button: {
    colorTextDisabled: '#fff',
  },
  Form: {
    itemMarginBottom: 16,
  },
};

export default defaultToken;

import { IDxpEditor } from '@dxp-declare';
import { createComponentId } from './createComponentId';
import { FieldType } from '@gaker/models';
import { Models } from '@declare-models';

const createComponent = <
  T extends IDxpEditor.Components.IComponent = IDxpEditor.Components.IComponent
>(
  type: T['type'],
  config: T['config'],
  children?: 'children' extends keyof T ? T['children'] : undefined
): 'children' extends keyof T ? T & { children: T['children'] } : T => {
  return { type: type, id: createComponentId(type), config, children } as any;
};

const createFieldComponentCommon = <Type extends FieldType>(
  data: Required<IDxpEditor.Interactive.TDragField>['data'],
  config: Omit<
    IDxpEditor.Components.TFieldComponent<Type>['config'],
    'field' | 'name' | 'width'
  >
): IDxpEditor.Components.TFieldComponent<Type> => {
  return createComponent<IDxpEditor.Components.TFieldComponent<Type>>('field', {
    name: data.fieldName,
    field: {
      ...data,
      fieldType: data.fieldType as any,
      valueType: data.valueType as any,
    },
    width: 'width.1',
    ...config,
  });
};

const createFieldImageComponent = (data: any) => {
  return createFieldComponentCommon<FieldType.PICTURE>(data, {
    // fit: '80-80',
  });
};

const createFieldNumberComponent = (data: any) => {
  return createFieldComponentCommon<FieldType.NUMBER>(data, {
    largeFont: false,
    highlight: false,
  });
};
const createFieldMoneyComponent = (
  data: IDxpEditor.Interactive.TDragField['data']
) => {
  return createFieldComponentCommon<FieldType.MONEY>(data, {
    largeFont: false,
    highlight: false,
  });
};

const createFieldPercentComponent = (data: any) =>
  createFieldComponentCommon<FieldType.PERCENT>(data, {
    style: 'number',
  });

export const createColumBarComponent = (
  config: Partial<IDxpEditor.Components.IColumnConfig>
) => {
  return createComponent<IDxpEditor.Components.TColumnBarComponent>(
    'column-bar',
    {
      width: 'width.1',
      bar: 1,
      name: '',
      justContainer: false,
      layout: 'field.left-value.left',
      ...config,
    },
    [] as any
  );
};

export const createContainerComponent = (
  config: Partial<IDxpEditor.Components.TContainerComponent['config']> = {},
  children: IDxpEditor.Components.TContainerComponent['children'] = []
) => {
  return createComponent<IDxpEditor.Components.TContainerComponent>(
    'container',
    {
      width: 'width.1',
      justContainer: true,
      abstract: true,
      ...config,
    },
    children
  );
};

export const createTopBarComponentByLayout = (
  type: IDxpEditor.Types.TLayoutType
): IDxpEditor.Components.TTopBarComponent[] => {
  const [dir, containerName = dir] = type.split(':');
  return containerName.split('-').map((name, index, { length }) => {
    const [key, flex] = name.split('.');
    return createComponent(
      'top-bar',
      {
        width: flex === 'flex' ? 'flex.1' : 360,
        direction: dir === 'vertical' ? 'vertical' : 'horizontal',
        type: index === 0 ? 'form' : 'list',
        display: 'tile',
      },
      index === 0
        ? [createContainerComponent({}, [createColumBarComponent({})])]
        : []
    );
  });
};

export const createTitleBarComponent = () => {
  return createComponent<IDxpEditor.Components.TTitleBarComponent>(
    'title-bar',
    {
      layout: 'field.left-value.left',
      width: 'flex.0',
      hideFieldName: false,
      borderBg: false,
      showStatus: true,
      icon: null,
      title: null,
      status: null,
    },
    []
  );
};

export const createFieldComponent = (
  data: Required<IDxpEditor.Interactive.TDragField>['data']
): IDxpEditor.Components.TFieldComponent<any> => {
  switch (data.valueType) {
    case FieldType.PICTURE:
      return createFieldImageComponent(data);
    case FieldType.NUMBER:
      return createFieldNumberComponent(data);
    case FieldType.MONEY:
      return createFieldMoneyComponent(data);
    case FieldType.PERCENT:
      return createFieldPercentComponent(data);
    // case FieldType.SUMMARY:
    //   return createFieldSummaryComponent(data);
    // case FieldType.REF:
    //   return createFieldRefComponent(data);
    // case FieldType.CALCULATE:
    //   return createFieldCalculateComponent(data);
    default:
      return createComponent<IDxpEditor.Components.TFieldComponent>('field', {
        field: {
          ...data,
          fieldType: data.fieldType as any,
        },
        name: data.fieldName,
        width: 'width.1',
      });
  }
};

export const createListComponent = (
  data: Required<IDxpEditor.Interactive.TDragList>['data']
) => {
  return createComponent<IDxpEditor.Components.TListComponent>('list', {
    ...data,
    width: 'width.1',
  });
};

export const createTitleBarImageComponent = (
  data: IDxpEditor.Interactive.TDragField['data']
) => {
  return createComponent<IDxpEditor.Components.TTitleBarImageComponent>(
    'title-bar-image',
    {
      fit: '80-80',
      name: '',
      width: 'width.0',
      field: data as any,
    }
  );
};

export const createCommentComponent = () => {
  return createComponent<IDxpEditor.Components.TCommentComponent>('comment', {
    content: '',
    width: 'width.1',
  });
};

export const createComponentByDragItem = (
  data: IDxpEditor.Interactive.TEditorDragItem,
  options?: { container?: boolean }
) => {
  let component: IDxpEditor.Components.IComponent | undefined;
  if (data.type === 'field') {
    component = createFieldComponent(data.data);
  }
  if (data.type === 'column-bar') {
    component = createColumBarComponent({ direction: 'horizontal' });
  }
  if (data.type === 'list') {
    component = createListComponent(data.data);
  }
  if (data.type === 'comment') component = createCommentComponent();
  if (!component) return;
  if (options?.container) {
    component.config.direction = 'horizontal';
    return createContainerComponent({}, [component]);
  }
  return component;
};

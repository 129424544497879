import { Models } from '@declare-models';
import FieldType from '../FieldType';

function getRequiredMessage(field: Models.Field.TField) {
  return `请填写${field.fieldName}`;
}

const MAIL_REX = /^([a-zA-Z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,18})$/;
const URL_REX =
  /^(http(s)?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

export function validateValue(
  field: Models.Field.TField,
  value?: any,
  isCreateFrom = false,
  isStrictMode = false
): string | undefined {
  const { fieldType } = field;

  const isRequired = isStrictMode; //isFieldRequired(field) ||

  let resultMsg: string | undefined;

  if (field.fieldDetail && field.fieldDetail.isFVAutoGen) {
    //系统自动生成
    return resultMsg;
  }
  // field.fieldType !== FieldType.TEXT_FUNCTION &&

  if (
    !!field.fieldDetail.textLen &&
    !!value &&
    !!value.stringValue &&
    value.stringValue.length > field.fieldDetail.textLen
  ) {
    //有文本内容，且有超长限制
    resultMsg = `${field.fieldName}不允许超过${field.fieldDetail.textLen}字`;
    return resultMsg;
  }

  switch (fieldType) {
    // string
    case FieldType.TEXT_SINGLE:
    case FieldType.TEXT_MULTI:
    case FieldType.USER: {
      if (
        isRequired &&
        (isCreateFrom
          ? !value || !value.stringValue
          : !value ||
            value.stringValue === undefined ||
            value.stringValue === null)
      ) {
        resultMsg = getRequiredMessage(field);
      }
      break;
    }
    case FieldType.NUMBER_AUTO: {
      break;
    }
    case FieldType.WEBSITE: {
      if (isRequired && (!value || !value.stringValue)) {
        resultMsg = getRequiredMessage(field);
      }

      if (!!value && !!value.stringValue && !value.stringValue.match(URL_REX)) {
        return `请输入正确的${field.fieldName}`;
      }

      break;
    }

    // 不允许为空
    case FieldType.SELECT_SINGLE:
    case FieldType.RADIO: {
      // case FieldType.LAYOUT:
      if (isRequired && (!value || !value.optionKey)) {
        resultMsg = getRequiredMessage(field);
      }
      break;
    }
    case FieldType.SLAVE_ASSOCIATE:
    case FieldType.PARENT_SON:
    case FieldType.OBJECT_ASSOCIATE: {
      if (
        isRequired &&
        (!value ||
          `${value}` === `{}` ||
          !value.associateObjectApiName ||
          !value.associateDataId ||
          !value.associateMainFieldValue)
      ) {
        resultMsg = getRequiredMessage(field);
      }
      break;
    }

    case FieldType.USER_LIST: {
      if (
        isRequired &&
        (!value || !value.stringValueList || value.stringValueList.length === 0)
      ) {
        resultMsg = getRequiredMessage(field);
      }
      break;
    }
    // case FieldType.COUNTRY_CITY: {
    //   if (
    //     isRequired &&
    //     (!value || !value.stringValueList || value.stringValueList.length === 0)
    //   ) {
    //     resultMsg = getRequiredMessage(field);
    //   }
    //   break;
    // }

    case FieldType.ADDRESS: {
      if (isRequired && !value?.addressCodes?.length) {
        resultMsg = getRequiredMessage(field);
      } else if (value?.detail && !value?.addressCodes?.length) {
        //已经填写详细地址，但是未填写国家城市
        resultMsg = `请选择完整的${field.fieldName}`;
      } /* fix - 屏蔽地址选择层级判断
       else if (value?.addressCodes?.length) {
        const { format } = field.fieldDetail;
        if (format && value.addressCodes.length < AddressFormatLevel[format]) {
          resultMsg = `请选择完整的${field.fieldName}`;
        }
      }*/
      break;
    }

    case FieldType.LOCATION: {
      if (isRequired && !value?.location?.addressName) {
        resultMsg = getRequiredMessage(field);
      }
      break;
    }

    //fileInfoList
    case FieldType.PICTURE:
    case FieldType.FILE: {
      if (
        isRequired &&
        (!value || !value.fileInfoList || value.fileInfoList.length === 0)
      ) {
        resultMsg = getRequiredMessage(field);
      }

      if (value?.fileInfoList?.some((f: any) => !f.fileUrl)) {
        //文件未上传完成
        resultMsg = `${field.fieldName} 有文件未上传完成`;
      }

      break;
    }

    // 不允许为空
    case FieldType.SELECT_MULTI:
    case FieldType.CHECKBOX: {
      if (
        isRequired &&
        (!value || !value.optionKeyList || value.optionKeyList.length === 0)
      ) {
        resultMsg = getRequiredMessage(field);
      }
      break;
    }

    case FieldType.PERCENT: {
      //百分比不校验位数
      if (isRequired) {
        const isEmpty = !value || !value.doubleValue;
        if (isEmpty) {
          resultMsg = getRequiredMessage(field);
        }
      }
      break;
    }
    // double
    case FieldType.MONEY:
    case FieldType.NUMBER: {
      if (isRequired) {
        const isEmpty = !value || !value.doubleValue;
        if (isEmpty) {
          return getRequiredMessage(field);
        }
        const { decimalLen = 0 } = field.fieldDetail || {};
        const [integer, decimal] = value?.doubleValue?.split('.') || [];
        // if (integer && integer.length > integerLen) {
        //   resultMsg = `${field.fieldName}的整数位数不能超过${integerLen}位`;
        //   return;
        // }
        if (decimal && decimal.length > decimalLen) {
          resultMsg = `${field.fieldName}的小数位数不能超过${decimalLen}位`;
        }
      }
      break;
    }
    // long
    case FieldType.DATE:
    case FieldType.DATE_TIME: {
      if (isRequired && (!value || !value.timeValue)) {
        resultMsg = getRequiredMessage(field);
      }
      break;
    }
    case FieldType.TELEPHONE: {
      if (isRequired && (!value || !value.countryCode || !value.telephone)) {
        resultMsg = getRequiredMessage(field);
      }
      break;
    }

    // case FieldType.TEXT_COMPOSITE: {
    //   if (isRequired && (!value || !value.optionKey || !value.stringValue)) {
    //     resultMsg = getRequiredMessage(field);
    //   }
    //   break;
    // }

    // case FieldType.BUSINESS_PROCESS: {
    //   if (isRequired && (!value || !value.processId)) {
    //     resultMsg = getRequiredMessage(field);
    //   }

    //   if (
    //     !!value &&
    //     !!value.processId &&
    //     !value.processingPhaseIndex &&
    //     value.processingPhaseIndex !== 0 &&
    //     !value.overPhaseIndex &&
    //     value.overPhaseIndex !== 0
    //   ) {
    //     resultMsg = '请完整选择商机阶段';
    //   }
    //   break;
    // }

    // case FieldType.POOL: {
    //   if (isRequired && (!value || !value.poolId)) {
    //     resultMsg = getRequiredMessage(field);
    //   }
    //   break;
    // }
    // case FieldType.TEXT_FUNCTION: {
    //   if (isRequired && (!value || !value.stringValue)) {
    //     resultMsg = getRequiredMessage(field);
    //   }
    //   break;
    // }
    // case FieldType.SUB_TABLE: {
    //   if (
    //     isRequired &&
    //     (!value || !value.subDataDTOS || value.subDataDTOS.length === 0)
    //   ) {
    //     resultMsg = getRequiredMessage(field);
    //   }
    //   break;
    // }

    default: {
      if (isRequired && !value) {
        resultMsg = getRequiredMessage(field);
      }
      break;
    }
  }

  return resultMsg;
}

export default validateValue;

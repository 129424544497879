import React from 'react';
import { baseEntry } from './baseEntry';
import { Router } from './router';
import { runnerRoutes } from './router/runner';
import DetailPage from './layout/DetailPage';
import RunnerPage from './layout/RunnerPage';
import FormPage from './layout/FormPage';
import SystemPage from './layout/SystemPage';
import { env } from './terminal/env';
import ListPage from './layout/ListPage';
import { useOutlet } from 'react-router-dom';

const Layout: React.FC<{ route: any }> = ({ route }) => {
  return useOutlet();

  if (route.name === 'create') {
    return (
      <SystemPage>
        <FormPage>{route.element}</FormPage>
      </SystemPage>
    );
  }
  if (route.name === 'result') return route.element;
  if (route.name === 'detail') return <DetailPage>{route.element}</DetailPage>;
  if (route.name === 'list') return <ListPage>{route.element}</ListPage>;
  return (
    <RunnerPage scroll={route.name !== 'portal'} isNotAuth={route.isNotAuth}>
      {route.element}
    </RunnerPage>
  );
};

baseEntry({
  bootstrap() {
    return <Router routes={runnerRoutes}></Router>;
  },
});

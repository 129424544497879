import { IDxpEditor } from '@dxp-declare';
import { EditorStore } from '@dxp-hooks/storeContent';
import RunnerComponent from '@dxp-components/runnerComponent';
import DxpSetup from '@dxp-runner/common/setup';
import {
  FieldDetail,
  FieldValue,
  PageMode,
  useDataId,
  useFieldEditEnableSetup,
  useObject,
} from '@dxp-presets/context';
import { Suspense, useMemo } from 'react';
import { createContainerComponent } from '@dxp-utils/createComponent';
import { createComponentId } from '@dxp-utils/createComponentId';
import { useRunnerMeta } from '@dxp-hooks/useRunnerMeta';
import { RunnerStore } from '@dxp-store/runnerValueStore';
import { FieldDetailStore } from '@dxp-store/fieldDetailStore';
import useScreen from '@gaker/hooks/useScreen';
import { useLoading } from '@dxp-hooks/useLoading';
import emitter from '@dxp-utils/emitter';
import isSubTable from '@dxp-utils/isSubTable';

const detailComponentId = createComponentId('container_abstract');

const DxpCanvas: React.FC<{
  config: IDxpEditor.IEditor;
}> = ({ config, ...props }) => {
  const screen = useScreen();
  const { objectApiName } = useObject();
  const dataId = useDataId();
  const { data, meta, loading, refresh } = useRunnerMeta({
    objectApiName,
    config,
    dataId: dataId,
    pageSize: 1,
  });
  const setup = useFieldEditEnableSetup();
  const {
    layout: { type },
  } = config.setting;
  const components = useMemo(() => {
    //type === 'vertical:from.flex-list.flex' ||
    if (screen.width < 720) {
      const [form, list1, list2] = config.components.map((v) => {
        return JSON.parse(JSON.stringify(v)); // toddo 先这么写
      });
      if (list2) {
        list1.children = [...list1.children, ...list2.children];
      }
      list1.config.width = 'flex.1'; // 可能存在列表栏固宽场景，在单列下，宽度需要修改为100%
      if (form.children.length > 0) {
        const detailComponent = createContainerComponent(
          {
            abstract: true,
            direction: 'vertical',
            isDetail: true,
          },
          form.children
        );
        detailComponent.id = detailComponentId;
        list1.children = [detailComponent, ...list1.children];
        // return [list1];
      }
      return [list1];
    }
    if (screen.width < 1280) {
      if (config.components.length < 3) return config.components.slice();
      const [form, list1, list2] = config.components.map((v) => ({ ...v }));
      list1.children = list1.children.concat(list2.children);
      return [form, list1];
    }
    return config.components.slice();
  }, [screen.width, type, config.components]);

  const valueStore = useMemo(() => {
    if (!isSubTable(objectApiName)) {
      setup(data?.content?.[0] || {}, config.setting.editAuth);
    }
    return new RunnerStore(objectApiName, data?.content?.[0] || {});
  }, [objectApiName, data, config]);

  const metaStore = useMemo(() => {
    return new FieldDetailStore(objectApiName, meta || {});
  }, [objectApiName, meta]);
  const [context] = useLoading(loading);
  emitter.useEmitter(
    `detail-refresh-${objectApiName}-${dataId}`,
    () => {
      refresh({});
    },
    [objectApiName, dataId, refresh]
  );
  if (!meta) return <>{context}</>;
  // @mark key={valueStore.id} 当详情数据发生变更以后，富文本字段ID不会发生变更导致不刷新
  return (
    <PageMode value={'view'} key={valueStore.id}>
      <EditorStore config={config} objectApiName={objectApiName}>
        <FieldValue value={valueStore}>
          <FieldDetail value={metaStore}>
            <DxpSetup
              mode="view"
              config={config}
              titleBar={(component) => {
                return (
                  <Suspense>
                    <RunnerComponent.Component
                      component={component}
                    ></RunnerComponent.Component>
                  </Suspense>
                );
              }}
            >
              <Suspense>
                <RunnerComponent.List
                  components={components}
                ></RunnerComponent.List>
              </Suspense>
            </DxpSetup>
          </FieldDetail>
        </FieldValue>
      </EditorStore>
    </PageMode>
  );
};

export default DxpCanvas;

import AntdHOC from '../antd';
import IconParkHOC from '../iconPark';
import { ReactThemeHOC, useThemePrimaryColor } from './hook';

export const ThemeHOC = <T extends {}>(
  Component: React.FC<T>,
  options: { antd?: boolean; iconPark?: boolean; antdPrefixCls?: string } = {}
): React.FC<T> => {
  const { antd = true, iconPark = true } = options;
  if (antd) {
    Component = AntdHOC(Component, { prefixCls: options.antdPrefixCls });
  }
  if (iconPark) {
    Component = IconParkHOC(Component);
  }

  return ReactThemeHOC(Component);
};

export { useThemePrimaryColor };

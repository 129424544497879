import React from 'react';
import { RunnerAuth, RunnerObjectName, RunnerSite } from './RunnerPage';
import { HOC } from '@gaker/tools';
import SystemPage from './SystemPage';

const FormPage: React.FN = (props) => {
  return <RunnerObjectName>{props.children}</RunnerObjectName>;
};

export default HOC(RunnerSite)(HOC(RunnerAuth)(HOC(SystemPage)(FormPage)));

import { generate } from '@ant-design/colors';

const DefaultOpacity = (0.6 * 255).toString(16);

export let PrimaryColor = '#13c1b8';

const generateColors = (color: string) => {
  return generate(color).reduce<any>((map, color, index) => {
    map[`brand-${index + 1}`] = color;
    return map;
  }, {});
};

export const generateVar = (name: string) => {
  return '--color-g-' + name;
};

export let BrandColor: {
  'brand-1': string;
  'brand-2': string;
  'brand-3': string;
  'brand-4': string;
  'brand-5': string;
  'brand-6': string;
  'brand-7': string;
  [key: string]: string;
} = generateColors(PrimaryColor);

export const updateBrandColor = (color: string) => {
  PrimaryColor = color;
  BrandColor = generateColors(color);
  return BrandColor;
};

export const NeutralColor = {
  'grey-1': '#F7F8FA',
  'grey-2': '#F2F3F5',
  'grey-3': '#E5E6EB',
  'grey-4': '#C9CDD4',
  'grey-6': '#86909C',
  'grey-8': '#4E5969',
  'grey-10': '#1D2129',
};

export const NeutralHelperColor: Record<
  `${keyof typeof NeutralColor}-6`,
  string
> = Object.keys(NeutralColor).reduce<any>((map, key) => {
  map[key + '-6'] =
    NeutralColor[key as unknown as keyof typeof NeutralColor] + DefaultOpacity;
  return map;
}, {});

export const FunctionalColor = {
  error: '#f53f3f',
  warn: '#ff7d00',
  success: '#00b42a',
  link: '#165dff',
  yellow: '#fadc19',
  cyan: '#13c1b8',
  purple: '#722ed1',
};

export const FunctionalHelperColor: typeof FunctionalColor = Object.keys(
  FunctionalColor
).reduce<any>((map, key) => {
  map[key + '-6'] =
    FunctionalColor[key as unknown as keyof typeof FunctionalColor] +
    DefaultOpacity;
  return map;
}, {});

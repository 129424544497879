/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-09-18 09:25:40
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2023-11-20 17:31:31
 * @FilePath: /gaker/libs/models/getFieldValue/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { getDataValue } from '@saleswork/models/lib/utils/getFieldValue';
import FieldType from '../FieldType';
import { Models } from '@declare-models';

import dayjs from 'dayjs';

interface IField {
  fieldApiName: string;
  fieldName: string;
  fieldType: FieldType;
  valueType: FieldType;
  fieldDetail: any;
}

const map = <T>(datas: T[], iterator: (data: T, index: number) => any) => {
  return (datas || []).map(iterator);
};

export const getFieldValue = (
  data: Models.FieldValue.TFieldValue,
  field: IField,
) => {
  if (!data.value) return;
  const { value, valueType } = data;
  // if (field.fieldApiName === FieldApiName.stateFlowStateField) {
  //   return value;
  // }
  switch (valueType) {
    case FieldType.USER_LIST:
      return map(value.userInfoList, (v) => ({
        avatar: v.photoUrl,
        name: v.userName,
      }));
    case FieldType.CHECKBOX:
    case FieldType.SELECT_MULTI:
      return map(value.optionKeyList, (key, index) => ({
        label: value.optionValueList[index],
        value: key,
      }));
    case FieldType.MONEY:
    case FieldType.NUMBER:
    case FieldType.PERCENT:
      return value.numberValue;
    case FieldType.FILE:
      return value.fileInfoList;
    case FieldType.LOCATION:
      return value.location?.addressName || '';
    case FieldType.DATE_TIME:
      return dayjs(value.timeValue).format('YYYY-MM-DD HH:mm');
  }
  try {
    return getDataValue(value, field.valueType || data.valueType);
  } catch (e) {
    console.error(field, data, e);
    return;
  }
};

export default getFieldValue;

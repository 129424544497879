import { useKeepAliveState } from '@dxp-components/KeepAlive/context';
import { IDxpRunner } from '@dxp-declare';
import { createEmitter } from '@gaker/tools';

export const emitter = createEmitter<IDxpRunner.IEmitterEvent>();

const oldEmitter = emitter.useEmitter;

emitter.useEmitter = (event, handler, deps) => {
  const keepALiveState = useKeepAliveState();
  return oldEmitter.call(
    emitter,
    event,
    function (data) {
      if (!keepALiveState) {
        return Promise.reject();
      }
      return handler(data as any);
    },
    deps
  );
};

export default emitter;

import { IDxpEditor } from '@dxp-declare';
import { createContext } from '@gaker/tools';
import { ValueBaseStore } from '../store/baseStore';
import { FieldApiName, FieldType } from '@gaker/models';
import { Models } from '@declare-models';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FieldDetailStore } from '@dxp-store/fieldDetailStore';
import { RunnerStore } from '@dxp-store/runnerValueStore';
const [useViewMode, ViewMode] = createContext(
  'computer' as IDxpEditor.Types.TDeviceType,
  (v) => v
);

export { useViewMode, ViewMode };

const [useFieldLayout, FieldLayout] = createContext(
  'field.left-value.left' as IDxpEditor.Types.TBarFiledLayoutType,
  (v) => v
);

export { useFieldLayout, FieldLayout };

const [useHideFileName, HideFieldName] = createContext(false, (v) => v);

export { useHideFileName, HideFieldName };

//字段值获取
const [useFieldValue, FieldValue, _, useFieldValueStore] = createContext<
  ValueBaseStore,
  IDxpEditor.Models.IFieldVO,
  (value: ValueBaseStore, key: IDxpEditor.Models.IFieldVO) => any
>(new ValueBaseStore(), (store, field) => {
  return store.get(field);
});

export { useFieldValue, FieldValue };

// 字段详情获取
const detailContext = createContext<
  ValueBaseStore,
  IDxpEditor.Models.IFieldVO,
  <Type extends keyof Models.FieldDetail.TFieldDetailMap>(
    value: ValueBaseStore,
    key: {
      fieldType: FieldType;
      valueType: Type;
      fieldApiName: string;
      fieldName: string;
      fieldDetail: any;
    }
  ) => Models.FieldDetail.TFieldDetailMap[Type]
>(new ValueBaseStore(), (store, field) => store.get(field));

export const useFieldDetail = <
  Type extends keyof Models.FieldDetail.TFieldDetailMap
>(field: {
  valueType: Type;
  fieldApiName: string;
}): Models.FieldDetail.TFieldDetailMap[Type] => {
  return detailContext[0](field as any) as any;
};

export const useFieldDetailOrigin = () => {
  return detailContext[3]();
};

export const FieldDetail = detailContext[1];

//过滤条件的字段
const [useListOption, ListOption] = createContext('', (v) => v);

export { useListOption, ListOption };

//列表显示方式
export const [useListDisplay, ListDisplay] = createContext(
  'tile' as IDxpEditor.Types.TListDisplayType,
  (v) => v
);

//是否需要边框显示的上下文
export const [usePaddingLayout, PaddingLayout] = createContext(true, (v) => v);

export const [useContextEnv, ContextEnv] = createContext(
  'container' as
    | 'container'
    | 'topbar'
    | 'columbar'
    | 'list'
    | 'canvas'
    | 'home',
  (v) => v
);

// 编辑器当前操作对象状态维护
export const [useObject, ObjectContext, useObjectUpdate] = createContext(
  { objectApiName: '', objectName: '' },
  (v) => v
);

// 运行器当前主数据ID
export const [useDataId, DataIdContext] = createContext('', (v) => v);

// 编辑对象是详情页面还是新增页面
export const [usePageMode, PageMode, usePageModeUpdate] = createContext(
  'view' as IDxpEditor.Types.TPageMode,
  (v) => v
);

// 当前门户类型
const [_useDoorType, DoorTypeProvider] = createContext(
  '' as IDxpEditor.Types.TDoorType,
  (v) => v
);
const useDoorType = () => {
  const type = _useDoorType();
  if (!type) throw new Error('door type not defined!');
  return type;
};
export { DoorTypeProvider, useDoorType };

export const useStatusValue = () => {
  const statusField: any = {
    fieldApiName: FieldApiName.approvalStateField,
    fieldName: '状态',
    fieldType: FieldType.SELECT_SINGLE,
    valueType: FieldType.SELECT_SINGLE,
  };
  const detail = useFieldDetail({
    fieldApiName: FieldApiName.approvalStateField,
    valueType: FieldType.SELECT_SINGLE,
  });
  const approvalValue: Models.FieldValue.TSelectSingleValue['value'] =
    useFieldValue(statusField);
  if (
    approvalValue?.optionKey &&
    approvalValue?.optionKey === Models.Define.ApprovalStateEnum.Approval
  )
    return {
      value: approvalValue,
      optionList: detail?.optionList || [],
    };
  const statusDetail = useFieldDetail({
    fieldApiName: FieldApiName.stateFlowStateField,
    valueType: FieldType.SELECT_SINGLE,
  });

  const statusValue: Models.FieldValue.TSelectSingleValue['value'] =
    useFieldValue({
      ...statusField,
      fieldApiName: FieldApiName.stateFlowStateField,
    });

  return { value: statusValue, optionList: statusDetail?.optionList || [] };
};

//列表栏下显示是否有背景边框
export const [useBorderBg, BorderBgProvider] = createContext(false, (v) => v);

// 列表栏是否显示标题

export const [useListTitleVisible, ListTitleVisibleProvider] = createContext(
  true,
  (v) => v
);

export const [
  useFieldEditEnable,
  FieldEditEnableProvider,
  useFieldEditEnableSetup,
  useFieldEditAuthList,
] = (() => {
  const FieldEditEnableContext = React.createContext<{
    config?: IDxpEditor.IEditor['setting']['editAuth'];
    whiteList?: string[];
    setup(data: any, config?: IDxpEditor.IEditor['setting']['editAuth']): void;
  }>({ config: [], setup() {} });
  const use = (field: Models.Field.TField) => {
    const { whiteList } = React.useContext(FieldEditEnableContext);
    if (!field.fieldApiName) return false;
    if (!whiteList) return true;
    return whiteList.includes(field.fieldApiName);
  };
  const Provider: React.FN<{
    config?: IDxpEditor.IEditor['setting']['editAuth'];
  }> = (props) => {
    const [editEnableFieldList, setEnableFieldList] = useState<string[]>();
    const [config, setConfig] =
      useState<IDxpEditor.IEditor['setting']['editAuth']>();
    const [data, setData] = useState();
    useEffect(() => {
      setConfig(props.config);
    }, [props.config]);
    const auth = useMemo(() => {
      if (!config) return () => [];
      const authList = config.map((item) => {
        if (!item.condition?.length || !item.controlFields?.length)
          return () => [];
        return ((item) => {
          const authList = item.condition.map((condition) => {
            return (data: any) => {
              const value: Models.FieldValue.TSelectSingleValue['value'] =
                data[condition.fieldApiName]; // @todo 此处只有流程的先写死
              if (!condition.values?.optionKeyList) return true;
              if (!value || !value.optionKey)
                return condition.values.optionKeyList.some(
                  (value) => value === ''
                );
              return condition.values.optionKeyList.includes(value.optionKey);
            };
          });
          return (data: any) => {
            if (authList.every((auth) => auth(data)))
              return item.controlFields || [];
            return [] as string[];
          };
        })(item);
      });
      return (data: any) => {
        return authList.reduce<string[]>((enableFieldApiNameList, auth) => {
          return enableFieldApiNameList.concat(auth(data));
        }, []);
      };
    }, [config]);
    useEffect(() => {
      if (!data) return;
      setEnableFieldList(auth(data));
    }, [auth, data]);
    return React.createElement(
      FieldEditEnableContext.Provider,
      {
        value: {
          config: props.config,
          setup(data, config) {
            setData(data);
            if (config) {
              setConfig(config);
            }
          },
          whiteList: editEnableFieldList,
        },
      },
      props.children
    );
  };
  return [
    use,
    Provider,
    () => {
      const { setup } = React.useContext(FieldEditEnableContext);
      return setup;
    },
    () => {
      const { whiteList } = React.useContext(FieldEditEnableContext);
      return whiteList;
    },
  ];
})();

import EventEmitter from 'events';
import { useEffect } from 'react';

export enum DndEmitKesEnum {
  DragStart = 'drag-start',
  DragEnd = 'drag-end',
}

export const dndEmitter = new EventEmitter();

interface IEvent {
  (event: 'drag-start', handler?: (evt: DragEvent) => void, deps?: any[]): void;
  (event: 'drag-end', handler?: () => void, deps?: any[]): void;
}

export const useDndEvent: IEvent = (event, handler, deps = []) => {
  useEffect(() => {
    if (!handler) return () => {};
    dndEmitter.on(event, handler);
    return () => {
      dndEmitter.off(event, handler);
    };
  }, [event, handler, ...deps]);
};

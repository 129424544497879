import { useCallback, useEffect, useRef } from 'react';

export const useScroll = (events: {
  scroll?(): void;
  scrollBottom?(): void;
  scrollTop?(): void;
}) => {
  const elRef = useRef<HTMLElement>();

  const onScroll = useCallback(
    (e: any) => {
      const el = e.target;
      if (!el) return;
      events.scroll?.();
      if (el.scrollTop <= 0) events.scrollTop?.();
      if (Math.ceil(el.scrollTop + el.offsetHeight) >= el.scrollHeight) {
        events.scrollBottom?.();
      }
    },
    [events.scroll, events.scrollBottom, events.scrollTop]
  );

  const onRef = (el: HTMLElement | undefined | null) => {
    if (!el) return;
    if (elRef.current) {
      elRef.current.removeEventListener('scroll', onScroll);
    }
    elRef.current = el;
    el.addEventListener('scroll', onScroll);
  };
  useEffect(() => {
    if (elRef.current) {
      elRef.current.addEventListener('scroll', onScroll);
    }
    return () => {
      if (elRef.current) {
        elRef.current.removeEventListener('scroll', onScroll);
        elRef.current = undefined;
      }
    };
  }, [onScroll]);

  return [onRef];
};

export default useScroll;

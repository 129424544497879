/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2022-04-06 15:48:16
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2023-06-27 18:20:39
 * @FilePath: /saleswork-webapp/src/common/utils/hooks/useQueryParams.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useMemo } from 'react';

/** 解析search query */
export function parseQueryParams(search: string) {
  // remove ?
  const val = search.replace('?', '');
  const queryItems = val.split('&');
  const queryParams: Record<string, any> = {};
  queryItems.forEach((it) => {
    const [key = '', val = ''] = it.split('=');
    queryParams[key] = val;
  });
  return queryParams;
}

/** 解析search query */
export default function useQueryParams<T = Record<string, any>>() {
  const search = window.location.href.split('?')[1] || '';
  return useMemo(() => parseQueryParams(decodeURI(search)), [search]) as T;
}

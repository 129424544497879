import React, { Suspense, useEffect, useMemo, useState } from 'react';
import List, { IListProps } from '../list/List';
import { useParams } from 'react-router-dom';
import { createComponentId } from '@dxp-utils/createComponentId';
import { IDxpEditor } from '@dxp-declare';
import { useCurrentData } from '@dxp-utils/currentData';
import useEditorStore from '@dxp-hooks/storeContent';
import { useDetailPageInfoUpdate } from '@dxp-hooks/useDetailPageName';
import { ContextEnv, ListTitleVisibleProvider } from '@dxp-presets/context';
import { useShow } from '@dxp-components/KeepAlive/context';
import emitter from '@dxp-utils/emitter';

const PresetObjectList = ['productObject'];

const AsyncTitle: React.FC = () => {
  const store = useEditorStore();
  const update = useDetailPageInfoUpdate();
  useEffect(() => {
    if (!store.setting.name) return;
    update({ name: store.setting.name });
  }, [store?.setting?.name]);
  return null;
};

export const ListMain: React.FC<
  { objectApiName: string } & Partial<IListProps>
> = ({ objectApiName, ...props }) => {
  const currentData = useCurrentData();

  const component = useMemo<IDxpEditor.Components.TListComponent>(() => {
    return {
      id: createComponentId('list'),
      type: 'list',
      config: {
        objectName: '',
        objectId: objectApiName!,
        width: 'flex.1',
      },
    };
  }, [objectApiName]);

  useShow(() => {
    emitter.emit(`list-force-refresh-${objectApiName}`, {});
  });

  //
  return (
    <ContextEnv value="home">
      <List
        component={component}
        associateDataId={
          PresetObjectList.includes(objectApiName!)
            ? undefined
            : currentData?.dataId
        }
        objectApiName={currentData?.objectApiName}
        {...props}
      >
        <AsyncTitle></AsyncTitle>
      </List>
    </ContextEnv>
  );
};

const ListIndex: React.FC = () => {
  const params = useParams();

  return (
    <ListTitleVisibleProvider value={false}>
      <ListMain objectApiName={params.objectApiName!}></ListMain>
    </ListTitleVisibleProvider>
  );
};

export default ListIndex;

/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-09-18 09:25:40
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2024-04-16 19:07:45
 * @FilePath: /gaker/apps/gaker-dxp/src/utils/request.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createHttpCtrl } from '@gaker/tools';
import { message } from 'antd';
import { clearToken, getTokenHeader } from './token';
import { Cookies } from '@gaker/models';
import { specialErrorCode } from '@saleswork/models';

const request = createHttpCtrl('');

request.useRequestInterCeptor((request) => {
  Object.assign(request.headers, getTokenHeader());
  return request;
});

request.useResponseInterCeptor(
  (response) => {
    if (!response.data.result) {
      if (response.data.mcode !== 'DXP_PORTAL_00000100') {
        message.error(response.data.message);
      }
      const presetError = specialErrorCode[response.data.mcode];
      if (presetError) {
        const { toast, action, message: msg } = presetError;
        const text = msg || response.data.message;
        if (toast && text) {
          message.error(text);
        }
        action?.(true, response.data);
      }
      if (
        response.data.mcode === 'DXP_PORTAL_00000010' ||
        response.data.mcode === 'DXP_PORTAL_00000011' ||
        response.data.mcode === 'DXP_PORTAL_00000012'
      ) {
        window.location.href =
          window.location.pathname.replace(/^\//, '').split('/')[0] + '/login';
      }
    }
    return response;
  },
  (e) => {
    console.error(e);
  }
);

export interface IResponse<T> {
  mcode: string;
  message: string;
  result: boolean;
  data: T;
}

export const getCreator = <Result, Params extends Record<string, any>>(
  url: string
) => {
  const handler = request.getCreator<IResponse<Result>, Params>(url);
  (handler as any).__request__url = url; // todo 测试使用
  return handler;
};

export const postCreator = <Result, Params extends Record<string, any>>(
  url: string
) => {
  return request.postCreator<IResponse<Result>, Params>(url);
};

export const post = request.post.bind(request);
export const get = request.get.bind(request);

/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-09-22 14:46:01
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2024-04-15 23:48:20
 * @FilePath: /gaker/libs/hooks/useRequest/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { storage } from '@gaker/tools';
import { useCallback, useEffect, useRef, useState } from 'react';

type Service<TData, TParams extends any[]> = (
  ...args: TParams
) => Promise<{ mcode: string; message: string; result: boolean; data: TData }>;

export const useRequest = <TData, TParams extends any[]>(
  service: Service<TData, TParams>,
  options?: Partial<{
    defaultParams: TParams;
    defaultLoaded: boolean;
    cacheKey?: string;
  }>
) => {
  const [data, setData] = useState<null | TData>(null);
  const [loading, setLoading] = useState<boolean>(
    !(options?.defaultLoaded === false)
  );
  const refresh: any = useCallback(
    (...params: TParams) => {
      /**缓存逻辑*/
      if (options?.cacheKey) {
        const cache = storage.get(options?.cacheKey);
        if (cache) {
          setData(cache as TData);
          return;
        }
      }
      setLoading(true);
      return service(...params)
        .then((res) => {
          if (res?.result) {
            setData(res.data);
            /**缓存逻辑*/
            if (options?.cacheKey) {
              storage.set(options?.cacheKey, res.data);
            }
            return res.data;
          }
        })
        .finally(() => setLoading(false));
    },
    [service]
  );
  useEffect(() => {
    if (options?.defaultLoaded === false) return;
    refresh(...(options?.defaultParams || ([] as any)));
  }, []);
  return { data, refresh, loading };
};

export default useRequest;

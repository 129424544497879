import { IDxpEditor } from '@dxp-declare';
import { ColumnType } from 'antd/es/table';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

export const renderMap: Partial<
  Record<keyof IDxpEditor.Models.IMemberUser, (value: any) => React.ReactNode>
> = {
  lastLoginTime: (v) => dayjs(v).format('YYYY-MM-DD HH:mm'),
  activeTime: (v) => dayjs(v).format('YYYY-MM-DD HH:mm'),
  accountStatus: (value) => (
    <div
      style={{}}
      className={classNames(
        'text-g-normal h-24px p-g-s p-l-g-l p-r-g-l rd-24px b-1 border-solid border-g-brand-6 flex flex-center text-g-brand-6'
      )}
    >
      {value === 'enabled' ? '启用' : '禁用'}
    </div>
  ),
};

export const useUserTableColumns = (
  fields: { dataIndex: keyof IDxpEditor.Models.IMemberUser; label: string }[]
) => {
  const columns = useMemo(() => {
    const columns = fields.map<ColumnType<any>>((field) => {
      return {
        title: <span className=" text-g-grey-6">{field.label}</span>,
        dataIndex: field.dataIndex,
        render: renderMap[field.dataIndex] || ((v) => v),
      };
    });
    return columns;
  }, [fields]);

  return columns;
};

export default useUserTableColumns;

/// 请求工具
/// 当Hooks依赖发生变更时，自动发起请求

import { useCallback, useEffect } from 'react';
import useRequest from '../useRequest';

type Service<TData, TParams extends any[]> = (
  ...args: TParams
) => Promise<{ mcode: string; message: string; result: boolean; data: TData }>;

const BlockRequest = Symbol('BlockRequest');

export const useEffectRequest = <TData, TParams extends any[]>(
  service: Service<TData, TParams>,
  params: () => TParams[0] | typeof BlockRequest, // 当返回 useEffectRequest.BlockRequest时，当前请求被阻断
  deps: React.DependencyList
) => {
  const { data, refresh } = useRequest<TData, TParams>(service, {
    defaultLoaded: false,
  });

  const getParams = useCallback(params, deps);

  useEffect(() => {
    const params: any = getParams();
    if (BlockRequest === params) return;
    refresh(...([params] as any));
  }, [getParams]);
  return { data, refresh };
};

useEffectRequest.BlockRequest = BlockRequest;

export default useEffectRequest;

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const DXP_DATA_KEY = 'dxp_data_key';

const Key = (domain: string) => DXP_DATA_KEY + '-' + domain;

interface ICurrentData {
  domain: string;
  objectApiName: string;
  dataId: string;
}

export const setCurrentData = (domain: string, data: ICurrentData) => {
  localStorage.setItem(Key(domain), JSON.stringify(data));
};

export const getCurrentData = (domain: string): ICurrentData | undefined => {
  const data = localStorage.getItem(Key(domain));
  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      console.warn('getCurrentData.error', data);
    }
  }
};

export const useCurrentData = () => {
  const { domain } = useParams<{ domain: string }>();
  return useMemo(() => getCurrentData(domain!), [domain]);
};
export const clearCurrentData = (domain: string) => {
  localStorage.removeItem(Key(domain));
};

import ListFilter from '@dxp-components/listFilter';
import { ListOption } from '@dxp-presets/context';
import React, { useCallback, useEffect } from 'react';
import { FieldApiName, getFieldValue } from '@gaker/models';
import { Button, Table } from 'antd';
import { useRequest, useScroll } from '@gaker/hooks';
import { getUserList } from '@dxp-api/index';
import useScreen from '@gaker/hooks/useScreen';
import { Dialog } from '@gaker/gaker-ui';
import UserInvite from './invite';
import { useLoading } from '@dxp-hooks/useLoading';
import { IDxpEditor } from '@dxp-declare';
import useUserTableColumns, { renderMap } from './hooks/useUserTableColumns';
import UserConstants from './Constants';
import AvatarView from './components/AvatarView';
import StatusView from './components/StatusView';
import { useCurrentData } from '@dxp-utils/currentData';
import { DefaultAvatar } from '@gaker/components';
const userColumns: Array<{
  label: string;
  dataIndex: keyof IDxpEditor.Models.IMemberUser;
}> = [
  {
    label: '姓名',
    dataIndex: 'userName',
  },
  {
    label: '用户ID',
    dataIndex: 'memberId',
  },
  {
    label: '电话号码',
    dataIndex: 'telephone',
  },
  {
    dataIndex: 'activeTime',
    label: '激活时间',
  },
  {
    dataIndex: 'lastLoginTime',
    label: '最后登录时间',
  },
  {
    label: '帐户状态',
    dataIndex: 'accountStatus',
  },
];

const CardList: React.FC<{
  data: IDxpEditor.Models.IMemberUser[];
}> = ({ data }) => {
  const otherFields = userColumns.filter((field) => {
    return ![
      UserConstants.NameFieldApiName,
      UserConstants.StatusFieldApiName,
    ].includes(field.dataIndex as any);
  });

  return (
    <div className="flex flex-col gap-g-xl">
      {data.map((data) => {
        return (
          <div
            key={data.userId}
            className="bg-white rd-g-normal p-g-l flex gap-g-l"
          >
            <DefaultAvatar title={data.userName}></DefaultAvatar>
            <div className="flex flex-col gap-g-s flex-1">
              <div className="flex justify-between">
                <span className="text-g-normal-bold">{data.userName}</span>
                {renderMap.accountStatus!(data.accountStatus)}
              </div>
              {otherFields.map((f) => {
                const render = renderMap[f.dataIndex] || ((v) => v);
                return (
                  <div key={f.dataIndex as any} className="text-g-small">
                    <span className="text-g-grey-6 inline-block w-100px">
                      {f.label}
                    </span>
                    <span>{render(data[f.dataIndex])}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TableList: React.FC<{
  data: any[];
}> = ({ data }) => {
  const columns = useUserTableColumns(userColumns);
  return (
    <Table
      size="small"
      pagination={false}
      rowKey={(data) => {
        return data.dataId;
      }}
      bordered
      dataSource={data}
      columns={columns}
    ></Table>
  );
};

const UserList: React.FC<{ hideInvite?: boolean }> = (props) => {
  const currentData = useCurrentData();

  const { data, refresh, loading } = useRequest(getUserList, {
    defaultLoaded: false,
  });
  const screen = useScreen();

  const [instance] = Dialog.useDialog();

  useEffect(() => {
    if (!currentData?.dataId) return;
    refresh({
      dataId: currentData.dataId,
      sort: 'createTime|desc',
    });
  }, [currentData?.dataId]);

  const [loadingContext] = useLoading(loading);

  const [ref] = useScroll({
    scrollBottom() {
      // next();
    },
  });

  return (
    <div className="p-g-l size-full overflow-auto" ref={ref}>
      {loadingContext}
      <div className="text-g-middle-bold  m-b-g-xl h-32px flex items-center justify-between">
        成员
        {!props.hideInvite && (
          <div className="flex justify-end m-b-g-s">
            <Dialog
              width={400}
              instance={instance}
              content={<UserInvite onClose={() => instance.hide()} />}
            >
              <Button
                size="small"
                shape="round"
                type="primary"
                className="m-l-g-l"
                onClick={() => instance.show()}
              >
                邀请
              </Button>
            </Dialog>
          </div>
        )}
      </div>
      <ListOption value={UserConstants.StatusFieldApiName}>
        {/* <ListFilter filterSlot={} onFilter={onFilter}></ListFilter> */}
        {screen.width < 720 ? (
          <CardList data={data || []}></CardList>
        ) : (
          <TableList data={data || []}></TableList>
        )}
      </ListOption>
      {/* <More more={hasMore}></More> */}
    </div>
  );
};

export default UserList;

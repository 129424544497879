/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-08-25 14:57:45
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2023-11-15 14:00:59
 * @FilePath: /gaker/libs/components/FieldForm/useStateValue.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useEffect, useState } from 'react';

export const useStateValue = <T, R>(
  originValue: T,
  getter: (value: T) => R
): { value: R; onChange(value: R): void } => {
  const [value, setValue] = useState(() => getter(originValue));
  useEffect(() => {
    setValue(() => {
      return getter(originValue);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originValue]);
  return {
    value,
    onChange(value) {
      setValue(value);
    },
  };
};

export default useStateValue;

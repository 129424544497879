import Cookie from 'js-cookie';
import { Cookies } from '@gaker/models';
import { basename } from './basename';

const isClient = basename === '';

const DXP_TOKEN_KEY = (() => {
  const [domain] = window.location.pathname.replace(/^\//, '').split('/');
  return `${domain}-dxp-token`;
})();

export const setToken = (token: string) => {
  Cookie.set(DXP_TOKEN_KEY, token);
};

export const getToken = () => {
  console.log(
    '%c [ isClient ]-14-「utils/token.ts」',
    'font-size:13px; background:pink; color:#bf2c9f;',
    isClient
  );
  return isClient ? Cookie.get(DXP_TOKEN_KEY) || '' : Cookies.getToken();
};

export const clearToken = () => {
  if (isClient) Cookie.remove(DXP_TOKEN_KEY);
  else Cookies.removeToken();
};

export const getTokenKey = () => {
  return isClient ? 'x-dxp-token' : 'X-Api-Token';
};

export const getTokenHeader = () => {
  return { [getTokenKey()]: getToken() };
};

import { useEffect, useRef, useState } from 'react';

export const useIntersectionObserver = (
  callback: () => void,
  params?: {
    threshold?: number | number[];
  }
) => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  const [observer] = useState(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          if (entry.intersectionRatio > 0) {
            callbackRef.current();
          }
        },
        {
          threshold: params?.threshold,
        }
      )
  );
  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, [observer]);
  return [
    (el: HTMLElement | null) => {
      if (!el) return;
      observer.observe(el);
    },
  ];
};

export default useIntersectionObserver;

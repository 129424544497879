import axios, { AxiosInstance, CancelTokenSource } from 'axios';
const CancelToken = axios.CancelToken;

interface IRequestConfig {
  cancelTokenSource?: CancelTokenSource;
}
type TCanceler = (reason?: string) => void;

type IMethod = ReturnType<typeof createMethods>[0];

interface TCancelCreator {
  <
    T extends (
      url: string,
      params?: any,
      config?: IRequestConfig
    ) => ReturnType<IMethod>
  >(
    request: T
  ): (...args: Parameters<T>) => [ReturnType<T>, TCanceler];
  <T extends (params?: any, config?: IRequestConfig) => ReturnType<IMethod>>(
    request: T
  ): (...args: Parameters<T>) => [ReturnType<T>, TCanceler];
}

const createMethods = (
  inst: AxiosInstance,
  requestQueue: CancelTokenSource[]
) => {
  return ['get', 'post'].map((method) => {
    return <D = any, P extends Record<string, any> = any>(
      url: string,
      params?: P,
      config?: IRequestConfig
    ) => {
      const source = config?.cancelTokenSource || CancelToken.source();
      delete config?.cancelTokenSource;
      const requestConfig = {
        [method === 'get' ? 'params' : 'data']: params,
      };
      requestQueue.push(source);

      const requestUrl = url.replace(/\{([a-zA-Z]+)\}/g, (_match, key) => {
        if (!params) return key;
        const value = params[key] || key;
        /**兼容不删除的场景*/
        if (!params['saveQuery']) {
          delete params[key];
        } else {
          // console.log('params', params);
          delete params['saveQuery'];
        }
        return value;
      });
      return inst
        .request<D>({
          url: requestUrl,
          method: method.toUpperCase() as any,
          cancelToken: source.token,
          ...requestConfig,
          ...(config || {}),
        })
        .then((res) => res?.data);
    };
  });
};

const createAbort = (queue: CancelTokenSource[]): TCanceler => {
  return (reason?: string) => {
    while (queue.length) {
      queue.shift()!.cancel(reason);
    }
  };
};

const creatorFactory =
  (request: IMethod, config1: IRequestConfig = {}) =>
  <D1 = any, P1 extends Record<string, any> = any>(
    url: string,
    config2: IRequestConfig = {}
  ) =>
  (params: P1, config3: IRequestConfig = {}) =>
    request<D1, P1>(
      url,
      params || ({} as any),
      Object.assign({}, config1, config2, config3)
    );

const createAxios = (baseURL: string) => {
  const inst = axios.create({
    baseURL,
  });
  const useResponseInterCeptor = inst.interceptors.response.use.bind(
    inst.interceptors.response
  );
  const useRequestInterCeptor = inst.interceptors.request.use.bind(
    inst.interceptors.request
  );

  return {
    instance: inst,
    useRequestInterCeptor,
    useResponseInterCeptor,
  };
};

const cancelCreatorFactory = (): TCancelCreator => (request: any) => {
  return (...args: any[]) => {
    const source = CancelToken.source();
    const configIndex = typeof args[0] === 'string' ? 2 : 1;
    args[configIndex] = Object.assign({}, args[configIndex] || {}, {
      cancelTokenSource: source,
    });
    return [
      request(...args),
      (reason: string) => {
        source.cancel(reason);
      },
    ] as any;
  };
};

export const createHttpCtrl = (server: string) => {
  const requestQueue: CancelTokenSource[] = [];
  const {
    instance: inst,
    useRequestInterCeptor,
    useResponseInterCeptor,
  } = createAxios(server);
  const abort = createAbort(requestQueue);
  const [get, post] = createMethods(inst, requestQueue);

  const getCreator = creatorFactory(get);
  const postCreator = creatorFactory(post);
  const cancelCreator = cancelCreatorFactory();
  return {
    abort,
    get,
    post,
    cancelCreator,
    getCreator,
    postCreator,
    useRequestInterCeptor,
    useResponseInterCeptor,
  };
};

export default createHttpCtrl;

import React from 'react';
import { Color } from '@gaker/theme';
// todo 后期若有需求 再完善主题相关
export const IconPark = <T extends {}>(Component: React.FC<T>): React.FC<T> => {
  return (props) => (
    <Component fill={Color.NeutralColor['grey-10']} {...props}></Component>
  );
};

export default IconPark;

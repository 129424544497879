import { useState } from 'react';
import { useScreen } from '@gaker/hooks';
import emitter from '@dxp-utils/emitter';
import { Button, Drawer } from 'antd';
import { uuid } from '@gaker/tools';
import { useDataId, usePageMode } from '@dxp-presets/context';
import listener from '@dxp-utils/listener';
import { useRouterRedirect } from '@dxp-hooks/useRouterRedirect';
import useEmitterToListener from '@dxp-hooks/useEmitterToListener';
import { removeDepWindow } from '@dxp-utils/depWindow';
import { Debugger } from '@gaker/components';
import { useCacheStoreManager } from './hooks/useCacheStore';

/**
 * 添加表单逻辑
 * 当为PC端时(此处不能用屏幕尺寸，只能使用设备检测，目前只支持2级弹窗，先不考虑)， 打开Drawer,默认90%宽度，依次递减
 * 当为移动端时，直接打开页面
 * @mark  此逻辑理论上只会在window.top上执行
 */

const useAddForm = (isMaster?: boolean) => {
  const redirect = useRouterRedirect();
  const associateDataId = useDataId();
  const [createList, setCreateList] = useState<
    {
      id: string;
      objectName: string;
      objectApiName: string;
      associateObjectApiName?: string;
      url: string;
      dataId: string;
    }[]
  >([]);
  const mode = usePageMode();
  const screen = useScreen();
  //监听来听下级iframe组件的事件，推送到当前window队列->3
  // useListenerToEmitter('list-add', []);
  listener.useEventListener(
    'list-add',
    (data) => {
      const newData = { ...data };
      if (!('isMaster' in newData)) {
        newData.isMaster = false;
      }
      emitter.emit('list-add', newData).catch(() => {});
    },
    []
  );
  //在iframe中监听当前打开新增表单事件，通过postMessage推送送到上级事件

  // useEmitterToListener('list-add', []);

  useEmitterToListener('object-edit', []);

  emitter.useEmitter(
    'object-edit',
    (data) => {
      emitter.emit('list-add', {
        ...data,
        isEdit: true,
      });
    },
    []
  );

  //3.监听新增表单事件
  emitter.useEmitter(
    'list-add',
    (data) => {
      if (window.parent !== window) {
        // 当在编辑态新增时，只会新增子表单，此时保存子表单直接入库，不需要写回缓存
        if (mode === 'edit') {
          data.isMaster = true;
        }
        listener.postMessage('list-add', data).catch(() => {});
        return;
      } // 事件最终在此处响应，一定是在window.top上去处理drawer
      const {
        objectApiName,
        objectName,
        dataId,
        associateFieldApiName,
        associateObjectApiName,
        isEdit,
        ...other
      } = data;
      const _isMaster = 'isMaster' in other ? other.isMaster : isMaster;
      const proxyNavigate =
        screen.width < 720 //00
          ? undefined
          : (url: string) => {
              // window.open(url); //todo debugger
              setCreateList((list) =>
                list.concat({
                  id: uuid(8, { prefix: objectApiName }),
                  url,
                  objectName: objectName || objectApiName,
                  objectApiName: objectApiName,
                  associateObjectApiName,
                  dataId,
                })
              );
            };
      if (isEdit) {
        return redirect(
          {
            to: 'edit',
            params: { objectApiName, dataId },
            query: {
              associateDataId,
              associateFieldApiName: associateFieldApiName!,
              associateObjectApiName: associateObjectApiName!,
            },
          },
          proxyNavigate
        );
      }
      redirect(
        {
          to: 'create',
          params: {
            objectApiName,
          },
          query: {
            dataId,
            associateDataId,
            associateFieldApiName,
            associateObjectApiName,
            isMaster: _isMaster !== false,
          },
        },
        proxyNavigate
      );
    },
    [screen.width, associateDataId, isMaster, mode]
  );
  //接受保存事件，或取消事件时，关闭窗口
  listener.useEventListener(
    'form-cancel',
    (data) => {
      removeDepWindow(data.dataId);
      setCreateList((list) => {
        return list.filter((item) => item.dataId !== data.dataId);
      });
    },
    []
  );

  return {
    formList: createList,
    remove(id: string) {
      setCreateList((list) => list.filter((item) => item.id !== id));
    },
  };
};

const AddForm: React.FC<{ isMaster?: boolean }> = (props) => {
  const { formList, remove } = useAddForm(props.isMaster);

  const context = formList.map((form, index) => {
    return (
      <Drawer
        key={form.id}
        getContainer={() => {
          if (window.top) return window.top.document.body;
          let parent = window.parent;
          while (parent !== window && window.parent) {
            parent = window.parent;
          }
          return parent.document.body;
        }}
        headerStyle={{ display: 'none' }}
        destroyOnClose={true}
        title={
          <>
            {form.objectName}
            <Debugger>
              <Button
                type="link"
                onClick={() => {
                  window.open(form.url);
                }}
              >
                窗口打开(debugger)[{form.url}]
              </Button>
            </Debugger>
          </>
        }
        width={(2 / 3) * 100 - 5 * index + '%'}
        open={!!form.url}
        // bodyStyle={{ padding: 0, overflow: 'hidden' }}
        styles={{
          body: {
            overflow: 'hidden',
            padding: 0,
          },
        }}
        onClose={() => {
          remove(form.id);
        }}
      >
        <iframe
          id={form.id}
          className="size-full p-0 m-0 border-0"
          src={form.url}
        ></iframe>
        {/* <DxpFormRunner
          objectApiName={form.objectApiName}
          dataId={form.dataId}
          onCancel={() => {
            remove(form.id);
          }}
          onSave={(data) => {
            emitter.emit(`form-save-${form.dataId}`, data);
            console.log(
              '%c [ data ]-96-「form/addForm.tsx」',
              'font-size:13px; background:pink; color:#bf2c9f;',
              data
            );
          }}
        ></DxpFormRunner> */}
      </Drawer>
    );
  });
  return <>{context}</>;
};

export default AddForm;

import { useLoading } from '@dxp-hooks/useLoading';
import React, { useEffect, useRef } from 'react';
import { useLocation, useOutlet } from 'react-router-dom';
import { KeepAliveStateProvider } from './context';

const MAX_CACHE_PAGE_COUNT = 10;

const KeepAlive: React.FC = () => {
  const location = useLocation();
  const child = useOutlet();
  const [_, loading] = useLoading();
  const childrenCacheList = useRef<
    Array<{ key: string; element: React.ReactElement | null }>
  >([]);

  const key = location.pathname;

  useEffect(() => {
    if (/\/login$/.test(key)) {
      childrenCacheList.current.length = 0;
      return;
    }
    const index = childrenCacheList.current.findIndex(
      (item) => item.key === key
    );
    if (index < 0) {
      childrenCacheList.current.push({ key, element: child });
    } else {
      if (index !== childrenCacheList.current.length - 2) {
        childrenCacheList.current.length = 0;
        loading.loading(!loading.isLoading);
        return;
      }
      childrenCacheList.current.splice(index + 1);
    }
    childrenCacheList.current.splice(
      0,
      childrenCacheList.current.length - MAX_CACHE_PAGE_COUNT
    );
    loading.loading(!loading.isLoading);
  }, [key]);
  if (/\/login$/.test(key)) return child;
  if (!childrenCacheList.current.length) return child;
  console.log(
    '%c [ childrenCacheList.current ]-42-「KeepAlive/index.tsx」',
    'font-size:13px; background:pink; color:#bf2c9f;',
    childrenCacheList.current
  );
  return (
    <>
      {childrenCacheList.current.map((item) => {
        return (
          <div
            key={item.key}
            className="size-full"
            style={{
              display: item.key === key ? 'block' : 'none',
            }}
          >
            <KeepAliveStateProvider value={item.key === key}>
              {item.element}
            </KeepAliveStateProvider>
          </div>
        );

        React.createElement(
          'div',
          {
            key: item.key,
            className: 'size-full',
            style: {
              display: item.key === key ? 'block' : 'none',
            },
          },
          React.createElement(
            KeepAliveStateProvider,
            {
              value: item.key === key,
            },
            [item.element]
          )
        );
      })}
    </>
  );
  return React.createElement(
    React.Fragment,
    {},
    childrenCacheList.current.map((item) => {
      return React.createElement(
        'div',
        {
          key: item.key,
          className: 'size-full',
          style: {
            display: item.key === key ? 'block' : 'none',
          },
        },
        React.createElement(
          KeepAliveStateProvider,
          {
            value: item.key === key,
          },
          [item.element]
        )
      );
    })
  );
};

export default KeepAlive;

import { IDxpEditor } from '@dxp-declare';
import { useTopBarNumber } from '@dxp-hooks/useResponsive';
import classNames from 'classnames';
import React, { useMemo, useRef } from 'react';
import './index.less';
import { ContextEnv } from '@dxp-presets/context';
import { ConfigProvider } from 'antd';
import NoMore from '@dxp-components/NoMore';
const DxpSetup: React.FN<{
  config: IDxpEditor.IEditor;
  mode: IDxpEditor.Types.TPageMode;
  onClick?: () => void;
  className?: string;
  titleBar: (
    component: IDxpEditor.Components.TTitleBarComponent
  ) => React.ReactNode;
  bodyRef?: (el: HTMLElement | null) => void;
}> = ({ config, titleBar, ...props }) => {
  const {
    layout: { type: layout, titleBarTop },
  } = config.setting;
  const titleBarComponent = config.titleBar;
  const [topBarColumns, ref] = useTopBarNumber();
  const [_dir] = layout.split(':');

  const isCol = useMemo(() => {
    if (_dir === 'vertical') return true;
    if (topBarColumns < 2) return true;
    return false;
  }, [_dir, topBarColumns]);
  return (
    <ConfigProvider
      theme={{
        inherit: true,
        token:
          props.mode === 'view'
            ? {}
            : {
                controlHeight: 36,
              },
      }}
    >
      <ContextEnv value="canvas">
        <div
          ref={ref}
          className={classNames(
            'size-full flex flex-col overflow-auto bg-gray-1 dxp-setup',
            props.className
          )}
          onClick={props.onClick}
        >
          {/**@mark 当移动端时，默认置顶标题栏**/}
          <div className="p-l-g-xl p-r-g-xl">
            {(titleBarTop || isCol) && titleBar(titleBarComponent)}
          </div>
          <div
            ref={props.bodyRef}
            className={classNames(
              'w-full flex flex-1  p-l-g-xl p-r-g-xl gap-g-xl p-b-g-xl', //
              {
                'flex-col setup-runner-col ': isCol,
                'p-t-g-xl p-b-g-xl': !titleBarTop, // 标题栏未置顶时上下边距
              }
            )}
          >
            {props.children}
          </div>
          {/* {props.mode === 'view' && <NoMore></NoMore>} */}
        </div>
      </ContextEnv>
    </ConfigProvider>
  );
};

export default DxpSetup;

import { useApi } from '@dxp-api/hooks';
import { loadFieldMeta, loadObjectData } from '@dxp-api/index';
import { IDxpEditor } from '@dxp-declare';
import {
  iterateComponent,
  iterateFieldComponent,
} from '@dxp-utils/iterateComponent';
import { useRequest } from '@gaker/hooks';
import { FieldApiName } from '@gaker/models';
import { logger } from '@gaker/tools';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface IRunnerMetaOption {
  objectApiName: string;
  config: IDxpEditor.IEditor;
  dataId?: string;
  pageSize?: number;
}
export const useRunnerMeta = (
  { objectApiName, config, dataId, pageSize }: IRunnerMetaOption,
  defaultLoaded = true
) => {
  const [list, setList] = useState<any[]>([]);
  const cacheParams = useRef<Parameters<typeof loadObjectData>[0]>();
  const dataContext = useRequest(useApi('loadObjectData'), {
    defaultLoaded: false,
  });
  const metaContext = useRequest(loadFieldMeta, {
    defaultLoaded: false,
  });

  // const showStatus = config.titleBar?.config.showStatus;

  const listOption = config.setting.listOption;
  const editAuth = config.setting.editAuth;
  const baseFieldFilters: {
    fieldApiName?: string;
    associateObjectApiName?: string;
    fieldValue: string | string[];
  }[] = [];
  if (config.setting.filter) {
    baseFieldFilters.push(
      ...config.setting.filter.map((v) => {
        return {
          fieldApiName: v.fieldApiName,
          fieldValue: v.values?.optionKeyList || [],
        };
      })
    );
  }
  const fields = useMemo(() => {
    const list: IDxpEditor.Models.IFieldVO[] = [];
    // iterateFieldComponent(config, (component) => {
    // });
    iterateComponent(config, (component) => {
      if (component.type === 'list' && component.config.associateFieldApiName) {
        list.push({
          fieldApiName: component.config.associateFieldApiName,
        } as any); // @mark 此处为了兼容，后续有变动记得修改
      }
      if (component.type === 'field' || component.type === 'title-bar-image') {
        list.push(component.config.field);
      }
    });
    return list;
  }, [config]);

  const fieldApiNames = useMemo(() => {
    const fieldApiNames = fields.map((v) => v.fieldApiName);
    // if (showStatus) {
    //   fieldApiNames.push(FieldApiName.stateFlowStateField);
    //   fieldApiNames.push(FieldApiName.approvalStateField);
    // }
    if (listOption) {
      fieldApiNames.push(listOption);
    }
    if (editAuth?.length) {
      // 如果有编辑权限的配置，请求数据需要带出编辑权限的内容
      editAuth.forEach(({ condition }) => {
        condition?.forEach((cond) => {
          fieldApiNames.push(cond.fieldApiName);
        });
      });
    }
    return fieldApiNames;
  }, [listOption, editAuth]);

  const loadData = useCallback(
    (append = false) => {
      if (!cacheParams.current || !dataContext) return;
      dataContext
        ?.refresh(cacheParams.current)
        .then((data: { content: any }) => {
          if (!data) return data;
          if (append) {
            setList((prev) => prev.concat(data.content || []));
          } else {
            setList(() => data.content || []);
          }
          return data;
        });
    },
    [dataContext?.refresh]
  );

  useEffect(() => {
    if (dataId || defaultLoaded) {
      cacheParams.current = {
        objectApiName,
        fieldApiNames,
        dataId: dataId || '', //'649a593a9efcc73e47d4275d', // '64993929cc196a4bcc4fd49f',
        fieldFilters: baseFieldFilters,
      };
      if (pageSize) {
        cacheParams.current.pageSize = pageSize;
      }
      loadData();
    }
    if (objectApiName) {
      metaContext.refresh({ objectApiName, fieldApiNames: fieldApiNames });
    } else {
      logger.warn('useRunnerMeta objectApiName error; not load');
    }
  }, [objectApiName, dataId, fieldApiNames]);
  const hasMore = dataContext.data
    ? dataContext.data.total > list.length
    : false;
  return {
    list: list,
    hasMore: hasMore,
    data: dataContext.data,
    meta: metaContext.data,
    loading: dataContext.loading || metaContext.loading,
    refresh: useCallback(
      (params: Partial<Parameters<typeof loadObjectData>[0]>) => {
        cacheParams.current = {
          objectApiName: objectApiName,
          dataId: dataId,
          fieldApiNames: fieldApiNames,
          ...params,
          fieldFilters: baseFieldFilters.concat(params.fieldFilters || []),
        };
        if (pageSize) {
          cacheParams.current.pageSize = pageSize;
        }
        loadData();
      },
      [loadData]
    ),
    next() {
      if (!hasMore || dataContext.loading) return;
      Object.assign(cacheParams.current!, {
        pageNum: (cacheParams.current?.pageNum || 1) + 1,
      });
      loadData(true);
    },
    loadData,
  };
};

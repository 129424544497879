import { useSizeObserver } from '@gaker/hooks';
import { useEffect, useMemo, useRef } from 'react';

//解决滚动条屏闪问题
const scrollBarWidth = 15;
const useCirculate = (size: { width: number; height: number }) => {
  const prev = useRef<{ width: number; height: number }>();
  const lastTime = useRef(Date.now());
  const counter = useRef(0);
  useEffect(() => {
    // @mark 如果窗口发生变更时，直接重置计数规则
    const handler = () => {
      counter.current = 0;
      prev.current = undefined;
    };
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('reset', handler);
  }, []);
  const isCirculate = (last: number, current: number) => {
    return (
      Math.abs(last - current) <= scrollBarWidth &&
      Date.now() - lastTime.current > 5000 // 超过5s的时间则重置
    );
  };
  if (!prev.current) {
    prev.current = size;
    return size;
  }
  if (
    isCirculate(size.width, prev.current.width) ||
    isCirculate(size.height, prev.current.height)
  ) {
    if (counter.current > 3) {
      lastTime.current = Date.now();
      return prev.current;
    }
    counter.current++;
  } else {
    counter.current = 0;
  }
  prev.current = size;
  lastTime.current = Date.now();
  return prev.current;
};

const createHook = <R>(
  hookBody: (size: { width: number; height: number }) => R
): ((
  useSizeObserverHook?: typeof useSizeObserver
) => [R, (el: HTMLElement | null) => void, HTMLElement | null | undefined]) => {
  return (useSizeObserverHook = useSizeObserver) => {
    const [size, ref, el] = useSizeObserverHook();
    const circulateSize = useCirculate(size);
    return [hookBody(circulateSize), ref, el];
  };
};

export const useColumnNumber = createHook((size) => {
  return useMemo(() => {
    if (size.width < 720) return 1;
    return 2;
  }, [size.width]);
});

export const useFieldNumber = createHook((size) => {
  return useMemo(() => {
    if (size.width < 720) return 1;
    return 4;
  }, [size.width]);
});

export const useTopBarNumber = useColumnNumber;

export const useTableModel = createHook((size) => {
  return useMemo(() => {
    if (size.width < 720) return 'card';
    return 'table';
  }, [size.width]);
});

export const useListCardNumber = createHook((size) => {
  return useMemo(() => {
    if (size.width < 720) return 1;
    if (size.width < 1024) return 2;
    if (size.width < 1440) return 3;
    if (size.width < 1600) return 4;
    return 5;
  }, [size.width]);
});

export const useListFilterLayout = createHook((size) => {
  return size.width < 720 ? 'col' : 'row';
});

const HookMap = {
  column: useColumnNumber,
  field: useFieldNumber,
  table: useTableModel,
  list: useListCardNumber,
  topBar: useTopBarNumber,
  filterLayout: useListFilterLayout,
};

export const useTerminalObserver = <Key extends keyof typeof HookMap>(
  list: Array<Key> = Object.keys(HookMap) as any
): [
  {
    [key in Key]: ReturnType<(typeof HookMap)[Key]>[0];
  } & {
    size: ReturnType<typeof useSizeObserver>[0];
  },
  (el: HTMLElement | null) => void
] => {
  const [size, ref, el] = useSizeObserver();
  const values = list.reduce<{
    [key in Key]: ReturnType<(typeof HookMap)[Key]>[0];
  }>(
    (map, key) => {
      map[key] = HookMap[key](() => [size, ref, el])[0];
      return map;
    },
    { size: size } as any
  );
  return [values as any, ref];
};

import {
  getCurrentUser,
  getProductionTradeSite,
  objectNameMap,
} from '@dxp-api';
import DoorPage from '@dxp-components/doorPage';
import { IDxpEditor } from '@dxp-declare';
import useCurrentUser, { CurrentUserContext } from '@dxp-hooks/useCurrentUser';
import { useLoading } from '@dxp-hooks/useLoading';
import { useRouterRedirect } from '@dxp-hooks/useRouterRedirect';
import useWebsite, { WebSiteContext } from '@dxp-hooks/useWebsite';
import { useCurrentData } from '@dxp-utils/currentData';
import formatUrl from '@dxp-utils/formatUrl';
import { clearToken } from '@dxp-utils/token';
import { DefaultAvatar } from '@gaker/components';
import { Adapter, Dialog, Dropover } from '@gaker/gaker-ui';
import { useRequest } from '@gaker/hooks';
import useScreen from '@gaker/hooks/useScreen';
import { Color, useThemePrimaryColor } from '@gaker/theme';
import { Right as _Right } from '@icon-park/react';;import { IconPark as IconParkHOC } from '@gaker/components';
        const Right = IconParkHOC(_Right)
      
import { Avatar, Button, Empty, message } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorImage from '@dxp-assets/error.svg';
import { DoorTypeProvider } from '@dxp-presets/context';
import { HOC } from '@gaker/tools';
import { ObjectNameMapProvider } from '@dxp-hooks/useObjectNameMap';

const NaviLink: React.FN<{
  link?: string;
  className?: string;
  onClick?: () => void;
}> = (props) => {
  return (
    <div
      onClick={props.onClick}
      className={classNames(
        'flex justify-between items-center h-54px cursor-pointer',
        props.className
      )}
    >
      <span>{props.children}</span>
      <Right strokeWidth={1} />
    </div>
  );
};

const NavigateCenter: React.FC = () => {
  const currentUser = useCurrentUser();
  const redirect = useRouterRedirect();
  const currentData = useCurrentData();
  return (
    <div className="p-g-xl bg-g-grey-1">
      <div className="flex gap-g-xl">
        <DefaultAvatar
          size="large"
          title={currentUser.userName}
          src={formatUrl(currentUser.avatar)}
        ></DefaultAvatar>
        <div className="flex flex-col">
          <span className="title-g-normal">{currentUser.userName}</span>
          <span className="text-g-normal">用户ID:{currentUser.memberId}</span>
        </div>
      </div>
      <div className="bg-white p-l-g-xl p-r-g-xl rd-g-normal m-t-g-xxl hidden">
        <NaviLink
          className="border-b-g-solid-stroke"
          onClick={() => {
            if (currentData) {
              redirect({ to: 'home', params: currentData });
            } else {
              redirect('login');
            }
          }}
        >
          帐户首页
        </NaviLink>
        <NaviLink
          onClick={() => redirect('user')}
          className="border-b-g-solid-stroke"
        >
          成员管理
        </NaviLink>
        <NaviLink
          onClick={() => {
            redirect('product');
          }}
        >
          产品中心
        </NaviLink>
      </div>
      <Button
        onClick={() => {
          clearToken();
          redirect('login');
        }}
        className="w-full m-t-g-xxxl"
        size="large"
        type="primary"
        ghost
      >
        退出登录
      </Button>
    </div>
  );
};

const RunnerRight: React.FC = () => {
  const currentUser = useCurrentUser();
  return (
    <div className="flex flex-center gap-g-l cursor-pointer h-full">
      {/* <Badge size="small" count={5}>
            <Shopping
              fill={Color.NeutralColor['grey-6']}
              size="28"
              strokeWidth={2}
            />
          </Badge> */}
      <Dropover content={<NavigateCenter></NavigateCenter>}>
        <div className="flex flex-center h-50px">
          <DefaultAvatar
            size="default"
            title={currentUser.userName}
            src={formatUrl(currentUser.avatar)}
          ></DefaultAvatar>
        </div>
      </Dropover>
    </div>
  );
};

const ErrorPage: React.FC<{ message: string }> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <img src={ErrorImage} />
        <p
          style={{
            color: '#303133',
            fontSize: 16,
            marginTop: 16,
            marginBottom: 8,
          }}
          dangerouslySetInnerHTML={{ __html: props.message }}
        ></p>
      </div>
    </div>
  );
};

export const RunnerAuth: React.FN = (props) => {
  const objectData = useCurrentData();
  const { data } = useRequest(getCurrentUser, {
    defaultParams: [{ dataId: objectData?.dataId! }],
  });
  const currentUser = useMemo(() => {
    if (!data) return { userName: '' } as IDxpEditor.Models.CurrentUserVO;
    return data;
  }, [data]);
  return (
    <CurrentUserContext value={currentUser}>
      {props.children}
    </CurrentUserContext>
  );
};

const IconLink = (() => {
  const link = document.createElement('link');
  link.rel = 'icon';
  link.type = 'image/x-icon';
  return link;
})();

export const RunnerSite: React.FN = (props) => {
  const screen = useScreen();
  const params = useParams<{ domain: string }>();
  const { set } = useThemePrimaryColor();
  const [webSite, setWebSite] = useState<IDxpEditor.Models.WebSite>();
  const [loadingContext, ctrl] = useLoading(!webSite);
  const [errorMsg, setErrorMsg] = useState('');
  useEffect(() => {
    ctrl.loading();
    getProductionTradeSite({ domainId: params.domain! })
      .then((data) => {
        if (data.result) {
          setWebSite(data.data);
          if (data.data.brandColor) {
            set(data.data.brandColor);
          }
          document.title = data.data.siteName || '';
          if (data.data.icon) {
            IconLink.href = formatUrl(data.data.icon);
            document.head.appendChild(IconLink);
          }
        } else {
          setErrorMsg(data.message);
        }
      })
      .finally(() => {
        ctrl.loading(false);
      });
  }, [params.domain]);

  if (ctrl.isLoading) return <>{loadingContext}</>;
  if (!webSite) return <ErrorPage message={errorMsg}></ErrorPage>;
  return (
    <DoorTypeProvider value={webSite.type || 'client'}>
      <WebSiteContext value={webSite}>
        <Adapter mode={screen.width < 720 ? 'mobile' : 'pc'}>
          {props.children}
        </Adapter>
      </WebSiteContext>
    </DoorTypeProvider>
  );
};

export const RunnerObjectName: React.FN = (props) => {
  const webSite = useWebsite();
  const { data, refresh } = useRequest(objectNameMap, {
    defaultLoaded: false,
    defaultParams: [{ type: webSite.type }],
  });
  useEffect(() => {
    if (!webSite.type) return;
    refresh({ type: webSite.type });
  }, [webSite.type]);
  return (
    <ObjectNameMapProvider value={data || {}}>
      {props.children}
    </ObjectNameMapProvider>
  );
};

export const RunnerAuthBaseAndObjectName = HOC(RunnerSite)(
  HOC(RunnerAuth)(RunnerObjectName)
);

export const RunnerBody: React.FN<{
  left?: React.ReactNode;
  right?: React.ReactNode;
  scroll?: boolean;
}> = HOC(RunnerSite)((props) => {
  const webSite = useWebsite();
  return (
    <DoorPage
      scroll={props.scroll}
      left={props.left}
      logo={formatUrl(webSite?.logo)}
      right={props.right}
    >
      {props.children}
    </DoorPage>
  );
});

const RunnerPage: React.FN<{ isNotAuth?: boolean; scroll?: boolean }> = (
  props
) => {
  if (props.isNotAuth) return <RunnerBody>{props.children}</RunnerBody>;
  return (
    <RunnerAuth>
      <RunnerBody scroll={props.scroll} right={<RunnerRight></RunnerRight>}>
        {props.children}
      </RunnerBody>
    </RunnerAuth>
  );
};

export const NotAuthRunnerPage: React.FN<{
  scroll?: boolean;
}> = (props) => {
  return <RunnerPage {...props} isNotAuth={true}></RunnerPage>;
};

export default RunnerPage;

import { IDxpEditor } from '@dxp-declare';

type EOA<T> = T | T[];

export const iterateComponent = (
  config: IDxpEditor.IEditor | undefined,
  iterators: (component: IDxpEditor.Components.TComponent) => void,
  key?: Keys<IDxpEditor.IEditor>[]
): IDxpEditor.IEditor | undefined => {
  if (!config) return config;
  const deep = (
    list: EOA<IDxpEditor.Components.TComponent | null | undefined>
  ) => {
    if (Array.isArray(list)) {
      list.forEach((item) => {
        item && iterators(item);
        const children = (item as any).children;
        if (children) {
          deep(children);
        }
      });
    } else {
      list && deep([list]);
    }
  };
  if (key) {
    key.map((k) => {
      const deepComponent = k.split('.').reduce((map: any, attr) => {
        if (map) return map[attr];
        throw new Error(`iterateComponent.error:${key} not match!`);
      }, config);
      if (k === 'titleBar') {
        deep(config.titleBar.config.icon);
        deep(config.titleBar.config.title);
        deep(config.titleBar.config.status);
      }
      deep(deepComponent);
    });
  } else {
    deep(config.titleBar.config.icon);
    deep(config.titleBar.config.title);
    deep(config.titleBar.config.status);
    deep(config.titleBar);
    deep(config.components);
  }
};

export const iterateFieldComponent = (
  config: IDxpEditor.IEditor | undefined,
  iterators: (
    component:
      | IDxpEditor.Components.TFieldComponent
      | IDxpEditor.Components.TTitleBarImageComponent
  ) => void,
  key?: Keys<IDxpEditor.IEditor>[]
) => {
  iterateComponent(
    config,
    (component) => {
      if (component.type === 'field' || component.type === 'title-bar-image') {
        iterators(component as any);
      }
    },
    key
  );
};

/*
 * @Author: Arthur arthur@lwork.com
 * @Date: 2023-08-04 11:28:03
 * @LastEditors: Arthur arthur@lwork.com
 * @LastEditTime: 2024-03-21 17:14:56
 * @FilePath: /gaker/libs/tools/uuid.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const chars = '0123456789abcdef';
export const uuid = (len = 9, options?: { prefix?: string }): string => {
  let result = '';
  for (let i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return options?.prefix ? options.prefix + result : result;
};

export default uuid;

import EventEmitter from 'events';
import { useEffect, useMemo } from 'react';
export const createEmitter = <
  T extends { [key in string | symbol]: any }
>() => {
  const emitter = new EventEmitter();

  const getId = (eventName: any) => `__${eventName as any}_call__back`;

  const on = <Key extends keyof T>(
    eventName: Key,
    handler: (data: T[Key]) => any
  ) => {
    const registerHandler = async (data: T[Key]) => {
      try {
        const res = await handler(data);
        emitter.emit(getId(eventName), { resolve: true, data: res });
      } catch (e) {
        emitter.emit(getId(eventName), { resolve: false, data: e });
      }
    };
    emitter.addListener(eventName as any, registerHandler);
    return () => {
      emitter.removeListener(eventName as any, registerHandler);
    };
  };

  const once = <Key extends keyof T>(
    eventName: Key,
    handler: (data: T[Key]) => void
  ) => {
    let remove = on<Key>(eventName, (data) => {
      remove();
      return handler(data);
    });
  };

  const useEmitter = <Key extends keyof T>(
    eventName: Key,
    handler: (data: T[Key]) => void,
    deps: any[]
  ) => {
    const memoDeps = useMemo(() => {
      return deps.concat(eventName, handler);
    }, [eventName, handler]);
    useEffect(() => {
      return on(eventName, handler);
    }, memoDeps);
  };
  const emit = <Key extends keyof T>(eventName: Key, data: T[Key]) => {
    emitter.emit(eventName as any, data);
    const promise = new Promise<any>((resolve, reject) => {
      emitter.once(getId(eventName), (event) => {
        if (event.resolve) resolve(event.data);
        else reject(event.data);
      });
    });
    promise.catch((e) => {
      console.warn(e);
    });
    return promise;
  };

  return { useEmitter, emit, on, once };
};

export default createEmitter;
